export const OPEN_NOTIFICATION_MENU = "OPEN_NOTIFICATION_MENU";

export const GET_SYSTEM_NOTIFICATION_START = "GET_SYSTEM_NOTIFICATION_START";
export const GET_SYSTEM_NOTIFICATION_SUCCESS = "GET_SYSTEM_NOTIFICATION_SUCCESS";
export const GET_SYSTEM_NOTIFICATION_ERROR = "GET_SYSTEM_NOTIFICATION_ERROR";

export const GET_NOTIFICATION_LIST_START = "GET_NOTIFICATION_LIST_START";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

export const GET_LAST_NOTIFICATION_LIST_START = "GET_LAST_NOTIFICATION_LIST_START";
export const GET_LAST_NOTIFICATION_LIST_SUCCESS = "GET_LAST_NOTIFICATION_LIST_SUCCESS";
export const GET_LAST_NOTIFICATION_LIST_ERROR = "GET_LAST_NOTIFICATION_LIST_ERROR";

export const GET_UNREAD_NOTIFICATION_START = "GET_UNREAD_NOTIFICATION_START";
export const GET_UNREAD_NOTIFICATION_SUCCESS = "GET_UNREAD_NOTIFICATION_SUCCESS";
export const GET_UNREAD_NOTIFICATION_ERROR = "GET_UNREAD_NOTIFICATION_ERROR";

export const UPDATE_READ_NOTIFICATION_START = "UPDATE_READ_NOTIFICATION_START";
export const UPDATE_READ_NOTIFICATION_SUCCESS = "UPDATE_READ_NOTIFICATION_SUCCESS";
export const UPDATE_READ_NOTIFICATION_ERROR = "UPDATE_READ_NOTIFICATION_ERROR";

export const DOWNLOAD_NOTIFICATION_ERROR = "DOWNLOAD_NOTIFICATION_ERROR";
export const DOWNLOAD_NOTIFICATION_START = "DOWNLOAD_NOTIFICATION_START";
export const DOWNLOAD_NOTIFICATION_SUCCESS = "DOWNLOAD_NOTIFICATION_SUCCESS";
