export const RESET = "RESET";

export const LIST_SEGMENTSN1_OPTIONS_START = "LIST_SEGMENTSN1_OPTIONS_START";
export const LIST_SEGMENTSN1_OPTIONS_SUCCESS = "LIST_SEGMENTSN1_OPTIONS_SUCCESS";
export const LIST_SEGMENTSN1_OPTIONS_ERROR = "LIST_SEGMENTSN1_OPTIONS_ERROR";

export const LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_START = "LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_START";
export const LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_SUCCESS =
  "LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_SUCCESS";
export const LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_ERROR = "LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_ERROR";
