export const GET_TAX_CALCULATION_START = "GET_TAX_CALCULATION_START";
export const GET_TAX_CALCULATION_SUCCESS = "GET_TAX_CALCULATION_SUCCESS";
export const GET_TAX_CALCULATION_ERROR = "GET_TAX_CALCULATION_ERROR";

export const RESET_TAX_CALCULATION = "RESET_TAX_CALCULATION";

export const RESET_TRANSACTION_TAX_CALCULATION = "RESET_TRANSACTION_TAX_CALCULATION";

export const UPDATE_TAX_CALCULATION_START = "UPDATE_TAX_CALCULATION_START";
export const UPDATE_TAX_CALCULATION_SUCCESS = "UPDATE_TAX_CALCULATION_SUCCESS";
export const UPDATE_TAX_CALCULATION_ERROR = "UPDATE_TAX_CALCULATION_ERROR";
