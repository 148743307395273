export const RESET = "RESET";

export const LIST_SEGMENTS_TREE_OPTIONS_START = "LIST_SEGMENTS_TREE_OPTIONS_START";
export const LIST_SEGMENTS_TREE_OPTIONS_SUCCESS = "LIST_SEGMENTS_TREE_OPTIONS_SUCCESS";
export const LIST_SEGMENTS_TREE_OPTIONS_ERROR = "LIST_SEGMENTS_TREE_OPTIONS_ERROR";

export const LIST_SEGMENTS_OPTIONS_START = "LIST_SEGMENTS_OPTIONS_START";
export const LIST_SEGMENTS_OPTIONS_SUCCESS = "LIST_SEGMENTS_OPTIONS_SUCCESS";
export const LIST_SEGMENTS_OPTIONS_ERROR = "LIST_SEGMENTS_OPTIONS_ERROR";

export const CREATE_SEGMENT_TREE_START = "CREATE_SEGMENT_TREE_START";
export const CREATE_SEGMENT_TREE_SUCCESS = "CREATE_SEGMENT_TREE_SUCCESS";
export const CREATE_SEGMENT_TREE_ERROR = "CREATE_SEGMENT_TREE_ERROR";

export const EDIT_SEGMENT_TREE_START = "EDIT_SEGMENT_TREE_START";
export const EDIT_SEGMENT_TREE_SUCCESS = "EDIT_SEGMENT_TREE_SUCCESS";
export const EDIT_SEGMENT_TREE_ERROR = "EDIT_SEGMENT_TREE_ERROR";

export const SET_VALUES = "SET_VALUES";
