export const RESET = "RESET";
export const SELECTED_YEAR = "SELECTED_YEAR";

export const LIST_PANORAMAS_OPTIONS_START = "LIST_PANORAMAS_OPTIONS_START";
export const LIST_PANORAMAS_OPTIONS_SUCCESS = "LIST_PANORAMAS_OPTIONS_SUCCESS";
export const LIST_PANORAMAS_OPTIONS_FAIL = "LIST_PANORAMAS_OPTIONS_FAIL";

export const LIST_ALL_PANORAMAS_START = "LIST_ALL_PANORAMAS_START";
export const LIST_ALL_PANORAMAS_SUCCESS = "LIST_ALL_PANORAMAS_SUCCESS";
export const LIST_ALL_PANORAMAS_FAIL = "LIST_ALL_PANORAMAS_FAIL";

export const ACTIVATE_PANORAMA_START = "ACTIVATE_PANORAMA_START";
export const ACTIVATE_PANORAMA_SUCCESS = "ACTIVATE_PANORAMA_SUCCESS";
export const ACTIVATE_PANORAMA_FAIL = "ACTIVATE_PANORAMA_FAIL";

export const LIST_YEARS_WITH_PANORAMS_START = "LIST_YEARS_WITH_PANORAMS_START";
export const LIST_YEARS_WITH_PANORAMS_SUCCESS = "LIST_YEARS_WITH_PANORAMS_SUCCESS";
export const LIST_YEARS_WITH_PANORAMS_FAIL = "LIST_YEARS_WITH_PANORAMS_FAIL";
