class ListingOptions {
  constructor(orderFieldDefault, frontEndControl = false, filters = {}) {
    return {
      page: 1,
      totalCount: 0,
      itemsPerPageCount: 15,
      listEvent: () => {
        // This is intentional
      },
      genericFilter: "",
      filterFields: filters,
      filterValue: "",
      filterAttr: "",
      orderField: orderFieldDefault,
      orderDirection: 0,
      frontEndControl,
    };
  }
}

export default ListingOptions;
