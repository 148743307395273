import * as actionTypes from "./cradles.actionTypes";
import { updateObject } from "utils/utility";

const initialState = {
  listCradlesOptions: {
    loading: false,
    cradleOptions: [],
    error: null,
    message: null,
  },
};

const listCradlesOptionsStart = state => {
  return updateObject(state, {
    listCradlesOptions: {
      loading: true,
      cradleOptions: [],
      error: null,
      message: null,
    },
  });
};
const listCradlesOptionsSuccess = (state, action) => {
  return updateObject(state, {
    listCradlesOptions: {
      loading: false,
      cradleOptions: action.payload,
    },
  });
};
const listCradlesOptionsError = (state, action) => {
  return updateObject(state, {
    listCradlesOptions: {
      loading: false,
      error: action.error,
      message: action.message,
    },
  });
};

const cradlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_CRADLES_OPTIONS_START:
      return listCradlesOptionsStart(state);
    case actionTypes.LIST_CRADLES_OPTIONS_SUCCESS:
      return listCradlesOptionsSuccess(state, action);
    case actionTypes.LIST_CRADLES_OPTIONS_ERROR:
      return listCradlesOptionsError(state, action);
    default:
      return state;
  }
};

export default cradlesReducer;
