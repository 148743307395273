export const filterParamsCount = filterFields => {
  const counter = Object.keys(filterFields).filter(propertyName => {
    const itemValue = filterFields[propertyName];
    if (Array.isArray(itemValue) && itemValue.length === 0) {
      return false;
    }
    return Boolean(itemValue);
  }).length;
  return counter;
};

/**
 * @param  {} opt
 * @param  {} isListingRequest=true
 */
export const autoListingParamsGet = (opt, isListingRequest = true) => {
  const addFilterFieds = opt && !opt.frontEndControl;
  const addListingOptions = addFilterFieds && isListingRequest;
  const filterFields = [];
  let skip = "";
  let limit = "";
  let sort = "";

  if (addListingOptions) {
    skip = `skip=${opt.itemsPerPageCount * (opt.page - 1)}`;
    limit = `take=${opt.itemsPerPageCount}`;
    sort = `sortDirection=${opt.orderDirection ? "desc" : "asc"}&columnName=${opt.orderField}`;
  }

  if (opt && opt.filterFields) {
    Object.keys(opt.filterFields).forEach(field => {
      const localValue = opt.filterFields[field];
      if (Array.isArray(localValue)) {
        localValue.forEach(value => {
          if (value !== undefined && value !== null) {
            value !== undefined &&
              value &&
              filterFields.push(`${field}=${encodeURIComponent(value)}`);
          }
        });
      } else if (localValue) {
        filterFields.push(`${field}=${encodeURIComponent(localValue)}`);
      }
    });
  }

  return !addListingOptions
    ? filterFields.join("&")
    : [skip, limit, sort].concat(filterFields).join("&");
};

/**
 * @param  {} paramsObject
 */
export const autoParamsGet = paramsObject => {
  const results = [];

  if (paramsObject) {
    Object.keys(paramsObject).forEach(field => {
      const localValue = paramsObject[field];

      if (Array.isArray(localValue)) {
        localValue.map(value => results.push(`${field}=${encodeURIComponent(value)}`));
      } else if (localValue) {
        results.push(`${field}=${encodeURIComponent(localValue)}`);
      }
    });
  }

  return results.join("&");
};

/**
 * format get url with possible params
 * @param  {string} endpoint
 * @param  {object} params
 */
export const getUrlFormat = (endpoint, params = {}) => `/${endpoint}?${autoParamsGet(params)}`;

/**
 */
export const simpleId = () =>
  `_${Math.random()
    .toString(36)
    .substring(2, 9)}`;
