import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import GlobalStyle from "./assets/style/global";
import theme from "./assets/style/theme";
import "./assets/css/colors.css";
import "./assets/sass/main.scss";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { store, history, persistor } from "store/store";
import API, { appClientAPI, notificationAPI } from "utils/api";
import { startTrackingUser, uncoverText } from "utils/utility";
import ls from "local-storage";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Router } from "react-router-dom";
// MSAL IMPORTS
import { msalConfig } from "utils/config";
import { PublicClientApplication } from "@azure/msal-browser";
// MSAL IMPORTS

import TagManager from "react-gtm-module";

API.defineRequestInterceptor(localStorage);
API.defineResponseInterceptor(history);
notificationAPI.defineRequestInterceptor();
appClientAPI.defineResponseInterceptor(history);

const currentLocationURL = window.location.origin;
const token = uncoverText(ls.get("usd"));
if (
  process.env.NODE_ENV === "development" ||
  (currentLocationURL === "https://pvdigital-dev.vli-logistica.com.br" && token)
) {
  console.warn("Token: ", token);
}

startTrackingUser();
const msalInstance = new PublicClientApplication(msalConfig);

const tagManagerArgs = {
  gtmId: "GTM-TZSDBV73",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App history={history} pca={msalInstance} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root"),
);
