import { updateObject } from "utils/utility";
import * as actionTypes from "./error.actionTypes";

const initialState = {
  hasError: false,
};
const setError = (state, action) =>
  updateObject(state, {
    hasError: action.hasError,
  });

const errorReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return setError(state, action);

    default:
      return state;
  }
};

export default errorReducer;
