import React, { useEffect, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { cancelAllRequests, hasToken } from "utils/api";
import * as urls from "utils/urls";
import i18n from "components/i18n";
import { MsalProvider } from "@azure/msal-react";

import numeral from "numeral";
import "numeral/locales/pt-br";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

numeral.locale("pt-br");

const Dashboard = React.lazy(() => lazyRetry(() => import("views/Dashboard"), "Dashboard"));
const Login = React.lazy(() => lazyRetry(() => import("views/Login"), "Login"));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function(componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false",
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh

          console.error(`Erro ao carregar o componente ${name}:`, error);
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const App = ({ pca }) => {
  const location = useLocation();

  useEffect(() => {
    (function(c, l, a, r, i, t, y) {
      if (c[a]) return;
      function s() {
        let e = l.createElement("script");
        e.type = "text/javascript";
        e.async = true;
        e.src = r + "?" + new Date().getTime();
        let t = l.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t);
      }
      s();
    })(window, document, "clarity", "https://www.clarity.ms/tag/eq0c7vni6b");
  }, []);

  useEffect(() => {
    cancelAllRequests();
  }, [location]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") clarity.init("eq0c7vni6b");
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense
        fallback={
          <>
            <center className="pt-4">
              <h2>Carregando módulo...</h2>
            </center>
          </>
        }
      >
        <Switch>
          <MsalProvider instance={pca}>
            <Route
              exact
              path={urls.LOGIN}
              render={() => (hasToken() ? <Redirect to={urls.DASHBOARD} /> : <Login />)}
            />
            <Route
              path={urls.DASHBOARD}
              render={() => (!hasToken() ? <Redirect to={urls.LOGIN} /> : <Dashboard />)}
            />
          </MsalProvider>
        </Switch>
      </Suspense>
    </I18nextProvider>
  );
};

App.defaultProps = {
  history: {},
};

export default App;
