/**
 * @param  {} className
 */
export const goTop = className => {
  if (className) {
    document.getElementsByClassName(className)[0].scrollTo(0, 0);
  } else {
    window.scrollTo(0, 0);
  }
};

/**
 * @param  {} elem
 */
export const isVisible = elem => {
  const { top, bottom, left, right } = elem.getBoundingClientRect();
  const width = window.innerWidth || document.documentElement.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight;
  return top >= 0 && left >= 0 && right <= width && bottom <= height;
};

/**
 * Scroll to anchor
 *
 * @param  {} anchorName
 */
export const scrollToAnchor = anchorName => {
  if (!anchorName) {
    return;
  }

  const topElement = document.getElementById(anchorName.replace("#", ""));

  if (!topElement) {
    return;
  }
  const scrollDuration = 600;
  const top = topElement.offsetTop;
  const target = Math.round(top);
  const duration = Math.round(scrollDuration);

  const startTime = Date.now();
  const endTime = startTime + duration;

  const startTop = window.pageYOffset;
  const distance = target - startTop;

  const smoothStep = (start, end, point) => {
    if (point <= start) {
      return 0;
    }
    if (point >= end) {
      return 1;
    }
    const x = (point - start) / (end - start);
    return x * x * (3 - 2 * x);
  };

  return new Promise(resolve => {
    let previousTop = window.pageYOffset;

    const scrollFrame = () => {
      if (window.pageYOffset !== previousTop) {
        resolve();
        return;
      }

      const now = Date.now();
      const point = smoothStep(startTime, endTime, now);
      const frameTop = Math.round(startTop + distance * point);
      window.scroll(0, frameTop);

      if (now >= endTime) {
        resolve();
        return;
      }

      if (window.pageYOffset === previousTop && window.pageYOffset !== frameTop) {
        resolve();
        return;
      }
      previousTop = window.pageYOffset;

      setTimeout(scrollFrame, 0);
    };

    setTimeout(scrollFrame, 0);
  });
};

/**
 * @param  {} blobData
 * @param  {} fileName
 * @param  {} callback
 */
export const downloadBlob = (blobData, fileName, callback) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  const blob = blobData;
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);

  if (callback) {
    callback();
  }
};
