import * as actionTypes from "./notifications.actionTypes";
import { updateObject } from "lib/object";

const initialState = {
  systemNotification: {
    loading: false,
    systemId: null,
    error: null,
    message: null,
    resultType: "danger",
  },
  notificationMenu: {
    isOpen: false,
  },
  notificationsList: {
    resultType: "danger",
    loading: false,
    notifications: [],
    config: {},
    error: null,
    message: null,
  },
  lastNotificationsList: {
    resultType: "danger",
    loading: false,
    notifications: [],
    error: null,
    message: null,
  },
  unReadNotifications: {
    resultType: "danger",
    loading: false,
    notifications: null,
    error: null,
    message: null,
  },
  updateReadNotifications: {
    resultType: "danger",
    loading: false,
    error: null,
    message: null,
  },
  downloadNotification: {
    resultType: "danger",
    loading: false,
    error: null,
    message: null,
    notification: null,
  },
};

export const openNotificationMenu = (state, action) =>
  updateObject(state, {
    notificationMenu: {
      isOpen: action.isOpen,
    },
  });

export const getSystemNotificationsStart = state =>
  updateObject(state, {
    systemNotification: {
      ...state.systemNotification,
      loading: true,
    },
  });

export const getSystemNotificationsSuccess = (state, action) =>
  updateObject(state, {
    systemNotification: {
      ...state.systemNotification,
      loading: false,
      resultType: "success",
      systemId: action.systemId,
    },
  });

export const getSystemNotificationsError = (state, action) =>
  updateObject(state, {
    systemNotification: {
      ...state.systemNotification,
      loading: false,
      resultType: "danger",
      error: action.error,
      message: action.message,
    },
  });

export const getNotificationsStart = state =>
  updateObject(state, {
    notificationsList: {
      ...state.notificationsList,
      loading: true,
    },
  });

export const getNotificationsSuccess = (state, action) =>
  updateObject(state, {
    notificationsList: {
      ...state.notificationsList,
      resultType: "success",
      loading: false,
      notifications: action.notifications,
      config: action.config,
    },
  });

export const getNotificationsError = (state, action) =>
  updateObject(state, {
    notificationsList: {
      ...state.notificationsList,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const getLastNotificationsStart = state =>
  updateObject(state, {
    lastNotificationsList: {
      ...state.lastNotificationsList,
      loading: true,
    },
  });

export const getLastNotificationsSuccess = (state, action) =>
  updateObject(state, {
    lastNotificationsList: {
      ...state.lastNotificationsList,
      loading: false,
      notifications: action.notifications,
    },
  });

export const getLastNotificationsError = (state, action) =>
  updateObject(state, {
    lastNotificationsList: {
      ...state.lastNotificationsList,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const getUnReadNotificationsStart = state =>
  updateObject(state, {
    unReadNotifications: {
      ...state.unReadNotifications,
      loading: true,
    },
  });

export const getUnReadNotificationsSuccess = (state, action) =>
  updateObject(state, {
    unReadNotifications: {
      ...state.unReadNotifications,
      resultType: "success",
      loading: false,
      notifications: action.notifications,
    },
  });

export const getUnReadNotificationsError = (state, action) =>
  updateObject(state, {
    unReadNotifications: {
      ...state.unReadNotifications,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const updateReadNotificationStart = state =>
  updateObject(state, {
    updateReadNotifications: {
      ...state.updateReadNotifications,
      loading: true,
    },
  });

export const updateReadNotificationSuccess = state =>
  updateObject(state, {
    updateReadNotifications: {
      ...state.updateReadNotifications,
      resultType: "success",
      loading: false,
      error: null,
      message: null,
    },
  });

export const updateReadNotificationError = (state, action) =>
  updateObject(state, {
    updateReadNotifications: {
      ...state.updateReadNotifications,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const downloadNotificationStart = state =>
  updateObject(state, {
    downloadNotification: {
      ...state.downloadNotification,
      loading: true,
    },
  });

export const downloadNotificationSuccess = state =>
  updateObject(state, {
    downloadNotification: {
      ...state.downloadNotification,
      loading: false,
      resultType: "success",
      error: null,
      message: null,
    },
  });

export const downloadNotificationError = (state, action) =>
  updateObject(state, {
    downloadNotification: {
      ...state.downloadNotification,
      loading: false,
      resultType: "danger",
      error: action.error,
      message: action.message,
    },
  });

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_NOTIFICATION_MENU:
      return openNotificationMenu(state, action);

    case actionTypes.GET_SYSTEM_NOTIFICATION_START:
      return getSystemNotificationsStart(state);
    case actionTypes.GET_SYSTEM_NOTIFICATION_SUCCESS:
      return getSystemNotificationsSuccess(state, action);
    case actionTypes.GET_SYSTEM_NOTIFICATION_ERROR:
      return getSystemNotificationsError(state, action);

    case actionTypes.GET_NOTIFICATION_LIST_START:
      return getNotificationsStart(state);
    case actionTypes.GET_NOTIFICATION_LIST_SUCCESS:
      return getNotificationsSuccess(state, action);
    case actionTypes.GET_NOTIFICATION_LIST_ERROR:
      return getNotificationsError(state, action);

    case actionTypes.GET_LAST_NOTIFICATION_LIST_START:
      return getLastNotificationsStart(state);
    case actionTypes.GET_LAST_NOTIFICATION_LIST_SUCCESS:
      return getLastNotificationsSuccess(state, action);
    case actionTypes.GET_LAST_NOTIFICATION_LIST_ERROR:
      return getLastNotificationsError(state, action);

    case actionTypes.GET_UNREAD_NOTIFICATION_START:
      return getUnReadNotificationsStart(state);
    case actionTypes.GET_UNREAD_NOTIFICATION_SUCCESS:
      return getUnReadNotificationsSuccess(state, action);
    case actionTypes.GET_UNREAD_NOTIFICATION_ERROR:
      return getUnReadNotificationsError(state, action);

    case actionTypes.UPDATE_READ_NOTIFICATION_START:
      return updateReadNotificationStart(state);
    case actionTypes.UPDATE_READ_NOTIFICATION_SUCCESS:
      return updateReadNotificationSuccess(state);
    case actionTypes.UPDATE_READ_NOTIFICATION_ERROR:
      return updateReadNotificationError(state, action);

    case actionTypes.DOWNLOAD_NOTIFICATION_START:
      return downloadNotificationStart(state);
    case actionTypes.DOWNLOAD_NOTIFICATION_SUCCESS:
      return downloadNotificationSuccess(state);
    case actionTypes.DOWNLOAD_NOTIFICATION_ERROR:
      return downloadNotificationError(state, action);

    default:
      return state;
  }
};

export default notificationsReducer;
