import { updateObject } from "utils/utility";
import DefaultRequestParams from "utils/defaultRequestParams";
import * as actionTypes from "./clients.actionTypes";

const initialState = {
  listClientsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    clientsOptions: [],
    clientsOptionsDS: [],
  },
  listClientsForProposals: {
    ...DefaultRequestParams(),
    clientsOptions: [],
  },
  transactionsLink: {
    listMonthsYears: {
      monthsYear: [],
      error: null,
      message: null,
      loading: false,
    },
    listRoutes: {
      origins: [],
      destinies: [],
      halls: [],
      products: [],
      error: null,
      message: null,
      loading: false,
    },
  },
};

/* list clients options for comboboxes and autocompletes */

const listClientsOptionsStart = state =>
  updateObject(state, {
    listClientsOptions: {
      ...state.listClientsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listClientsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listClientsOptions: {
      ...state.listClientsOptions,
      loading: false,
      resultType: "success",
      message: null,
      clientsOptions: action.clients
        .map(item => ({
          id: item.id,
          label: item.name,
          associated: item.associated,
          descricao: item.descricao,
          dominio: item.dominio,
          segmentTree: item.segmentTree,
          active: item.active,
        }))
        .filter(item => item.active === true),
      clientsOptionsDS: action.clients.map(item => ({
        value: { ...item },
        label: item.name,
      })),
      allClientsOptions: action.clients.map(item => ({
        id: item.id,
        label: item.name,
        associated: item.associated,
        descricao: item.descricao,
        dominio: item.dominio,
        segmentTree: item.segmentTree,
        active: item.active,
      })),
    },
  });

const listClientsOptionsError = (state, action) =>
  updateObject(state, {
    listClientsOptions: {
      ...state.listClientsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      clientsOptions: [],
    },
  });

/* list clients availables for proposals */

const listClientsForProposalsStart = state =>
  updateObject(state, {
    listClientsForProposals: {
      ...state.listClientsForProposals,
      loading: true,
      error: null,
      message: null,
    },
  });

const listClientsForProposalsSuccess = (state, action) =>
  updateObject(state, {
    listClientsForProposals: {
      ...state.listClientsForProposals,
      loading: false,
      resultType: "success",
      message: null,
      clientsOptions: action.clients,
    },
  });

const listClientsForProposalsError = (state, action) =>
  updateObject(state, {
    listClientsForProposals: {
      ...state.listClientsForProposals,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      clientsOptions: [],
    },
  });

/* list routes for transactions link */

const listRoutesStart = state =>
  updateObject(state, {
    transactionsLink: {
      ...state.transactionsLink,
      listRoutes: {
        origins: [],
        destinies: [],
        halls: [],
        products: [],
        error: null,
        message: null,
        loading: true,
      },
    },
  });

const listRoutesSuccess = (state, action) =>
  updateObject(state, {
    transactionsLink: {
      ...state.transactionsLink,
      listRoutes: {
        origins: action.listRoutes.origens,
        destinies: action.listRoutes.destinos,
        halls: action.listRoutes.corredores,
        products: action.listRoutes.produtos,
        error: null,
        message: null,
        loading: false,
        resultType: "success",
      },
    },
  });

const listRoutsFail = (state, action) =>
  updateObject(state, {
    transactionsLink: {
      ...state.transactionsLink,
      listRoutes: {
        origins: [],
        destinies: [],
        halls: [],
        products: [],
        error: action.error,
        message: action.message,
        loading: false,
        resultType: "danger",
      },
    },
  });

const saveSegmentTreeClientRelationshipStart = state =>
  updateObject(state, {
    clientForm: {
      ...state.clientForm,
      loading: true,
      error: null,
      message: null,
    },
  });

const saveSegmentTreeClientRelationshipSuccess = state =>
  updateObject(state, {
    clientForm: {
      ...state.clientForm,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const saveSegmentTreeClientRelationshipError = (state, action) =>
  updateObject(state, {
    clientForm: {
      ...state.clientForm,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const clientsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.LIST_CLIENTS_OPTIONS_START:
      return listClientsOptionsStart(state);
    case actionTypes.LIST_CLIENTS_OPTIONS_SUCCESS:
      return listClientsOptionsSuccess(state, action);
    case actionTypes.LIST_CLIENTS_OPTIONS_ERROR:
      return listClientsOptionsError(state, action);

    case actionTypes.LIST_CLIENTS_FOR_PROPOSALS_START:
      return listClientsForProposalsStart(state);
    case actionTypes.LIST_CLIENTS_FOR_PROPOSALS_SUCCESS:
      return listClientsForProposalsSuccess(state, action);
    case actionTypes.LIST_CLIENTS_FOR_PROPOSALS_ERROR:
      return listClientsForProposalsError(state, action);

    case actionTypes.LIST_ROUTES_START:
      return listRoutesStart(state);
    case actionTypes.LIST_ROUTES_SUCCESS:
      return listRoutesSuccess(state, action);
    case actionTypes.LIST_ROUTES_FAIL:
      return listRoutsFail(state, action);

    case actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_START:
      return saveSegmentTreeClientRelationshipStart(state);
    case actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_SUCCESS:
      return saveSegmentTreeClientRelationshipSuccess(state);
    case actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_ERROR:
      return saveSegmentTreeClientRelationshipError(state, action);

    default:
      return state;
  }
};

export default clientsReducer;
