import DefaultRequestParams from "utils/defaultRequestParams";
import { updateObject } from "utils/utility";
import * as actionTypes from "./reports.actionTypes";

const initialState = {
  reportData: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    filterParams: {},
    volume: [],
    volumeReal: [],
    revenue: [],
    newKri: [],
    kriTable: {},
    filter: {},
  },

  comparativeData: {
    ...DefaultRequestParams(),
    selectedScenarios: [],
    data: {
      volume: [],
      volumeReal: [],
      revenue: [],
      liquid: [],
      liquidReal: [],
      totalVolume: [],
      totalVolumeReal: [],
      totalRevenue: [],
      totalLiquid: [],
      totalLiquidReal: [],
    },
  },
  yearsAvailable: {
    ...DefaultRequestParams(),
    options: [],
  },
  exportForm: DefaultRequestParams(),
  scale: "",
  duplicatedScenario: {
    isDuplicating: false,
    index: undefined,
  },
};

const reset = () => initialState;

const getReportDataStart = (state, action) =>
  updateObject(state, {
    reportData: {
      ...state.reportData,
      loading: true,
      error: null,
      message: null,
      filterParams: action.options,
    },
  });

const getReportDataSuccess = (state, action) =>
  updateObject(state, {
    reportData: {
      ...state.reportData,
      loading: false,
      resultType: "success",
      message: null,
      newKri: action.reportData.newKri,
      kriTable: action.reportData.kriTable,
    },
  });

const getReportDataError = (state, action) =>
  updateObject(state, {
    reportData: {
      ...state.reportData,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      volume: [],
      volumeReal: [],
    },
  });

const getComparativeStart = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: true,
      error: null,
      message: null,
      filterParams: action.options,
      data: {
        volume: [],
        volumeReal: [],
        revenue: [],
        liquid: [],
        liquidReal: [],
        totalVolume: [],
        totalVolumeReal: [],
        totalLiquidReal: [],
        totalRevenue: [],
        totalLiquid: [],
      },
    },
  });

const getComparativeSuccess = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      data: action.data,
    },
  });

const setSelectedScenarios = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      selectedScenarios: updateScenario(state, action.payload),
    },
  });

function updateScenario(state, action) {
  let scenarios = [...state.comparativeData.selectedScenarios];

  if (action === null) {
    scenarios = scenarios.splice(action.index, 1);
  } else {
    scenarios[action.index] = {
      label: action.label,
      year: action.year,
      scenarioId: action.index,
    };
  }

  return scenarios;
}

const setDuplicatedScenario = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      selectedScenarios: updateDuplicatedScenario(state, action.payload),
    },
  });

export function updateDuplicatedScenario(state, action) {
  const scenarios = [...state.comparativeData.selectedScenarios];
  const newScenario = {
    label: scenarios[action]?.label,
    year: scenarios[action]?.year,
    scenarioId: scenarios.length,
  };
  const validate = scenarios.findIndex(scenario => scenario === action);

  if (validate) {
    scenarios.push(newScenario);
  }

  return scenarios;
}

const setSelectedYear = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      selectedScenarios: updateYear(state, action.payload),
    },
  });

function updateYear(state, action) {
  const scenarios = [...state.comparativeData.selectedScenarios];
  if (action === null) {
    scenarios[action.index].year = "";
  } else {
    action != null && (scenarios[action.index].year = action?.year);
  }
  return scenarios;
}

const resetSelectedScenario = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      selectedScenarios: resetScenario(state, action.payload),
    },
  });

function resetScenario(state, action) {
  const scenarios = [...state.comparativeData.selectedScenarios];
  scenarios.splice([action.index], 1);
  scenarios.forEach((_, index) => {
    if (index >= action.index) {
      scenarios[index].scenarioId = scenarios[index].scenarioId - 1;
    }
  });

  return scenarios;
}

const resetAllScenarios = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      loading: false,
      resultType: "success",
      message: null,
      selectedScenarios: action.payload,
    },
  });

const getComparativeError = (state, action) =>
  updateObject(state, {
    comparativeData: {
      ...state.comparativeData,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      data: {
        volume: [],
        volumeReal: [],
        revenue: [],
        liquid: [],
        liquidReal: [],
        totalVolume: [],
        totalVolumeReal: [],
        totalRevenue: [],
        totalLiquid: [],
        totalLiquidReal: [],
      },
    },
  });

// get available years options

const getYearsStart = (state, action) =>
  updateObject(state, {
    yearsAvailable: {
      ...state.yearsAvailable,
      loading: true,
      error: null,
      message: null,
      filterParams: action.options,
      data: {},
    },
  });

const getYearsSuccess = (state, action) =>
  updateObject(state, {
    yearsAvailable: {
      ...state.yearsAvailable,
      loading: false,
      resultType: "success",
      message: null,
      options: action.options,
    },
  });

const getYearsError = (state, action) =>
  updateObject(state, {
    yearsAvailable: {
      ...state.yearsAvailable,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      options: [],
    },
  });

const setScale = (state, action) =>
  updateObject(state, {
    scale: action.payload,
  });

const isDuplicatingScenario = (state, action) =>
  updateObject(state, {
    duplicatedScenario: {
      isDuplicating: action.isDuplicating,
      index: action.index,
    },
  });

const reportsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.GET_REPORT_DATA_START:
      return getReportDataStart(state, action);
    case actionTypes.GET_REPORT_DATA_SUCCESS:
      return getReportDataSuccess(state, action);
    case actionTypes.GET_REPORT_DATA_ERROR:
      return getReportDataError(state, action);

    case actionTypes.GET_COMPARATIVE_START:
      return getComparativeStart(state, action);
    case actionTypes.GET_COMPARATIVE_SUCCESS:
      return getComparativeSuccess(state, action);
    case actionTypes.SET_SELECTED_SCENARIO:
      return setSelectedScenarios(state, action);
    case actionTypes.SET_DUPLICATED_SCENARIO:
      return setDuplicatedScenario(state, action);
    case actionTypes.SET_SELECTED_YEAR:
      return setSelectedYear(state, action);
    case actionTypes.RESET_SELECTED_SCENARIO:
      return resetSelectedScenario(state, action);
    case actionTypes.RESET_ALL_SCENARIOS:
      return resetAllScenarios(state, action);
    case actionTypes.GET_COMPARATIVE_ERROR:
      return getComparativeError(state, action);

    case actionTypes.GET_YEARS_START:
      return getYearsStart(state, action);
    case actionTypes.GET_YEARS_SUCCESS:
      return getYearsSuccess(state, action);
    case actionTypes.GET_YEARS_ERROR:
      return getYearsError(state, action);

    case actionTypes.SET_SCALE:
      return setScale(state, action);

    case actionTypes.IS_DUPLICATING_SCENARIO:
      return isDuplicatingScenario(state, action);

    default:
      return state;
  }
};

export default reportsReducer;
