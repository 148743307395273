export const HTTP_MESSAGES = {
  UNAUTHORIZED: "Faça login novamente para continuar usando o sistema!",
  NOT_FOUND: "Os ítens pesquisados não foram encontrados.",
  INTERNAL_SERVER_ERROR: "Ocorreu um erro inesperado.",
  GATEWAY_TIMEOUT:
    "Tempo limite de espera atingido, a solicitação demorou demais para ser respondida.",
  NETWORK_ERROR: "A conexão a rede falhou, tente novamente.",
};

export const ERROR_MESSAGES = {
  AUTH: "Não foi possível autenticar o usuário.",
};

export const VALIDATION_MESSAGES = {
  REQUIRED: "Campo obrigatório",
  INVALID_YEAR: "Ano inválido",
  DATE_MIN: "Vigência final deve ser uma data após a vigência inicial",
  VALID_MAIL: "Digite um email válido",
  EXIST_MAIL: "Email já cadastrado",
  NAME: "O campo deve conter nome e sobrenome",
  EMAIL: "Email é obrigatório",
  PHONE: "Telefone é obrigatório",
  PHONE_VALID: "Digite um telefone válido",
  ACCOUNTABILITY: "Cargo é obrigatório",
  MAX_PERCENT: "Máximo de 100%",
  MIN_PERCENT: "Mínimo de 1%",
  AT_LEAST_ONE: "Selecione ao menos um fluxo",
  AT_LEAST_TWO: "Selecione ao menos dois fluxos",
};

export const LOADING_MESSAGES = [
  "Refinando informações",
  "Definindo destinos",
  "Localizando clientes",
  "Identificando produtos",
  "Rastreando datas",
  "Direcionando rotas",
  "Ajustando parâmetros",
  "Buscando registros",
  "Analisando status",
  "Verificando permissões",
  "Empilhando transações",
  "Buscando modais",
  "Ajustando configurações",
  "Carregando dados",
  "Ajustando filtros",
];
