import { updateObject } from "utils/utility";
import * as actionTypes from "./segmentsN1.actionTypes";

const initialState = {
  listSegmentsN1Options: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    segmentsN1Options: [],
  },
  listSegmentsN1OptionsWithIndex: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    segmentsN1Options: [],
  },
  listSegmentsN1MultipleOptions: [
    {
      loading: false,
      error: null,
      resultType: "danger",
      message: null,
      segmentsN1Options: [],
    },
  ],
};

const reset = () => initialState;

/* list segments N1 options for comboboxes and autocompletes */

const listSegmentsN1OptionsStart = state =>
  updateObject(state, {
    listSegmentsN1Options: {
      ...state.listSegmentsN1Options,
      loading: true,
      error: null,
      message: null,
    },
  });

const listSegmentsN1OptionsSuccess = (state, action) =>
  updateObject(state, {
    listSegmentsN1Options: {
      ...state.listSegmentsN1Options,
      loading: false,
      resultType: "success",
      message: null,
      segmentsN1Options: action.segments.map(item => ({
        id: item.id,
        label: item.name,
        segmentGroup: item.segmentGroup,
        saleTypes: item.saleTypes,
      })),
    },
  });

const listSegmentsN1OptionsError = (state, action) =>
  updateObject(state, {
    listSegmentsN1Options: {
      ...state.listSegmentsN1Options,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      segmentsN1Options: [],
    },
  });

/* with index */

const listSegmentsN1OptionsWithIndexStart = (state, action) => {
  if (action.index !== undefined) {
    const newState = { ...state };
    newState.listSegmentsN1MultipleOptions[action.index] = {
      ...state.listSegmentsN1MultipleOptions[action.index],
      loading: true,
      error: null,
      message: null,
    };
    return updateObject(state, newState);
  }

  return updateObject(state, {
    listSegmentsN1OptionsWithIndex: {
      ...state.listSegmentsN1OptionsWithIndex,
      loading: true,
      error: null,
      message: null,
    },
  });
};

const listSegmentsN1OptionsWithIndexSuccess = (state, action) => {
  const newOptions = [...state.listSegmentsN1OptionsWithIndex.segmentsN1Options];

  newOptions[action.searchIndex] = action.segments.map(item => ({
    id: item.id,
    label: item.name,
    segmentGroup: item.segmentGroup,
    saleTypes: item.saleTypes,
  }));

  if (action.index !== undefined) {
    const newState = { ...state };
    newState.listSegmentsN1MultipleOptions[action.index] = {
      ...state.listSegmentsN1MultipleOptions[action.index],
      loading: false,
      resultType: "success",
      message: null,
      segmentsN1Options: newOptions,
    };

    return updateObject(state, newState);
  }

  return updateObject(state, {
    listSegmentsN1OptionsWithIndex: {
      ...state.listSegmentsN1OptionsWithIndex,
      loading: false,
      resultType: "success",
      message: null,
      segmentsN1Options: newOptions,
      segmentsN1OptionsDS: action.segments.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });
};

const listSegmentsN1OptionsWithIndexError = (state, action) => {
  if (action.index !== undefined) {
    const newState = { ...state };
    newState.listSegmentsN1MultipleOptions[action.index] = {
      ...state.listSegmentsN1MultipleOptions[action.index],
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      segmentsN1Options: [],
    };
    return updateObject(state, newState);
  }

  return updateObject(state, {
    listSegmentsN1OptionsWithIndex: {
      ...state.listSegmentsN1OptionsWithIndex,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      segmentsN1Options: [],
    },
  });
};

const segmentsN1Reducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_SEGMENTSN1_OPTIONS_START:
      return listSegmentsN1OptionsStart(state);
    case actionTypes.LIST_SEGMENTSN1_OPTIONS_SUCCESS:
      return listSegmentsN1OptionsSuccess(state, action);
    case actionTypes.LIST_SEGMENTSN1_OPTIONS_ERROR:
      return listSegmentsN1OptionsError(state, action);

    case actionTypes.LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_START:
      return listSegmentsN1OptionsWithIndexStart(state, action);
    case actionTypes.LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_SUCCESS:
      return listSegmentsN1OptionsWithIndexSuccess(state, action);
    case actionTypes.LIST_SEGMENTSN1_OPTIONS_WITH_INDEX_ERROR:
      return listSegmentsN1OptionsWithIndexError(state, action);

    default:
      return state;
  }
};

export default segmentsN1Reducer;
