import { updateObject } from "utils/utility";
import * as actionTypes from "./states.actionTypes";

const initialState = {
  listStatesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    statesOptions: [],
  },
  listStatesOptionsAppCliente: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    statesOptions: [],
  },
  listStatesInitialsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    statesOptions: [],
  },
  listStatesAssociated: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    statesOptions: [],
  },
};

const reset = () => initialState;

const listStatesOptionsStart = state =>
  updateObject(state, {
    listStatesOptions: {
      ...state.listStatesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listStatesOptionsSuccess = (state, action) =>
  updateObject(state, {
    listStatesOptions: {
      ...state.listStatesOptions,
      loading: false,
      resultType: "success",
      message: null,
      statesOptions: action.states.map(item => ({
        id: item.id,
        label: item.nome,
        initial: item.sigla,
      })),
      stateOptionsDS: action.states.map(item => ({
        value: { ...item },
        label: item.nome,
      })),
    },
  });

const listStatesOptionsError = (state, action) =>
  updateObject(state, {
    listStatesOptions: {
      ...state.listStatesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      statesOptions: [],
    },
  });

const listStatesOptionsAppClienteStart = state =>
  updateObject(state, {
    listStatesOptionsAppCliente: {
      ...state.listStatesOptionsAppCliente,
      loading: true,
      error: null,
      message: null,
    },
  });

const listStatesOptionsAppClienteSuccess = (state, action) =>
  updateObject(state, {
    listStatesOptionsAppCliente: {
      ...state.listStatesOptionsAppCliente,
      loading: false,
      resultType: "success",
      message: null,
      statesOptions: action.states.map(item => ({
        id: item.id,
        label: item.nome,
        initials: item.sigla,
      })),
    },
  });

const listStatesOptionsAppClienteError = (state, action) =>
  updateObject(state, {
    listStatesOptionsAppCliente: {
      ...state.listStatesOptionsAppCliente,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      statesOptions: [],
    },
  });

const listStatesInitialsOptionsStart = state =>
  updateObject(state, {
    listStatesInitialsOptions: {
      ...state.listStatesInitialsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listStatesInitialsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listStatesInitialsOptions: {
      ...state.listStatesInitialsOptions,
      loading: false,
      resultType: "success",
      message: null,
      statesOptions: action.states.map(item => ({
        id: item.id,
        label: item.sigla,
      })),
    },
  });

const listStatesInitialsOptionsError = (state, action) =>
  updateObject(state, {
    listStatesInitialsOptions: {
      ...state.listStatesInitialsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      statesOptions: [],
    },
  });

const listStatesAssociatedStart = state =>
  updateObject(state, {
    listStatesAssociated: {
      ...state.listStatesAssociated,
      loading: true,
      error: null,
      message: null,
    },
  });

const listStatesAssociatedSuccess = (state, action) =>
  updateObject(state, {
    listStatesAssociated: {
      ...state.listStatesAssociated,
      loading: false,
      resultType: "success",
      message: null,
      statesOptions: action.payload?.map(item => ({
        id: item.id,
        label: item.nome,
      })),
    },
  });

const listStatesAssociatedError = (state, action) =>
  updateObject(state, {
    listStatesAssociated: {
      ...state.listStatesAssociated,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      statesOptions: [],
    },
  });

const statesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_STATES_OPTIONS_START:
      return listStatesOptionsStart(state);
    case actionTypes.LIST_STATES_OPTIONS_SUCCESS:
      return listStatesOptionsSuccess(state, action);
    case actionTypes.LIST_STATES_OPTIONS_ERROR:
      return listStatesOptionsError(state, action);

    case actionTypes.LIST_STATES_OPTIONS_APPCLIENTE_START:
      return listStatesOptionsAppClienteStart(state);
    case actionTypes.LIST_STATES_OPTIONS_APPCLIENTE_SUCCESS:
      return listStatesOptionsAppClienteSuccess(state, action);
    case actionTypes.LIST_STATES_OPTIONS_APPCLIENTE_ERROR:
      return listStatesOptionsAppClienteError(state, action);

    case actionTypes.LIST_STATES_INITIALS_OPTIONS_START:
      return listStatesInitialsOptionsStart(state);
    case actionTypes.LIST_STATES_INITIALS_OPTIONS_SUCCESS:
      return listStatesInitialsOptionsSuccess(state, action);
    case actionTypes.LIST_STATES_INITIALS_OPTIONS_ERROR:
      return listStatesInitialsOptionsError(state, action);

    case actionTypes.LIST_STATES_ASSOCIATED_START:
      return listStatesAssociatedStart(state);
    case actionTypes.LIST_STATES_ASSOCIATED_SUCCESS:
      return listStatesAssociatedSuccess(state, action);
    case actionTypes.LIST_STATES_ASSOCIATED_ERROR:
      return listStatesAssociatedError(state, action);

    default:
      return state;
  }
};

export default statesReducer;
