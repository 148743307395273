/**
 * @param  {} timestampValue
 */
export const timestampToHtmlDate = timestampValue => {
  const date = new Date(timestampValue);

  let day = `${date.getUTCDate()}`;
  let month = `${date.getUTCMonth() + 1}`;
  const year = `${date.getFullYear()}`;

  if (day.length === 1) {
    day = `0${day}`;
  }
  if (month.length === 1) {
    month = `0${month}`;
  }
  return `${year}-${month}-${day}`;
};

/**
 * @param  {} date
 */
export const dateToUTCTimestamp = date => {
  const now = date;
  const utcTimestamp = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds(),
  );
  return utcTimestamp;
};

/**
 * @param  {} dateToValidate
 */
export const isValidDate = dateToValidate => {
  const date = dateToValidate.split("-");

  if (date[2] < 1 || date[2] > 31) {
    return false;
  }

  if (date[1] < 1 || date[1] > 12) {
    return false;
  }

  if (date[0] < 1902 || date[0] > new Date().getFullYear()) {
    return false;
  }

  return true;
};

export const addDaysToDate = (date, days = 0) => {
  const dateObject = date instanceof Date ? date : new Date(date);
  return new Date(dateObject.getTime() + days * 24 * 60 * 60 * 1000);
};

/**
 * @param  {} date1
 * @param  {} date2
 */
export const compareDate = (date1, date2) => {
  // check if a date is greater than or equal to a date value
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return d2.getTime() >= d1.getTime();
};

/**
 * @param  {} date
 * @param  {} format='dd/mm/yyyy'
 */
export const formatDate = (date, format = "dd/mm/yyyy") => {
  const dateObject = date instanceof Date ? date : new Date(date);
  const day = dateObject
    .getDate()
    .toString()
    .padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();

  return format
    .replace("dd", day)
    .replace("mm", month)
    .replace("yyyy", year);
};

export const formatUTCDate = (date, format = "dd/mm/yyyy") => {
  const dateObject = date instanceof Date ? date : new Date(date);
  const day = dateObject
    .getUTCDate()
    .toString()
    .padStart(2, "0");
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getUTCFullYear();
  return format
    .replace("dd", day)
    .replace("mm", month)
    .replace("yyyy", year);
};

export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const getMonthsOptions = yearsObject => {
  const response = {};
  Object.keys(yearsObject).forEach(year => {
    const months = yearsObject[year];
    const options = [];
    months.forEach(month => {
      if (monthKeys[month - 1]) {
        options.push({
          label: monthKeys[month - 1],
          id: month,
        });
      }
    });
    response[year] = options;
  });
  return response;
};

export const getYearsOptions = yearsObject => {
  const options = [];
  Object.keys(yearsObject).map(key => {
    options.push({
      label: key,
      id: key,
    });
  });
  return options;
};

export const getProposalInitialDate = (days, date = new Date()) => {
  if (days) date.setDate(date.getDate() + days);
  return date;
};

export const monthKeys = [
  "jan",
  "fev",
  "mar",
  "abr",
  "mai",
  "jun",
  "jul",
  "ago",
  "set",
  "out",
  "nov",
  "dez",
];

export const JANUARY = 0;
export const FEBRUARY = 1;
export const MARCH = 2;
export const APRIL = 3;
export const MAY = 4;
export const JUNE = 5;
export const JULY = 6;
export const AUGUST = 7;
export const SEPTEMBER = 8;
export const OCTUBER = 9;
export const NOVEMBER = 10;
export const DECEMBER = 11;

export const extendedMonthKeys = {
  [JANUARY]: { short: "Jan", name: "Janeiro", days: 31 },
  [FEBRUARY]: { short: "Fev", name: "Fevereiro", days: 28 },
  [MARCH]: { short: "Mar", name: "Março", days: 31 },
  [APRIL]: { short: "Abr", name: "Abril", days: 30 },
  [MAY]: { short: "Mai", name: "Maio", days: 31 },
  [JUNE]: { short: "Jun", name: "Junho", days: 30 },
  [JULY]: { short: "Jul", name: "Julho", days: 31 },
  [AUGUST]: { short: "Ago", name: "Agosto", days: 31 },
  [SEPTEMBER]: { short: "Set", name: "Setembro", days: 30 },
  [OCTUBER]: { short: "Out", name: "Outubro", days: 31 },
  [NOVEMBER]: { short: "Nov", name: "Novembro", days: 30 },
  [DECEMBER]: { short: "Dez", name: "Dezembro", days: 31 },
};

export const formateDateToLocale = date =>
  typeof date === "string" ? new Date(date).toLocaleDateString("pt-br") : "-";

export const getMonthsFromRange = (fromDateValue, toDateValue) => {
  const addLeadingZeros = (num, totalLength) => {
    if (num < 0) {
      const withoutMinus = String(num).slice(1);
      return "-" + withoutMinus.padStart(totalLength, "0");
    }

    return String(num).padStart(totalLength, "0");
  };

  const fromDate = typeof fromDateValue === "string" ? new Date(fromDateValue) : fromDateValue;
  const toDate = typeof toDateValue === "string" ? new Date(toDateValue) : toDateValue;

  const fromYear = fromDate.getFullYear();
  const fromMonth = fromDate.getMonth();
  const toYear = toDate.getFullYear();
  const toMonth = toDate.getMonth();
  const months = [];
  for (let year = fromYear; year <= toYear; year++) {
    let month = year === fromYear ? fromMonth : 0;
    const monthLimit = year === toYear ? toMonth : 11;
    for (; month <= monthLimit; month++) {
      const fixedLengthMonth = addLeadingZeros(month + 1, 2);
      months.push(`${fixedLengthMonth}/${year}`);
    }
  }
  return months;
};

export const getDateFromMonthYear = monthYear => {
  const [month, year] = monthYear.split("/");
  return new Date(year, month - 1, 1);
};

export const getWeeksInMonth = (year, month) => {
  const weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();

  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }

  return weeks.length;
};
