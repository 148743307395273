import { updateObject } from "utils/utility";
import * as actionTypes from "./pricing.actionTypes";

const initialState = {
  listPricing: {
    list: [],
    error: "",
    loading: false,
    message: "",
  },
  filterForm: {
    error: "",
    loading: false,
    message: "",
  },
  listPricingStatus: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    pricingStatus: [],
  },
  getRecommendedPricing: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    recommendedPricing: {},
  },
  downloadPricingFile: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  listRecommendation: {
    newList: [],
  },
  listRecommendationModal: {
    active: false,
    continue: false,
    tab: "",
  },
  saveModal: {
    active: false,
  },
  listingRecommendation: {
    list: [],
    error: "",
    message: "",
    loading: false,
    config: {},
    filterFields: {},
  },
  saveRecommendation: {
    list: [],
    error: "",
    message: "",
    loading: false,
  },
  extension: {
    loading: false,
    saleId: null,
    days: null,
    error: "",
    message: "",
  },
  listExtension: {
    list: [],
    loading: false,
    error: "",
    message: "",
    config: {},
  },
  newListExtension: {
    list: [],
  },
  savedExtensionApproval: {
    loading: false,
    error: "",
    message: "",
  },
  uploadDocuments: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    documentFile: undefined,
  },
  documentsPricingList: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    documents: [],
    config: {},
  },
  documentForEdit: [],
  downloadSalesWithoutRecommendation: {
    resultType: "danger",
    loading: false,
    error: null,
    message: null,
  },
  clipboardNoRecommendation: "",
  listPricingHistoric: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    pricingHistoric: [],
    config: {},
  },
  downloadPricingHistoricFile: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  observerUploadStatus: {
    oldStatus: null,
  },
  pricingServicesList: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    pendingList: [],
    realizedList: [],
    filterFields: {},
    config: {},
    servicesList: [],
  },
  selectedSalesPricingServices: {
    list: [],
  },
  servicesTransactions: {
    sale: {},
  },
  saveServices: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
};

/* DEFAULT STATES */

const pricingDefaultStartState = {
  loading: true,
  error: null,
  message: null,
};

const pricingDefaultSuccessState = {
  loading: false,
  error: null,
  message: null,
  resultType: "success",
};

const pricingDefaultErrorState = action => {
  return {
    resultType: "danger",
    loading: false,
    error: action.error,
    message: action.message,
  };
};

/* DEFAULT FUNCTIONS */

const pricingDefaultStart = state =>
  updateObject(state, {
    ...state,
    ...pricingDefaultStartState,
  });

const pricingDefaultSucess = state =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
  });

const pricingDefaultError = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
  });

const pricingListSucess = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    list: action.payload,
  });

const reset = () => initialState;

const listPricingStatusSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    pricingStatus: action.pricingStatus.map(({ envio, mensagemStatus, ...pricing }) => ({
      dataEnvio: new Date(envio)
        .toLocaleString("pt-br")
        .replace(" ", " - ")
        .replace(",", " "),
      status: mensagemStatus,
      ...pricing,
    })),
  });

const listPricingStatusError = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    pricingStatus: [],
  });

const getRecommendedPricingSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    recommendedPricing: action.recommendedPricing,
  });

const getRecommendedPricingError = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    recommendedPricing: {},
  });

const getPricingDocumentsListSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    documents: action.documents,
    config: action.config,
  });

const getPricingDocumentsListError = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    documents: [],
    config: {},
  });

const getSalesWithoutRecommendationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    list: action.list,
    config: action.config,
    filterFields: action.filterFields,
  });
};

const getSalesWithoutRecommendationError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    list: [],
    filterFields: {},
  });
};

const requestExtensionSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    saleId: action.payload.saleId,
    days: action.payload.days,
  });
};

const getListRequestingExtendSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    list: action.list,
    config: action.config,
  });
};

const getListRequestingExtendError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    config: {},
  });
};

const listPricingHistoricSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    config: action.config,
    pricingHistoric: action.pricingHistoric.map(({ dataEnvio, ...pricing }) => ({
      dataEnvio: new Date(dataEnvio)
        .toLocaleString("pt-br")
        .replace(" ", " - ")
        .replace(",", " "),
      ...pricing,
    })),
  });

const listPricingHistoricError = (state, action) =>
  updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    pricingHistoric: [],
  });

const observerUploadStatus = (state, action) =>
  updateObject(state, {
    observerUploadStatus: {
      oldStatus: action.payload,
    },
  });

const clipboardNoRecommendationDate = (state, action) =>
  updateObject(state, {
    clipboardNoRecommendation: action.payload,
  });

const listPricingWithFilterStart = state => {
  return updateObject(state, {
    filterForm: {
      ...state.filterForm,
      ...pricingDefaultStartState,
    },
  });
};

const listPricingWithFilterSuccess = (state, action) => {
  return updateObject(state, {
    listPricing: {
      ...state.listPricing,
      list: action.payload,
    },
    filterForm: {
      loading: false,
    },
  });
};

const listPricingWithFilterError = (state, action) => {
  return updateObject(state, {
    filterForm: {
      error: action.error,
      message: action.message,
    },
  });
};

const saveExtendList = (state, { payload }) => {
  return updateObject(state, {
    newListExtension: {
      list: payload,
    },
  });
};

const documentsForEditData = (state, action) =>
  updateObject(state, {
    documentForEdit: action.payload,
  });

const verifyDate = (payload, state, filtered) => {
  if (payload.date) {
    if (payload.date === "") {
      let index = state.listRecommendation.newList.findIndex(
        x => x.saleTransactionId === payload.saleTransactionId,
      );
      let newArray = state.listRecommendation.newList;

      newArray.splice(index, 1);

      return newArray;
    } else {
      if (filtered?.length > 0) {
        let index = state.listRecommendation.newList.findIndex(
          x => x.saleTransactionId === payload.saleTransactionId,
        );
        let newArray = state.listRecommendation.newList;
        newArray[index].date = payload.date;

        return newArray;
      } else {
        return [...state.listRecommendation.newList, payload];
      }
    }
  }
};

const verifyValue = (payload, state, filtered) => {
  if (filtered?.length > 0) {
    let index = state.listRecommendation.newList.findIndex(
      x => x.saleTransactionId === payload.saleTransactionId,
    );
    let newArray = state.listRecommendation.newList;
    newArray[index].value = payload.value;

    return newArray;
  } else {
    return [...state.listRecommendation.newList, payload];
  }
};

const updateSalesWithoutRecommendation = (state, { payload }) => {
  if (!payload) {
    return updateObject(state, {
      listRecommendation: {
        newList: [],
      },
    });
  } else {
    const filtered = state.listRecommendation.newList?.filter(
      obj => obj.saleTransactionId === payload.saleTransactionId,
    );

    let list = [];
    list = verifyDate(payload, state, filtered);
    list = verifyValue(payload, state, filtered);

    return updateObject(state, {
      listRecommendation: {
        newList: list,
      },
    });
  }
};

const modalNoRecommendationStatus = (state, action) => {
  return updateObject(state, {
    listRecommendationModal: {
      active: action.payload.active,
      continue: action.payload.continue,
      tab: action.payload.tab,
    },
  });
};

const modalSaveNoRecommendation = (state, action) => {
  return updateObject(state, {
    saveModal: {
      active: action.payload.active,
      description: action.payload.description,
      title: action.payload.title,
    },
  });
};

const getPricingServicesStart = state => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultStartState,
    pendingList: [],
    realizedList: [],
    filterFields: {},
  });
};

const getPricingPendingServicesListSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    pendingList: action.list,
    config: action.config,
    filterFields: action.filterFields,
  });
};

const getPricingPendingServicesListError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    pendingList: [],
    filterFields: {},
  });
};

const getPricingRealizedServicesListSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    realizedList: action.list,
    config: action.config,
    filterFields: action.filterFields,
  });
};

const getPricingRealizedServicesListError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    realizedList: [],
    filterFields: {},
  });
};

const setSelectedSalesPricingServices = (state, action) => {
  const hasSale = state?.list?.filter(sale => sale.id === action.id).length > 0;
  let newList = [];

  if (hasSale) {
    newList = [
      ...state.list.map(sale => {
        if (sale.id === action.id) {
          return { id: action.id, checked: action.checked };
        } else {
          return sale;
        }
      }),
    ];
  } else {
    if (state?.list?.length > 0) {
      newList = [...state.list, { id: action.id, checked: action.checked }];
    } else {
      newList = [{ id: action.id, checked: action.checked }];
    }
  }

  newList = newList.filter(sale => sale.checked);

  return updateObject(state, {
    ...state,
    list: [...newList],
  });
};

const setAllSelectedSalesPricingServices = (state, action) => {
  let newList = [];
  if (action.ids?.length > 0) {
    newList = action.ids.map(id => {
      return { id, checked: action.checked };
    });
  }
  return updateObject(state, {
    ...state,
    list: [...newList],
  });
};

const resetSelectedSalesPricingServices = state => {
  return updateObject(state, {
    ...state,
    list: [],
  });
};

const getServicesStart = state => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultStartState,
    servicesList: [],
  });
};

const getServicesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    servicesList: action.servicesList,
  });
};

const getServicesError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    servicesList: [],
  });
};

const getServicesTransactionsStart = state => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultStartState,
    sale: [],
  });
};

const getServicesTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultSuccessState,
    sale: action.sale,
  });
};

const getServicesTransactionsError = (state, action) => {
  return updateObject(state, {
    ...state,
    ...pricingDefaultErrorState(action),
    sale: [],
  });
};

const resetServicesTransactions = state => {
  return updateObject(state, {
    sale: [],
  });
};

function listPricingStatusReducer(state = initialState.listPricingStatus, action = "") {
  switch (action.type) {
    case actionTypes.LIST_PRICING_STATUS_START:
      return pricingDefaultStart(state);
    case actionTypes.LIST_PRICING_STATUS_SUCCESS:
      return listPricingStatusSuccess(state, action);
    case actionTypes.LIST_PRICING_STATUS_ERROR:
      return listPricingStatusError(state, action);
    default:
      return state;
  }
}

function getRecommendedPricingReducer(state = initialState.getRecommendedPricing, action = "") {
  switch (action.type) {
    case actionTypes.GET_RECOMMENDED_PRICING_START:
      return pricingDefaultStart(state);
    case actionTypes.GET_RECOMMENDED_PRICING_SUCCESS:
      return getRecommendedPricingSuccess(state, action);
    case actionTypes.GET_RECOMMENDED_PRICING_ERROR:
      return getRecommendedPricingError(state, action);
    default:
      return state;
  }
}

function sendPricingFileReducer(state = initialState.sendPricingFile, action = "") {
  switch (action.type) {
    case actionTypes.SEND_PRICING_FILE_START:
      return pricingDefaultStart(state);
    case actionTypes.SEND_PRICING_FILE_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.SEND_PRICING_FILE_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function downloadPricingFileReducer(state = initialState.downloadPricingFile, action = "") {
  switch (action.type) {
    case actionTypes.DOWNLOAD_PRICING_FILE_START:
      return pricingDefaultStart(state);
    case actionTypes.DOWNLOAD_PRICING_FILE_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.DOWNLOAD_PRICING_FILE_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function getPricingReducer(state = initialState.listPricing, action = "") {
  switch (action.type) {
    case actionTypes.GET_PRICING_START:
      return pricingDefaultStart(state);
    case actionTypes.GET_PRICING_SUCCESS:
      return pricingListSucess(state, action);
    case actionTypes.GET_PRICING_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function getSalesWithoutRecommendationReducer(
  state = initialState.listingRecommendation,
  action = "",
) {
  switch (action.type) {
    case actionTypes.GET_SALES_WITHOUT_RECOMMENDATION_START:
      return pricingDefaultStart(state);
    case actionTypes.GET_SALES_WITHOUT_RECOMMENDATION_SUCCESS:
      return getSalesWithoutRecommendationSuccess(state, action);
    case actionTypes.GET_SALES_WITHOUT_RECOMMENDATION_ERROR:
      return getSalesWithoutRecommendationError(state, action);
    default:
      return state;
  }
}

function saveNewRecommendationReducer(state = initialState.saveRecommendation, action = "") {
  switch (action.type) {
    case actionTypes.SAVE_NEW_RECOMMENDATION_START:
      return pricingDefaultStart(state);
    case actionTypes.SAVE_NEW_RECOMMENDATION_SUCCESS:
      return pricingListSucess(state, action);
    case actionTypes.SAVE_NEW_RECOMMENDATION_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function requestExtensionReducer(state = initialState.extension, action = "") {
  switch (action.type) {
    case actionTypes.REQUEST_EXTENSION_START:
      return pricingDefaultStart(state);
    case actionTypes.REQUEST_EXTENSION_SUCCESS:
      return requestExtensionSuccess(state, action);
    case actionTypes.REQUEST_EXTENSION_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function getListRequestingExtendReducer(state = initialState.listExtension, action = "") {
  switch (action.type) {
    case actionTypes.GET_EXTEND_REQUESTING_DATA_START:
      return pricingDefaultStart(state);
    case actionTypes.GET_EXTEND_REQUESTING_DATA_SUCCESS:
      return getListRequestingExtendSuccess(state, action);
    case actionTypes.GET_EXTEND_REQUESTING_DATA_ERROR:
      return getListRequestingExtendError(state, action);
    default:
      return state;
  }
}

function saveExtendApprovalReducer(state = initialState.savedExtensionApproval, action = "") {
  switch (action.type) {
    case actionTypes.SAVE_EXTEND_APPROVAL_START:
      return pricingDefaultStart(state);
    case actionTypes.SAVE_EXTEND_APPROVAL_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.SAVE_EXTEND_APPROVAL_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function uploadDocumentsReducer(state = initialState.uploadDocuments, action = "") {
  switch (action.type) {
    case actionTypes.UPLOAD_DOCUMENTS_START:
      return pricingDefaultStart(state);
    case actionTypes.UPLOAD_DOCUMENTS_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.UPLOAD_DOCUMENTS_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

function getPricingDocumentsListReducer(state = initialState.documentsPricingList, action = "") {
  switch (action.type) {
    case actionTypes.GET_PRICING_DOCUMENTS_LIST_START:
      return pricingDefaultStart(state);
    case actionTypes.GET_PRICING_DOCUMENTS_LIST_SUCCESS:
      return getPricingDocumentsListSuccess(state, action);
    case actionTypes.GET_PRICING_DOCUMENTS_LIST_ERROR:
      return getPricingDocumentsListError(state, action);
    default:
      return state;
  }
}

function editDocumentsInfoReducer(state = initialState.editDocumentsInfo, action = "") {
  switch (action.type) {
    case actionTypes.EDIT_DOCUMENTS_INFO_START:
      return pricingDefaultStart(state);
    case actionTypes.EDIT_DOCUMENTS_INFO_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.EDIT_DOCUMENTS_INFO_ERROR:
      return pricingDefaultError(state, action);

    default:
      return state;
  }
}

function deleteDocumentsInfoReducer(state = initialState.deleteDocumentsInfo, action = "") {
  switch (action.type) {
    case actionTypes.DELETE_DOCUMENTS_INFO_START:
      return pricingDefaultStart(state);
    case actionTypes.DELETE_DOCUMENTS_INFO_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.DELETE_DOCUMENTS_INFO_ERROR:
      return pricingDefaultError(state, action);

    default:
      return state;
  }
}

function downloadSalesWithoutRecommendationFileReducer(
  state = initialState.downloadSalesWithoutRecommendation,
  action = "",
) {
  switch (action.type) {
    case actionTypes.DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_START:
      return pricingDefaultStart(state);
    case actionTypes.DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_ERROR:
      return pricingDefaultError(state, action);

    default:
      return state;
  }
}

function listPricingHistoricReducer(state = initialState.listPricingHistoric, action = "") {
  switch (action.type) {
    case actionTypes.LIST_PRICING_HISTORIC_START:
      return pricingDefaultStart(state);
    case actionTypes.LIST_PRICING_HISTORIC_SUCCESS:
      return listPricingHistoricSuccess(state, action);
    case actionTypes.LIST_PRICING_HISTORIC_ERROR:
      return listPricingHistoricError(state, action);

    default:
      return state;
  }
}

function downloadPricingHistoricFileReducer(
  state = initialState.downloadPricingHistoricFile,
  action = "",
) {
  switch (action.type) {
    case actionTypes.DOWNLOAD_PRICING_HISTORIC_FILE_START:
      return pricingDefaultStart(state);
    case actionTypes.DOWNLOAD_PRICING_HISTORIC_FILE_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.DOWNLOAD_PRICING_HISTORIC_FILE_ERROR:
      return pricingDefaultError(state, action);

    default:
      return state;
  }
}

function getPricingServicesListReducer(state = initialState.pricingServicesList, action = "") {
  switch (action.type) {
    case actionTypes.GET_PRICING_PENDING_SERVICES_LIST_START:
      return getPricingServicesStart(state);
    case actionTypes.GET_PRICING_PENDING_SERVICES_LIST_SUCCESS:
      return getPricingPendingServicesListSuccess(state, action);
    case actionTypes.GET_PRICING_PENDING_SERVICES_LIST_ERROR:
      return getPricingPendingServicesListError(state, action);
    case actionTypes.GET_PRICING_REALIZED_SERVICES_LIST_START:
      return getPricingServicesStart(state);
    case actionTypes.GET_PRICING_REALIZED_SERVICES_LIST_SUCCESS:
      return getPricingRealizedServicesListSuccess(state, action);
    case actionTypes.GET_PRICING_REALIZED_SERVICES_LIST_ERROR:
      return getPricingRealizedServicesListError(state, action);
    case actionTypes.GET_SERVICES_START:
      return getServicesStart(state);
    case actionTypes.GET_SERVICES_SUCCESS:
      return getServicesSuccess(state, action);
    case actionTypes.GET_SERVICES_ERROR:
      return getServicesError(state, action);

    default:
      return state;
  }
}

function selectedSalesPricingServicesReducer(
  state = initialState.selectedSalesPricingServices,
  action,
) {
  switch (action.type) {
    case actionTypes.SELECTED_SALES_PRICING_SERVICES:
      return setSelectedSalesPricingServices(state, action);
    case actionTypes.SELECTED_ALL_SALES_PRICING_SERVICES:
      return setAllSelectedSalesPricingServices(state, action);
    case actionTypes.RESET_SELECTED_SALES_PRICING_SERVICES:
      return resetSelectedSalesPricingServices(state);
    default:
      return state;
  }
}

function getServicesTransactionsReducer(state = initialState.servicesTransactions, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICES_TRANSACTIONS_START:
      return getServicesTransactionsStart(state);
    case actionTypes.GET_SERVICES_TRANSACTIONS_SUCCESS:
      return getServicesTransactionsSuccess(state, action);
    case actionTypes.GET_SERVICES_TRANSACTIONS_ERROR:
      return getServicesTransactionsError(state, action);

    case actionTypes.RESET_SERVICES_TRANSACTIONS:
      return resetServicesTransactions(state);

    default:
      return state;
  }
}

function saveServicesReducer(state = initialState.saveServices, action = "") {
  switch (action.type) {
    case actionTypes.SAVE_SERVICES_START:
      return pricingDefaultStart(state);
    case actionTypes.SAVE_SERVICES_SUCCESS:
      return pricingDefaultSucess(state);
    case actionTypes.SAVE_SERVICES_ERROR:
      return pricingDefaultError(state, action);
    default:
      return state;
  }
}

const pricingReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_PRICING_WITH_FILTERS_START:
      return listPricingWithFilterStart(state);
    case actionTypes.LIST_PRICING_WITH_FILTERS_SUCCESS:
      return listPricingWithFilterSuccess(state, action);
    case actionTypes.LIST_PRICING_WITH_FILTERS_ERROR:
      return listPricingWithFilterError(state, action);

    case actionTypes.UPDATE_SALES_WITHOUT_RECOMMENDATION:
      return updateSalesWithoutRecommendation(state, action);

    case actionTypes.MODAL_NO_RECOMMENDATION_STATUS:
      return modalNoRecommendationStatus(state, action);
    case actionTypes.SAVE_MODAL:
      return modalSaveNoRecommendation(state, action);

    case actionTypes.UPDATE_EXTEND_LIST:
      return saveExtendList(state, action);

    case actionTypes.OBSERVER_UPLOAD_STATUS:
      return observerUploadStatus(state, action);

    case actionTypes.CLIPBOARD_NO_RECOMMENDATION_DATE:
      return clipboardNoRecommendationDate(state, action);

    case actionTypes.DOCUMENTS_EDIT:
      return documentsForEditData(state, action);

    default:
      return {
        ...state,
        listPricingStatus: listPricingStatusReducer(state.listPricingStatus, action),
        getRecommendedPricing: getRecommendedPricingReducer(state.getRecommendedPricing, action),
        sendPricingFile: sendPricingFileReducer(state.sendPricingFile, action),
        downloadPricingFile: downloadPricingFileReducer(state.downloadPricingFile, action),
        listPricing: getPricingReducer(state.listPricing, action),
        listingRecommendation: getSalesWithoutRecommendationReducer(
          state.listingRecommendation,
          action,
        ),
        saveRecommendation: saveNewRecommendationReducer(state.saveRecommendation, action),
        extension: requestExtensionReducer(state.extension, action),
        listExtension: getListRequestingExtendReducer(state.listExtension, action),
        savedExtensionApproval: saveExtendApprovalReducer(state.savedExtensionApproval, action),
        uploadDocuments: uploadDocumentsReducer(state.uploadDocuments, action),
        documentsPricingList: getPricingDocumentsListReducer(state.documentsPricingList, action),
        editDocumentsInfo: editDocumentsInfoReducer(state.editDocumentsInfo, action),
        deleteDocumentsInfo: deleteDocumentsInfoReducer(state.deleteDocumentsInfo, action),
        downloadSalesWithoutRecommendation: downloadSalesWithoutRecommendationFileReducer(
          state.downloadSalesWithoutRecommendation,
          action,
        ),
        listPricingHistoric: listPricingHistoricReducer(state.listPricingHistoric, action),
        downloadPricingHistoricFile: downloadPricingHistoricFileReducer(
          state.downloadPricingHistoricFile,
          action,
        ),
        pricingServicesList: getPricingServicesListReducer(state.pricingServicesList, action),
        selectedSalesPricingServices: selectedSalesPricingServicesReducer(
          state.selectedSalesPricingServices,
          action,
        ),
        servicesTransactions: getServicesTransactionsReducer(state.servicesTransactions, action),
        saveServices: saveServicesReducer(state.saveServices, action),
      };
  }
};

export default pricingReducer;
