import { updateObject } from "utils/utility";
import * as actionTypes from "./flowCode.actionTypes";

const initialState = {
  listFlowCodeOptions: {
    loading: false,
    error: null,
    message: null,
    resultType: null,
    flowCodeOptions: [],
  },
  listSalesOptions: {
    loading: false,
    error: null,
    message: null,
    resultType: null,
    salesOptions: [],
    config: {},
    filterFields: {},
  },
};

const resetSalesOptions = () =>
  updateObject(initialState, {
    listSalesOptions: {
      ...initialState.listSalesOptions,
    },
  });

const listFlowCodeOptionsStart = state =>
  updateObject(state, {
    listFlowCodeOptions: {
      ...state.listFlowCodeOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listFlowCodeOptionsSuccess = (state, action) =>
  updateObject(state, {
    listFlowCodeOptions: {
      ...state.listFlowCodeOptions,
      loading: false,
      resultType: "success",
      message: null,
      flowCodeOptions: action.list,
    },
  });

const listFlowCodeOptionsError = (state, action) =>
  updateObject(state, {
    listFlowCodeOptions: {
      ...state.listFlowCodeOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      flowCodeOptions: [],
    },
  });

const listSalesOptionsStart = state =>
  updateObject(state, {
    listSalesOptions: {
      ...state.listSalesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listSalesOptionsSuccess = (state, action) => {
  return updateObject(state, {
    listSalesOptions: {
      ...state.listSalesOptions,
      loading: false,
      resultType: "success",
      message: null,
      salesOptions: action.data,
      config: action.config,
      filterFields: action.filterFields,
    },
  });
};

const listSalesOptionsError = (state, action) =>
  updateObject(state, {
    listSalesOptions: {
      ...state.listSalesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      salesOptions: [],
    },
  });

const flowCodeReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return resetSalesOptions();

    case actionTypes.LIST_FLOW_CODE_OPTIONS_START:
      return listFlowCodeOptionsStart(state);
    case actionTypes.LIST_FLOW_CODE_OPTIONS_SUCCESS:
      return listFlowCodeOptionsSuccess(state, action);
    case actionTypes.LIST_FLOW_CODE_OPTIONS_ERROR:
      return listFlowCodeOptionsError(state, action);

    case actionTypes.LIST_SALES_OPTIONS_START:
      return listSalesOptionsStart(state);
    case actionTypes.LIST_SALES_OPTIONS_SUCCESS:
      return listSalesOptionsSuccess(state, action);
    case actionTypes.LIST_SALES_OPTIONS_ERROR:
      return listSalesOptionsError(state, action);

    default:
      return state;
  }
};

export default flowCodeReducer;
