import DefaultRequestParams from "utils/defaultRequestParams";
import ListingDefaultState from "utils/listingDefaultState";
import * as actionTypes from "./sales.actionTypes";
import { updateObject } from "utils/utility";
import { SALES_TYPE } from "constants/sales";

const initialState = {
  saleForm: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: undefined,
    resume: {},
    isLinked: null,
  },
  listSales: ListingDefaultState("client", { idSaleType: [SALES_TYPE.RAILROAD.id] }, true),
  listSalesCheckDuplicated: ListingDefaultState(
    "client",
    { idSaleType: [SALES_TYPE.RAILROAD.id] },
    true,
  ),
  exportForm: Object.assign(DefaultRequestParams(), { exportFilters: [] }),
  exportHistory: DefaultRequestParams(),
  getSale: Object.assign(DefaultRequestParams(), { detail: {} }),
  saleHistory: Object.assign(DefaultRequestParams(), { detail: { user: { name: "" } } }),
  saleDefaultTab: 0,
  alterSaleStatus: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  selectedSegmentN1: {
    0: undefined,
  },
  hasSaleFieldChanged: {
    0: false,
  },
  flowCode: {
    0: "noFlowCode",
  },
  saleNewExperiencePeriod: {
    0: undefined,
  },
  isNewRecommendation: {
    0: [],
  },
  typeOfSale: {
    0: "",
  },
  saleRecommendedTaxPrice: {},
  saleOutOfMargin: {
    error: null,
    message: null,
    loading: false,
    details: [],
  },
  transactionsSituationUpdate: {
    error: null,
    message: null,
    loading: false,
  },
  listSalesStatus: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    salesStatus: [],
  },
  listMargins: [],

  oldSaleModalType: [],
  newSaleModalType: [],
  listNegotiationDeadlines: {
    list: [],
    resultType: "danger",
    loading: false,
    error: null,
    message: null,
    config: {},
  },
  isResetingFilter: false,
  salesStatus: {
    list: [],
    resultType: "danger",
    loading: false,
    error: null,
    message: null,
  },
  separateSales: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  salesIds: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    list: [],
  },
};

const deleteTransactionFromObject = (object, transactionToRemove) => {
  if (object === null || object === undefined) return object;

  const { [transactionToRemove]: _removedTransaction, ...remainingTransactions } = object;
  const splitTransaction = transactionToRemove.split(".");
  const year = splitTransaction[1];
  const month = splitTransaction[2];
  const transactionToRemoveIndex = splitTransaction[3];

  const removeMonthNumber = month.replace(/\D/g, "");
  const removeYearNumber = year.replace(/\D/g, "");
  const newTransactions = {};

  Object.keys(remainingTransactions).forEach(transactionKey => {
    const transactionKeySplit = transactionKey.split(".");
    const currentYear = transactionKeySplit[1];
    const currentMonth = transactionKeySplit[2];
    const transactionIndex = transactionKeySplit[3];
    const propertyValues = transactionKey.split(".").slice(0, -1);
    const propertyPrefix = propertyValues.join(".");
    const currentMonthNumber = currentMonth.replace(/\D/g, "");
    const currentYearNumber = currentYear.replace(/\D/g, "");

    if (currentYearNumber === removeYearNumber && currentMonthNumber === removeMonthNumber) {
      if (Number(transactionIndex) < Number(transactionToRemoveIndex)) {
        newTransactions[`${propertyPrefix}.${transactionIndex}`] =
          remainingTransactions[transactionKey];
      } else {
        newTransactions[`${propertyPrefix}.${transactionIndex - 1}`] =
          remainingTransactions[transactionKey];
      }
    } else {
      newTransactions[transactionKey] = remainingTransactions[transactionKey];
    }
  });
  return newTransactions;
};

/* DEFAULT STATES */

const salesDefaultStartState = {
  loading: true,
  error: null,
  message: null,
};

const salesDefaultSuccessState = {
  loading: false,
  error: null,
  message: null,
  resultType: "success",
};

const salesDefaultErrorState = action => {
  return {
    resultType: "danger",
    loading: false,
    error: action.error,
    message: action.message,
  };
};

/* DEFAULT FUNCTIONS */

const listError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    rows: [],
  });

const detailSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    detail: action.detail,
    updateTime: Date.now(),
  });

const detailError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    detail: {},
  });

const salesDefaultStart = state =>
  updateObject(state, {
    ...state,
    ...salesDefaultStartState,
  });

const salesDefaultSucess = state =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
  });

const salesDefaultError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
  });

/* FUNCTIONS */

const reset = state => ({
  ...initialState,
  selectedSegmentN1: state.selectedSegmentN1,
  hasSaleFieldChanged: state.hasSaleFieldChanged,
  flowCode: state.flowCode,
  saleNewExperiencePeriod: state.saleNewExperiencePeriod,
  isNewRecommendation: state.isNewRecommendation,
  typeOfSale: state.typeOfSale,
  listSales: state.listSales,
  saleDefaultTab: state.saleDefaultTab,
});

const resetSale = state => ({
  ...state,
  getSale: Object.assign(DefaultRequestParams(), { detail: {} }),
  saleForm: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: undefined,
    resume: {},
    isLinked: null,
  },
});

const isResetingFilter = (state, action) =>
  updateObject(state, {
    isResetingFilter: action.payload,
  });

const saleSetTab = (state, action) =>
  updateObject(state, {
    saleDefaultTab: action.index,
  });

const setSelectedSegmentN1 = (state, action) => {
  return updateObject(state, {
    ...state,
    selectedSegmentN1: {
      ...state.selectedSegmentN1,
      [action.tabId]: action.payload,
    },
  });
};

const hasSaleFieldChanged = (state, action) =>
  updateObject(state, {
    ...state,
    hasSaleFieldChanged: {
      ...state.hasSaleFieldChanged,
      [action.tabId]: action.payload,
    },
  });

/*  tipo de venda */

const setTypeOfSale = (state, action) =>
  updateObject(state, {
    ...state,
    typeOfSale: {
      ...state.typeOfSale,
      [action.tabId]: action.payload,
    },
  });

const setSaleNewExperiencePeriod = (state, action) =>
  updateObject(state, {
    ...state,
    saleNewExperiencePeriod: {
      ...state.saleNewExperiencePeriod,
      [action.tabId]: action.payload,
    },
  });

const setSaleModalType = (state, action) =>
  updateObject(state, {
    oldSaleModalType: state.newSaleModalType,
    newSaleModalType: action.payload,
  });

/* list sales */

const listSalesSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    rows: action.list,
    options: {
      ...state.options,
      itemsPerPageCount: action.options?.itemsPerPageCount,
      page: action.options?.page,
      totalPages: action.options?.totalPages,
      totalCount: action.options?.totalCount,
    },
    updateTime: Date.now(),
  });

const listSales = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    updateTime: Date.now(),
    allSales: action.payload,
  });

const resetSalesList = state => ({
  ...state,
  loading: false,
  rows: [],
});

/* save sales list filters */

const saleSaveFilter = (state, action) =>
  updateObject(state, {
    ...state,
    options: {
      ...state.options,
      filterFields: action.values,
    },
  });

const saleIncrementFilter = (state, action) =>
  updateObject(state, {
    ...state,
    options: {
      ...state.options,
      filterFields: {
        ...state.options.filterFields,
        ...action.values,
      },
    },
  });

const saleResetFilter = state =>
  updateObject(state, {
    ...state,
    options: {
      ...state.options,
      filterFields: {},
    },
  });

/* form sale */

const saveSaleSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    resume: action.resume,
    isLinked: action.isLinked,
    averageLiquidTax: action.averageLiquidTax,
  });

const saveNSalesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    resume: action.resume,
  });
};

const finishSaleCreating = state =>
  updateObject(state, {
    ...state,
    resultType: "danger",
  });

/* list sales check duplicated */

const listSalesCheckDuplicatedSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    rows: action.list,
    updateTime: Date.now(),
  });

/* export sales items (excel sheet) */

const exportSalesStart = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultStartState,
    exportFilters: action.exportFilters,
  });

const exportResetFilter = state =>
  updateObject(state, {
    ...state,
    exportFilters: [],
  });

const resetHistory = () =>
  Object.assign(DefaultRequestParams(), { detail: { user: { name: "" } } });

/*  sale's recommended tax price */

const getSaleRecommendedTaxPriceStart = (state, action) => {
  const recommendations = action?.payload;
  let newObj = {};

  Object.keys(recommendations).forEach(index => {
    newObj[recommendations?.[index].property] = {
      taxRecommendation: 0,
      loading: true,
    };
  });

  return updateObject(state, {
    ...state,
    [action.tabId]: {
      ...salesDefaultStartState,
      price: {
        ...state[action.tabId]?.price,
        ...newObj,
      },
    },
  });
};

const getSaleRecommendedTaxPriceSuccess = (state, action) => {
  const recommendations = action?.payload;
  let newObj = {};
  Object.keys(recommendations).forEach(index => {
    newObj[recommendations?.[index].propriedade] = {
      taxRecommendation: recommendations?.[index].tarifa,
      recommendationId: recommendations?.[index].id,
      loading: false,
    };
  });

  return updateObject(state, {
    ...state,
    [action.tabId]: {
      ...salesDefaultSuccessState,
      price: {
        ...state[action.tabId]?.price,
        ...newObj,
      },
    },
  });
};

const getSaleRecommendedTaxPriceError = (state, action) => {
  const recommendations = action?.payload;
  let newObj = {};

  Object.keys(recommendations).forEach(index => {
    newObj[recommendations?.[index].property] = {
      taxRecommendation: 0,
      recommendationId: null,
      loading: false,
    };
  });

  return updateObject(state, {
    ...state,
    [action.tabId]: {
      error: action.error,
      message: action.message,
      price: {
        ...state[action.tabId]?.price,
        ...newObj,
      },
      loading: false,
    },
  });
};

const deleteSaleRecommendedTaxPrice = (state, action) => {
  const newPrices = deleteTransactionFromObject(state?.[action.tabId]?.price, action.payload);
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      error: null,
      message: null,
      price: newPrices,
    },
  });
};

const updateRecommendedTaxPrices = (state, action) => {
  const newPrices = {
    ...state[action.tabId]?.price,
    ...action.payload,
  };
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      error: null,
      message: null,
      price: newPrices,
    },
  });
};

const shouldTransactionUpdate = (state, action) => {
  const newTransaction = { ...state[action.tabId]?.price?.[action.transactionKey] };
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      price: {
        ...state[action.tabId]?.price,
        [action.transactionKey]: {
          ...newTransaction,
          shouldUpdate: action.shouldUpdate,
          taxRecommendation: 0,
          recommendationId: null,
        },
      },
    },
  });
};

/* flow code */

const getFlowCodeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: "noFlowCode",
  });
};

const getFlowCodeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: action.payload,
  });
};

const getFlowCodeError = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: null,
  });
};

const resetFlowCode = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: "noFlowCode",
  });
};

/* list sales status */

const listSalesStatusSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    salesStatus: action.salesStatus.map(({ envio, mensagemStatus, ...salesFile }) => ({
      dataEnvio: new Date(envio).toLocaleString("pt-br").replace(" ", " - "),
      status: mensagemStatus,
      ...salesFile,
    })),
  });

const listSalesStatusError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    salesStatus: [],
  });

/* list all margins */

const listAllMarginsSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    margins: action.payload.margins,
  });

/* is new recommendation*/

const isNewRecommendation = (state, action) => {
  let newArr = [];

  let newState = state[action.tabId];
  if (action.payload) {
    if (newState?.map(item => item.id).includes(action.payload.id)) {
      newArr = newState?.filter(item => item.id !== action.payload.id);
      newArr.push(action.payload);
    } else {
      if (newState) {
        newArr = [...newState, action.payload];
      } else {
        newArr.push(action.payload);
      }
    }
  }
  return updateObject(state, {
    ...state,
    [action.tabId]: newArr,
  });
};

const resetIsNewRecommendation = (state, action) =>
  updateObject(state, {
    ...state,
    [action.tabId]: [],
  });

/* negotiation sales deadlines list */

const getAllNegotiationSalesDeadlinesListSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    list: action.payload,
    config: action.config,
  });

const getAllNegotiationSalesDeadlinesListError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    list: [],
    config: {},
  });

/* get sales status */

const getSalesStatusSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    rows: action.rows,
  });

/* get sales ids */

const getSalesIdsSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    list: action.ids,
  });

const getSalesIdsError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    list: [],
  });

const resetSaleRecommendedTaxPrice = (state, action) =>
  updateObject(state, {
    ...state,
    saleRecommendedTaxPrice: {
      ...state.saleRecommendedTaxPrice,
      [action.tabId]: null,
    },
  });

function listSalesReducer(state = initialState.listSales, action = "") {
  switch (action.type) {
    case actionTypes.LIST_SALES_START:
      return salesDefaultStart(state);
    case actionTypes.LIST_SALES_SUCCESS:
      return listSalesSuccess(state, action);
    case actionTypes.LIST_SALES_ERROR:
      return listError(state, action);
    case actionTypes.LIST_SALES:
      return listSales(state, action);
    case actionTypes.RESET_SALES_LIST:
      return resetSalesList(state);

    case actionTypes.SALE_SAVE_FILTER:
      return saleSaveFilter(state, action);
    case actionTypes.SALE_INCREMENT_FILTER:
      return saleIncrementFilter(state, action);
    case actionTypes.SALE_RESET_FILTER:
      return saleResetFilter(state);

    default:
      return state;
  }
}

function saleFormReducer(state = initialState.saleForm, action = "") {
  switch (action.type) {
    case actionTypes.SAVE_SALE_START:
    case actionTypes.EDIT_SALE_START:
    case actionTypes.DELETE_SALE_START:
    case actionTypes.SAVE_SALES_START:
      return salesDefaultStart(state);
    case actionTypes.SAVE_SALE_SUCCESS:
      return saveSaleSuccess(state, action);
    case actionTypes.SAVE_SALE_ERROR:
      return salesDefaultError(state, action);

    case actionTypes.SAVE_SALES_SUCCESS:
      return saveNSalesSuccess(state, action);
    case actionTypes.SAVE_SALES_ERROR:
      return salesDefaultError(state, action);

    case actionTypes.FINISH_SALE_CREATING:
      return finishSaleCreating(state);

    case actionTypes.EDIT_SALE_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.EDIT_SALE_ERROR:
      return salesDefaultError(state, action);

    case actionTypes.DELETE_SALE_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.DELETE_SALE_ERROR:
      return salesDefaultError(state, action);

    default:
      return state;
  }
}

function listSalesCheckDuplicatedReducer(
  state = initialState.listSalesCheckDuplicated,
  action = "",
) {
  switch (action.type) {
    case actionTypes.LIST_SALES_CHECK_DUPLICATED_START:
      return salesDefaultStart(state);
    case actionTypes.LIST_SALES_CHECK_DUPLICATED_SUCCESS:
      return listSalesCheckDuplicatedSuccess(state, action);
    case actionTypes.LIST_SALES_CHECK_DUPLICATED_ERROR:
      return listError(state, action);
    default:
      return state;
  }
}

function flowCodeReducer(state = initialState.flowCode, action = "") {
  switch (action.type) {
    case actionTypes.GET_FLOW_CODE_START:
      return getFlowCodeStart(state, action);
    case actionTypes.GET_FLOW_CODE_SUCCESS:
      return getFlowCodeSuccess(state, action);
    case actionTypes.GET_FLOW_CODE_ERROR:
      return getFlowCodeError(state, action);
    case actionTypes.RESET_FLOW_CODE:
      return resetFlowCode(state, action);

    default:
      return state;
  }
}

function exportSalesReducer(state = initialState.exportForm, action = "") {
  switch (action.type) {
    case actionTypes.EXPORT_SALES_START:
      return exportSalesStart(state, action);
    case actionTypes.EXPORT_SALES_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.EXPORT_SALES_ERROR:
      return salesDefaultError(state, action);
    case actionTypes.EXPORT_RESET_FILTER:
      return exportResetFilter(state);
    default:
      return state;
  }
}

function getSaleReducer(state = initialState.getSale, action = "") {
  switch (action.type) {
    case actionTypes.GET_SALE_START:
      return salesDefaultStart(state);
    case actionTypes.GET_SALE_SUCCESS:
      return detailSuccess(state, action);
    case actionTypes.GET_SALE_ERROR:
      return detailError(state, action);
    default:
      return state;
  }
}

function getSaleHistoryReducer(state = initialState.saleHistory, action = "") {
  switch (action.type) {
    case actionTypes.GET_SALE_HISTORY_START:
      return salesDefaultStart(state);
    case actionTypes.GET_SALE_HISTORY_SUCCESS:
      return detailSuccess(state, action);
    case actionTypes.GET_SALE_HISTORY_ERROR:
      return detailError(state, action);

    case actionTypes.RESET_HISTORY:
      return resetHistory();

    default:
      return state;
  }
}

function exportHistoryReducer(state = initialState.exportHistory, action = "") {
  switch (action.type) {
    case actionTypes.EXPORT_TRANSACTIONS_HISTORY_START:
      return salesDefaultStart(state);
    case actionTypes.EXPORT_TRANSACTIONS_HISTORY_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.EXPORT_TRANSACTIONS_HISTORY_ERROR:
      return salesDefaultError(state, action);
    default:
      return state;
  }
}

function alterSaleStatusReducer(state = initialState.alterSaleStatus, action = "") {
  switch (action.type) {
    case actionTypes.ALTER_SALE_STATUS_START:
      return salesDefaultStart(state);
    case actionTypes.ALTER_SALE_STATUS_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.ALTER_SALE_STATUS_ERROR:
      return salesDefaultError(state, action);

    default:
      return state;
  }
}

function getSaleRecommendedTaxPriceReducer(
  state = initialState.saleRecommendedTaxPrice,
  action = "",
) {
  switch (action.type) {
    case actionTypes.GET_RECOMMENDED_TAX_PRICE_START:
      return getSaleRecommendedTaxPriceStart(state, action);
    case actionTypes.GET_RECOMMENDED_TAX_PRICE_SUCCESS:
      return getSaleRecommendedTaxPriceSuccess(state, action);
    case actionTypes.GET_RECOMMENDED_TAX_PRICE_ERROR:
      return getSaleRecommendedTaxPriceError(state, action);

    case actionTypes.RESET_RECOMMENDED_TAX_PRICE:
      return resetSaleRecommendedTaxPrice(state, action);

    case actionTypes.DELETE_RECOMMENDED_TAX_PRICE:
      return deleteSaleRecommendedTaxPrice(state, action);
    case actionTypes.UPDATE_RECOMMENDED_PRICES:
      return updateRecommendedTaxPrices(state, action);
    case actionTypes.SHOULD_TRANSACTION_UPDATE:
      return shouldTransactionUpdate(state, action);

    default:
      return state;
  }
}

function transactionsSituationUpdateReducer(
  state = initialState.transactionsSituationUpdate,
  action = "",
) {
  switch (action.type) {
    case actionTypes.UPDATE_TRANSACTIONS_SITUATION_START:
      return salesDefaultStart(state);
    case actionTypes.UPDATE_TRANSACTIONS_SITUATION_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.UPDATE_TRANSACTIONS_SITUATION_ERROR:
      return salesDefaultError(state, action);

    default:
      return state;
  }
}

function listSalesStatusReducer(state = initialState.listSalesStatus, action = "") {
  switch (action.type) {
    case actionTypes.LIST_SALES_STATUS_START:
      return salesDefaultStart(state);
    case actionTypes.LIST_SALES_STATUS_SUCCESS:
      return listSalesStatusSuccess(state, action);
    case actionTypes.LIST_SALES_STATUS_ERROR:
      return listSalesStatusError(state, action);

    default:
      return state;
  }
}

function newRecommendationReducer(state = initialState.isNewRecommendation, action = "") {
  switch (action.type) {
    case actionTypes.IS_NEW_RECOMMENDATION:
      return isNewRecommendation(state, action);
    case actionTypes.RESET_IS_NEW_RECOMMENDATION:
      return resetIsNewRecommendation(state, action);

    default:
      return state;
  }
}

function listAllMarginsReducer(state = initialState.listMargins, action = "") {
  switch (action.type) {
    case actionTypes.GET_LIST_MARGINS_START:
      return salesDefaultStart(state);
    case actionTypes.GET_LIST_MARGINS_SUCCESS:
      return listAllMarginsSuccess(state, action);
    case actionTypes.GET_LIST_MARGINS_ERROR:
      return salesDefaultError(state, action);

    default:
      return state;
  }
}

function getAllNegotiationSalesDeadlinesReducer(
  state = initialState.listNegotiationDeadlines,
  action = "",
) {
  switch (action.type) {
    case actionTypes.GET_NEGOTIATION_SALES_DEADLINES_LIST_START:
      return salesDefaultStart(state);
    case actionTypes.GET_NEGOTIATION_SALES_DEADLINES_LIST_SUCCESS:
      return getAllNegotiationSalesDeadlinesListSuccess(state, action);
    case actionTypes.GET_NEGOTIATION_SALES_DEADLINES_LIST_ERROR:
      return getAllNegotiationSalesDeadlinesListError(state, action);

    default:
      return state;
  }
}

function getSalesStatusReducer(state = initialState.salesStatus, action = "") {
  switch (action.type) {
    case actionTypes.GET_SALES_STATUS_START:
      return salesDefaultStart(state);
    case actionTypes.GET_SALES_STATUS_SUCCESS:
      return getSalesStatusSuccess(state, action);
    case actionTypes.GET_SALES_STATUS_ERROR:
      return listError(state, action);

    default:
      return state;
  }
}

function separateSalesReducer(state = initialState.separateSales, action = "") {
  switch (action.type) {
    case actionTypes.SEPARATE_SALE_START:
      return salesDefaultStart(state);
    case actionTypes.SEPARATE_SALE_SUCCESS:
      return salesDefaultSucess(state);
    case actionTypes.SEPARATE_SALE_ERROR:
      return salesDefaultError(state, action);

    default:
      return state;
  }
}

function getSalesIdsReducer(state = initialState.salesIds, action = "") {
  switch (action.type) {
    case actionTypes.GET_SALES_IDS_START:
      return salesDefaultStart(state);
    case actionTypes.GET_SALES_IDS_SUCCESS:
      return getSalesIdsSuccess(state, action);
    case actionTypes.GET_SALES_IDS_ERROR:
      return getSalesIdsError(state, action);

    default:
      return state;
  }
}

const salesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset(state);
    case actionTypes.RESET_SALE:
      return resetSale(state);
    case actionTypes.IS_RESETING_FILTER:
      return isResetingFilter(state, action);
    case actionTypes.SALE_SET_TAB:
      return saleSetTab(state, action);
    case actionTypes.SET_SELECTED_SEGMENTN1:
      return setSelectedSegmentN1(state, action);
    case actionTypes.HAS_SALE_FIELD_CHANGED:
      return hasSaleFieldChanged(state, action);

    case actionTypes.SET_TYPE_OF_SALE:
      return setTypeOfSale(state, action);
    case actionTypes.SET_SALE_NEW_EXPERIENCE_PERIOD:
      return setSaleNewExperiencePeriod(state, action);
    case actionTypes.SET_SALE_MODAL_TYPE:
      return setSaleModalType(state, action);

    default:
      return {
        ...state,
        listSales: listSalesReducer(state.listSales, action),
        saleForm: saleFormReducer(state.saleForm, action),
        listSalesCheckDuplicated: listSalesCheckDuplicatedReducer(
          state.listSalesCheckDuplicated,
          action,
        ),
        flowCode: flowCodeReducer(state.flowCode, action),
        exportForm: exportSalesReducer(state.exportForm, action),
        exportHistory: exportHistoryReducer(state.exportHistory, action),
        getSale: getSaleReducer(state.getSale, action),
        saleHistory: getSaleHistoryReducer(state.saleHistory, action),
        listSalesStatus: listSalesStatusReducer(state.listSalesStatus, action),
        alterSaleStatus: alterSaleStatusReducer(state.alterSaleStatus, action),
        saleRecommendedTaxPrice: getSaleRecommendedTaxPriceReducer(
          state.saleRecommendedTaxPrice,
          action,
        ),
        transactionsSituationUpdate: transactionsSituationUpdateReducer(
          state.transactionsSituationUpdate,
          action,
        ),
        isNewRecommendation: newRecommendationReducer(state.isNewRecommendation, action),
        listMargins: listAllMarginsReducer(state.listMargins, action),
        listNegotiationDeadlines: getAllNegotiationSalesDeadlinesReducer(
          state.listNegotiationDeadlines,
          action,
        ),
        salesStatus: getSalesStatusReducer(state.salesStatus, action),
        separateSales: separateSalesReducer(state.separateSales, action),
        salesIds: getSalesIdsReducer(state.salesIds, action),
      };
  }
};

export default salesReducer;
