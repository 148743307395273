import { updateObject } from "utils/utility";
import ListingOptions from "utils/listingOptions";
import * as actionTypes from "./users.actionTypes";

const initialState = {
  listUsers: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    options: new ListingOptions("nome"),
    rows: [],
    updateTime: undefined,
  },
  userDetails: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    details: undefined,
    updateTime: undefined,
  },
  userForm: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: undefined,
  },

  userSubSegments: {
    loading: false,
    error: null,
    resultType: "",
    list: [],
  },
  exportUsersFile: {
    loading: false,
    error: null,
    resultType: "",
    message: null,
    file: undefined,
  },
  deleteUser: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
};

const defaultStartState = {
  loading: true,
  error: null,
  message: null,
};

const defaultSuccessState = {
  loading: false,
  error: null,
  message: null,
  resultType: "success",
};

const defaultErrorState = action => ({
  loading: false,
  error: action.error,
  message: action.message,
  resultType: "danger",
});

const usersDefaultStart = state =>
  updateObject(state, {
    ...state,
    ...defaultStartState,
  });

const usersDefaultSuccess = state =>
  updateObject(state, {
    ...state,
    ...defaultSuccessState,
  });

const usersDefaultError = (state, action) =>
  updateObject(state, {
    ...state,
    ...defaultErrorState(action),
  });

const successDetail = (state, action) =>
  updateObject(state, {
    ...state,
    ...defaultSuccessState,
    details: action.details,
    updateTime: Date.now(),
  });

/* list users options */

const listUsersSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    ...defaultSuccessState,
    rows: action.list,
    options: action.config,
    updateTime: Date.now(),
  });
};

const listUsersError = (state, action) =>
  updateObject(state, {
    ...state,
    ...defaultErrorState(action),
    rows: [],
  });

const listUsersOptions = (state, action) =>
  updateObject(state, {
    ...state,
    options: action.options,
  });

const getUserSubSegmentsSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...defaultSuccessState,
    list: action.subsegments,
    optionsDS: action.subsegments.map(subsegment => ({
      label: subsegment.label,
      value: { ...subsegment },
    })),
  });

// REDUCERS

function listUsersReducer(state = initialState.listUsers, action = "") {
  switch (action.type) {
    case actionTypes.LIST_USERS_START:
      return usersDefaultStart(state);
    case actionTypes.LIST_USERS_SUCCESS:
      return listUsersSuccess(state, action);
    case actionTypes.LIST_USERS_ERROR:
      return listUsersError(state, action);

    case actionTypes.LIST_USERS_OPTIONS:
      return listUsersOptions(state, action);

    default:
      return state;
  }
}

function listUserDetailsReducer(state = initialState.userDetails, action = "") {
  switch (action.type) {
    case actionTypes.LIST_USER_DETAILS_START:
      return usersDefaultStart(state);
    case actionTypes.LIST_USER_DETAILS_SUCCESS:
      return successDetail(state, action);
    case actionTypes.LIST_USER_DETAILS_ERROR:
      return usersDefaultError(state, action);

    case actionTypes.LIST_USER_DETAILS_SIMPLIFIED_START:
      return usersDefaultStart(state);
    case actionTypes.LIST_USER_DETAILS_SIMPLIFIED_SUCCESS:
      return successDetail(state, action);
    case actionTypes.LIST_USER_DETAILS_SIMPLIFIED_ERROR:
      return usersDefaultError(state, action);

    default:
      return state;
  }
}

function userFormReducer(state = initialState.userForm, action = "") {
  switch (action.type) {
    case actionTypes.SAVE_USER_START:
      return usersDefaultStart(state);
    case actionTypes.SAVE_USER_SUCCESS:
      return usersDefaultSuccess(state);
    case actionTypes.SAVE_USER_ERROR:
      return usersDefaultError(state, action);

    case actionTypes.EDIT_USER_START:
      return usersDefaultStart(state);
    case actionTypes.EDIT_USER_SUCCESS:
      return usersDefaultSuccess(state);
    case actionTypes.EDIT_USER_ERROR:
      return usersDefaultError(state, action);

    default:
      return state;
  }
}

function deleteUserReducer(state = initialState.deleteUser, action = "") {
  switch (action.type) {
    case actionTypes.DELETE_USER_START:
      return usersDefaultStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return usersDefaultSuccess(state);
    case actionTypes.DELETE_USER_ERROR:
      return usersDefaultError(state, action);

    default:
      return state;
  }
}

function exportUsersFileReducer(state = initialState.exportUsersFile, action = "") {
  switch (action.type) {
    case actionTypes.EXPORT_USERS_FILE_START:
      return usersDefaultStart(state);
    case actionTypes.EXPORT_USERS_FILE_SUCCESS:
      return usersDefaultSuccess(state);
    case actionTypes.EXPORT_USERS_FILE_FAIL:
      return usersDefaultError(state, action);

    default:
      return state;
  }
}

function getUsersSubSegmentsReducer(state = initialState.userSubSegments, action = "") {
  switch (action.type) {
    case actionTypes.GET_USER_SUBSEGMENTS_START:
      return usersDefaultStart(state);
    case actionTypes.GET_USER_SUBSEGMENTS_SUCCESS:
      return getUserSubSegmentsSuccess(state, action);
    case actionTypes.GET_USER_SUBSEGMENTS_FAIL:
      return usersDefaultError(state, action);

    default:
      return state;
  }
}

const usersReducer = (state = initialState, action = "") => {
  return {
    listUsers: listUsersReducer(state.listUsers, action),
    userDetails: listUserDetailsReducer(state.userDetails, action),
    userForm: userFormReducer(state.userForm, action),
    deleteUser: deleteUserReducer(state.deleteUser, action),
    exportUsersFile: exportUsersFileReducer(state.exportUsersFile, action),
    userSubSegments: getUsersSubSegmentsReducer(state.userSubSegments, action),
  };
};

export default usersReducer;
