import API from "utils/api";
import { basicErrorHandler } from "utils/utility";
import * as actions from "./clients.actions";
import { formatClientsProposal } from "./clients.format";

export const listClientsOptions = (desativados = false) => dispatch => {
  dispatch(actions.listClientsOptionsStart());

  const url = `/v1/cliente?ListarDesativados=${desativados}`;

  API.get(url)
    .then(response => {
      const adaptedResponse = response.data.map(cliente => {
        return {
          id: cliente.id,
          name: cliente.nome,
          active: cliente.ativo,
        };
      });

      dispatch(actions.listClientsOptionsSuccess(adaptedResponse));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listClientsOptionsError));
};

export const listClientsForProposals = (format = true) => dispatch => {
  dispatch(actions.listClientsForProposalsStart());
  const url = "/v1/cliente?Proposta=true";

  API.get(url)
    .then(response => {
      const adaptedResponse = response.data.map(cliente => {
        return {
          id: cliente.id,
          name: cliente.nome,
          dominio: cliente.dominio,
          descricao: cliente.descricao,
          associated: cliente.associado,
        };
      });
      const clients = format ? formatClientsProposal(adaptedResponse) : adaptedResponse;
      dispatch(actions.listClientsForProposalsSuccess(clients));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listClientsForProposalsError));
};

export const getRoutes = clientId => dispatch => {
  dispatch(actions.listRoutesStart());
  const url = `/v1/cliente/${clientId}/rotas`;
  API.get(url)
    .then(response => {
      dispatch(actions.listRoutesSuccess(response.data));
    })
    .catch(error => {
      basicErrorHandler(error, dispatch, actions.listRoutesFail);
    });
};

export const createSegmentTreeClientRelationship = (clientId, id, successCallback) => dispatch => {
  dispatch(actions.saveSegmentTreeClientRelationshipStart());
  const url = "v1/ArvoreSegmento/Cliente";

  API.post(url, { ClienteGestaoId: clientId, id })
    .then(() => {
      dispatch(actions.saveSegmentTreeClientRelationshipSuccess());
      dispatch(listClientsOptions());

      if (successCallback) {
        successCallback();
      }
    })
    .catch(error =>
      basicErrorHandler(error, dispatch, actions.saveSegmentTreeClientRelationshipError),
    );
};
