export const RESET = "RESET";

export const LIST_CITIES_OPTIONS_START = "LIST_CITIES_OPTIONS_START";
export const LIST_CITIES_OPTIONS_SUCCESS = "LIST_CITIES_OPTIONS_SUCCESS";
export const LIST_CITIES_OPTIONS_ERROR = "LIST_CITIES_OPTIONS_ERROR";
export const LIST_CITIES_OPTIONS_NO_CLIENT_START = "LIST_CITIES_OPTIONS_NO_CLIENT_START";
export const LIST_CITIES_OPTIONS_NO_CLIENT_SUCCESS = "LIST_CITIES_OPTIONS_NO_CLIENT_SUCCESS";
export const LIST_CITIES_OPTIONS_NO_CLIENT_ERROR = "LIST_CITIES_OPTIONS_NO_CLIENT_ERROR";

export const LIST_CITIES_OPTIONS_V1_START = "LIST_CITIES_OPTIONS_V1_START";
export const LIST_CITIES_OPTIONS_V1_SUCCESS = "LIST_CITIES_OPTIONS_V1_SUCCESS";
export const LIST_CITIES_OPTIONS_V1_ERROR = "LIST_CITIES_OPTIONS_V1_ERROR";

export const LIST_CITIES_OPTIONS_V1_BY_STATES_START = "LIST_CITIES_OPTIONS_V1_BY_STATES_START";
export const LIST_CITIES_OPTIONS_V1_BY_STATES_SUCCESS = "LIST_CITIES_OPTIONS_V1_BY_STATES_SUCCESS";
export const LIST_CITIES_OPTIONS_V1_BY_STATES_ERROR = "LIST_CITIES_OPTIONS_V1_BY_STATES_ERROR";
