export const RESET = "RESET";

export const LIST_ORIGINS_HIGHLIGHT_OPTIONS_START = "LIST_ORIGINS_HIGHLIGHT_OPTIONS_START";
export const LIST_ORIGINS_HIGHLIGHT_OPTIONS_SUCCESS = "LIST_ORIGINS_HIGHLIGHT_OPTIONS_SUCCESS";
export const LIST_ORIGINS_HIGHLIGHT_OPTIONS_ERROR = "LIST_ORIGINS_HIGHLIGHT_OPTIONS_ERROR";

export const ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_START =
  "ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_START";
export const ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_SUCCESS =
  "ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_SUCCESS";
export const ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_ERROR =
  "ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_ERROR";
