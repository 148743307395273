import { autoListingParamsGet } from "lib/listing";
import { notificationAPI } from "utils/api";
import * as actions from "./notifications.actions";
import { formatNotificationsBackToFront } from "./notifications.format";
import Axios from "axios";
import { uncoverText } from "lib/auth";
import ls from "local-storage";
import { downloadBlob } from "lib/document";
import { getFileName } from "lib/files";
import { addMessage } from "store/messages/messages.useCases";

export const openNotificationMenu = isOpen => dispatch => {
  dispatch(actions.openNotificationMenu(isOpen));
};

export const getNotifications = (systemId, email, page, itemsPerPageCount) => dispatch => {
  dispatch(actions.getNotificationsStart());

  const enhancedOptions = {
    filterFields: { SistemaId: systemId, Email: email },
    itemsPerPageCount,
    page,
  };

  const url = `v1/Notificacao?${autoListingParamsGet(enhancedOptions)}`;

  notificationAPI
    .get(url)
    .then(response => {
      const { data } = response.data;
      dispatch(
        actions.getNotificationsSuccess({
          notifications: formatNotificationsBackToFront(data),
          config: {
            itemsPerPageCount: response.data?.pageSize,
            page: response.data?.page,
            totalPages: response.data?.totalPages,
            totalCount: response.data?.totalRegistries,
          },
        }),
      );
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.getNotificationsError());
    });
};

export const getUnreadNotifications = (systemId, email) => dispatch => {
  dispatch(actions.getUnreadNotificationsStart());

  const url = `v1/Notificacao/nao-lidas?SistemaId=${systemId}&Email=${email}`;
  notificationAPI
    .get(url)
    .then(response => {
      dispatch(actions.getUnreadNotificationsSuccess(response.data?.notificacoes));
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.getUnreadNotificationsError());
    });
};

export const getLastNotifications = (systemId, email) => dispatch => {
  dispatch(actions.getLastNotificationsStart());

  const url = `v1/Notificacao?SistemaId=${systemId}&skip=0&take=3&Email=${email}`;
  notificationAPI
    .get(url)
    .then(response => {
      const { data } = response.data;

      dispatch(actions.getLastNotificationsSuccess(formatNotificationsBackToFront(data)));
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.getLastNotificationsError());
    });
};

export const updateReadNotification = (id, successCallback) => dispatch => {
  dispatch(actions.updateReadNotificationStart());

  const url = `v1/Notificacao?id=${id}`;

  notificationAPI
    .patch(url)
    .then(() => {
      dispatch(actions.updateReadNotificationSuccess());
      successCallback();
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.updateReadNotificationError());
    });
};

export const allReadNotification = (email, successCallback) => dispatch => {
  dispatch(actions.updateReadNotificationStart());

  const url = `v1/Notificacao/todas-lidas?email=${email}`;

  notificationAPI
    .patch(url)
    .then(() => {
      dispatch(actions.updateReadNotificationSuccess());
      successCallback();
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.updateReadNotificationError());
    });
};

export const downloadNotification = caminhoDownload => dispatch => {
  dispatch(actions.downloadNotificationStart());

  const clientId = "6da52b98-9179-3234-98cc-6980a2dca367";

  const accessToken = `${uncoverText(ls.get("usd")).accessToken}`;
  const password = `${uncoverText(ls.get("usd")).password}`;

  Axios.get(caminhoDownload, {
    responseType: "blob",
    headers: {
      common: {
        client_id: clientId,
        access_token: accessToken,
        authorization: `Bearer ${password}`,
      },
    },
  })
    .then(response => {
      downloadBlob(response.data, getFileName(response));
      dispatch(addMessage("Arquivo baixado com sucesso!", "success"));
      dispatch(actions.downloadNotificationSuccess());
    })
    .catch(error => {
      console.error("Erro na API de notificação:", error);
      dispatch(actions.downloadNotificationError());
    });
};
