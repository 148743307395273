import ls from "local-storage";
import { LOGIN } from "utils/urls";
import { config } from "utils/config";

/**
 * cover and uncover text to 'ommit' things on localhost
 *
 * @param  {} uncoveredText
 * @param  {} =>btoa(JSON.stringify(uncoveredText
 */
export const coverText = uncoveredText => btoa(JSON.stringify(uncoveredText));

export const uncoverText = coveredText => {
  try {
    return JSON.parse(atob(coveredText));
  } catch (error) {
    return false;
  }
};

/**
 * @param  {} section
 */
export const permission = section => {
  try {
    return uncoverText(ls.get("per"))[section];
  } catch (err) {
    return false;
  }
};

/**
 * @param  {} section
 * @param  {} perm
 */
export const hasPermission = (section, perm) => {
  try {
    const permissionRes = uncoverText(ls.get("per"))[section];
    if (Array.isArray(permissionRes) && permissionRes.includes(perm)) {
      return true;
    }
    return !!(typeof permissionRes === "string" && permissionRes === perm);
  } catch (err) {
    return false;
  }
};

/**
 */
export const getUserData = () => {
  return uncoverText(ls.get("per"))?.data;
};

/**
 * log out the user from the application
 */
export const logout = (dispatch, history) => {
  dispatch({ type: "USER_LOGOUT" });
  localStorage.clear();
  history.replace(LOGIN);
};

export const isNotProdEnv = () =>
  config.apiurl.indexOf("dev") !== -1 || config.apiurl.indexOf("hom") !== -1;
