import { combineReducers } from "redux";
import { history } from "store/store";

import loginReducer from "./login/loginReducer";
import usersReducer from "./users/usersReducer";
import rolesReducer from "./roles/rolesReducer";
import profilesReducer from "./profiles/profilesReducer";
import messagesReducer from "./messages/messagesReducer";
import segmentsN1Reducer from "./segmentsN1/segmentsN1Reducer";
import subSegmentsReducer from "./subSegments/subSegmentsReducer";
import clientsReducer from "./clients/clientsReducer";
import cradlesReducer from "./cradles/cradlesReducer";
import productsReducer from "./products/productsReducer";
import dieselReducer from "./diesel/dieselReducer";
import destinationsReducer from "./destinations/destinationsReducer";
import hallsReducer from "./halls/hallsReducer";
import originsReducer from "./origins/originsReducer";
import regionsReducer from "./regions/regionsReducer";
import salesReducer from "./sales/salesReducer";
import salesRearrangeReducer from "./salesRearrange/salesRearrangeReducer";
import scenesReducer from "./scenes/scenesReducer";
import reportsReducer from "./reports/reportsReducer";
import statesReducer from "./states/statesReducer";
import factoriesReducer from "./factories/factoriesReducer";
import uploadReducer from "./upload/uploadReducer";
import citiesReducer from "./cities/citiesReducer";
import powerbiReducer from "./powerbi/powerbiReducer";
import panoramaReducer from "./panorama/panoramaReducer";
import integrationReducer from "./integration/integrationReducer";
import pricingReducer from "./pricing/pricingReducer";
import observationsReducer from "./observations/observationsReducer";
import taxCalculationReducer from "./taxCalculation/taxCalculationReducer";
import periodsReducer from "./periods/periodsReducer";
import permissionsReducer from "./permissions/permissionsReducer";
import saleTypesReducer from "./saleTypes/saleTypesReducer";
import proposalSolutionsReducer from "./proposalSolutions/proposalSolutionsReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import errorReducer from "./error/errorReducer";
import newExperienceSaleReducer from "./newExperienceSale/newExperienceSaleReducer";
import pessoaJuridicaReducer from "./pessoaJuridica/pessoaJuridicaReducer";
import segmentTreeReducer from "./segmentTree/segmentTreeReducer";
import flowCodeReducer from "./flowCode/flowCodeReducer";

const Reducers = () =>
  combineReducers({
    router: history,
    login: loginReducer,
    users: usersReducer,
    roles: rolesReducer,
    profiles: profilesReducer,
    messages: messagesReducer,
    segmentsN1: segmentsN1Reducer,
    subSegments: subSegmentsReducer,
    clients: clientsReducer,
    cradles: cradlesReducer,
    products: productsReducer,
    diesel: dieselReducer,
    destinations: destinationsReducer,
    halls: hallsReducer,
    origins: originsReducer,
    regions: regionsReducer,
    sales: salesReducer,
    salesRearrange: salesRearrangeReducer,
    scenes: scenesReducer,
    reports: reportsReducer,
    states: statesReducer,
    factories: factoriesReducer,
    upload: uploadReducer,
    cities: citiesReducer,
    powerbi: powerbiReducer,
    panoramas: panoramaReducer,
    integration: integrationReducer,
    pricing: pricingReducer,
    observations: observationsReducer,
    taxCalculation: taxCalculationReducer,
    periods: periodsReducer,
    permissions: permissionsReducer,
    saleTypes: saleTypesReducer,
    proposalSolutions: proposalSolutionsReducer,
    notifications: notificationsReducer,
    error: errorReducer,
    newExperienceSale: newExperienceSaleReducer,
    pessoaJuridica: pessoaJuridicaReducer,
    segmentTree: segmentTreeReducer,
    flowCode: flowCodeReducer,
  });

export default Reducers;
