import * as actionTypes from "./clients.actionTypes";

/* list clients options */

export const listClientsOptionsStart = () => ({
  type: actionTypes.LIST_CLIENTS_OPTIONS_START,
});

export const listClientsOptionsSuccess = clients => ({
  type: actionTypes.LIST_CLIENTS_OPTIONS_SUCCESS,
  clients,
});

export const listClientsOptionsError = (error, message) => ({
  type: actionTypes.LIST_CLIENTS_OPTIONS_ERROR,
  error,
  message,
});

/* list clients for proposals options */

export const listClientsForProposalsStart = () => ({
  type: actionTypes.LIST_CLIENTS_FOR_PROPOSALS_START,
});

export const listClientsForProposalsSuccess = clients => ({
  type: actionTypes.LIST_CLIENTS_FOR_PROPOSALS_SUCCESS,
  clients,
});

export const listClientsForProposalsError = (error, message) => ({
  type: actionTypes.LIST_CLIENTS_FOR_PROPOSALS_ERROR,
  error,
  message,
});

/* list of months years of client to link transactions */

export const listMonthsYearStart = () => ({
  type: actionTypes.LIST_MONTHS_YEAR_START,
});

export const listMonthsYearSuccess = monthsYear => ({
  type: actionTypes.LIST_MONTHS_YEAR_SUCCESS,
  monthsYear,
});

/* list of routes of client to link transactions */

export const listRoutesStart = () => ({
  type: actionTypes.LIST_ROUTES_START,
});

export const listRoutesSuccess = listRoutes => ({
  type: actionTypes.LIST_ROUTES_SUCCESS,
  listRoutes,
});

export const listRoutesFail = (error, message) => ({
  type: actionTypes.LIST_ROUTES_FAIL,
  error,
  message,
});

/* save segment tree and client relationship */
export const saveSegmentTreeClientRelationshipStart = () => ({
  type: actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_START,
});

export const saveSegmentTreeClientRelationshipSuccess = () => ({
  type: actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_SUCCESS,
});

export const saveSegmentTreeClientRelationshipError = (error, message) => ({
  type: actionTypes.SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_ERROR,
  error,
  message,
});
