import API from "utils/api";

import { basicErrorHandler } from "utils/utility";
import * as actions from "./halls.actions";

export const listHallsOptions = successCallback => dispatch => {
  dispatch(actions.listHallsOptionsStart());

  const url = "/v1/corredor";

  API.get(url)
    .then(response => {
      const adaptedResponse = response.data.map(corredor => {
        return { id: corredor.id, name: corredor.descricao, associated: corredor.associado };
      });

      dispatch(actions.listHallsOptionsSuccess(adaptedResponse));

      if (successCallback) {
        successCallback(
          adaptedResponse.map(item => ({
            id: item.id,
            label: item.name,
          })),
        );
      }
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listHallsOptionsError));
};
