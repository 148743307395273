import { updateObject } from "utils/utility";
import * as actionTypes from "./roles.actionTypes";

const initialState = {
  listRolesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    rolesOptions: [],
  },
};

const reset = () => initialState;

/* list roles options for comboboxes and autocompletes */

const listRolesOptionsStart = state =>
  updateObject(state, {
    listRolesOptions: {
      ...state.listRolesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listRolesOptionsSuccess = (state, action) =>
  updateObject(state, {
    listRolesOptions: {
      ...state.listRolesOptions,
      loading: false,
      resultType: "success",
      message: null,
      rolesOptions: action.roles.map(item => ({
        id: item.id,
        label: item.descricao,
      })),
      rolesOptionsDS: action.roles.map(item => ({
        value: item.id,
        label: item.descricao,
      })),
    },
  });

const listRolesOptionsError = (state, action) =>
  updateObject(state, {
    listRolesOptions: {
      ...state.listRolesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      rolesOptions: [],
    },
  });

const rolesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_ROLES_OPTIONS_START:
      return listRolesOptionsStart(state);
    case actionTypes.LIST_ROLES_OPTIONS_SUCCESS:
      return listRolesOptionsSuccess(state, action);
    case actionTypes.LIST_ROLES_OPTIONS_ERROR:
      return listRolesOptionsError(state, action);

    default:
      return state;
  }
};

export default rolesReducer;
