import API from "utils/api";
import { basicErrorHandler } from "utils/utility";
import { formatSaleTypes } from "./saleTypes.format";
import * as actions from "./saleTypes.actions";

export const getSaleTypes = () => dispatch => {
  dispatch(actions.getSaleTypesStart());

  const url = `/v1/VendaTipo`;

  API.get(url)
    .then(response => {
      const data = formatSaleTypes(response.data);
      dispatch(actions.getSaleTypesSuccess(data));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.getSaleTypesError));
};
