import API from "utils/api";
import { basicErrorHandler } from "utils/utility";
import * as actions from "./products.actions";

export const listProductsOptions = (desativados = false) => dispatch => {
  dispatch(actions.listProductsOptionsStart());

  const url = `/v1/Produto?ListarDesativados=${desativados}`;

  API.get(url)
    .then(response => {
      const adaptedResponse = response.data.map(produto => {
        return {
          id: produto.id,
          name: produto.nome,
          active: produto.ativo,
          associated: true,
          isSugar: produto.usina,
          unidadeMedida: produto.unidadeMedida,
          macroProduct: { id: produto.produtoMacro?.id, name: produto.produtoMacro?.descricao },
          groupedProduct: {
            id: produto.produtoAgrupado?.id,
            name: produto.produtoAgrupado?.descricao,
          },
        };
      });
      dispatch(actions.listProductsOptionsSuccess(adaptedResponse));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listProductsOptionsError));
};
