export const formatUserFrontToBack = values => {
  return {
    nome: values.name,
    matricula: values.registry,
    cargoId: values.roleId,
    perfilId: values.profileId,
    subSegmentoIds: values.subSegmentId ? [...values.subSegmentId] : [],
    telefone: String(values.phone),
  };
};

export const formatUserBackToFront = users => {
  return users.map(user => {
    return {
      id: user.id,
      name: user.nome,
      registry: user.matricula,
      role: {
        id: user.cargo.id,
        description: user.cargo.descricao,
      },
      profile: {
        id: user.perfil.id,
        description: user.perfil.descricao,
      },
      subSegment: user.subSegmentos.map(subSegment => {
        return {
          id: subSegment.id,
          name: subSegment.nome,
        };
      }),
    };
  });
};
