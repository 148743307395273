import { hasToken } from "utils/api";
import { HTTP_MESSAGES } from "constants/messages";
import * as actions from "./messages.actions";
import { setError } from "store/error/error.useCases";

export const contains = (text, content) => text.indexOf(content) !== -1;

const errorMessageIntercept = (message, dispatch) => {
  const text = message.toLowerCase();
  if (contains(text, "401")) {
    return HTTP_MESSAGES.UNAUTHORIZED;
  }
  if (contains(text, "404")) {
    dispatch(setError(true));
    return HTTP_MESSAGES.NOT_FOUND;
  }
  if (contains(text, "500")) {
    dispatch(setError(true));

    return HTTP_MESSAGES.INTERNAL_SERVER_ERROR;
  }
  if (contains(text, "504")) {
    dispatch(setError(true));

    return HTTP_MESSAGES.GATEWAY_TIMEOUT;
  }
  if (contains(text, "network error")) {
    dispatch(setError(true));
    return HTTP_MESSAGES.NETWORK_ERROR;
  }
  if (contains(text, "502")) {
    dispatch(setError(true));
    return HTTP_MESSAGES.INTERNAL_SERVER_ERROR;
  }
  return message;
};

const typeIntercept = (type, message) => {
  const text = message.toLowerCase();
  if (contains(text, "401")) {
    return "info";
  }
  if (contains(text, "network error")) {
    return "warning";
  }

  return type;
};

export const reset = () => dispatch => dispatch(actions.reset());

export const addMessage = (text, type) => dispatch => {
  if (hasToken()) {
    dispatch(
      actions.addMessage({
        text: errorMessageIntercept(text, dispatch),
        type: typeIntercept(type, text),
      }),
    );
  }
};

export const removeMessage = id => dispatch => dispatch(actions.removeMessage(id));

export const setToast = ({ active, status, message }) => dispatch => {
  dispatch(actions.setToast(active, status, message));
};
