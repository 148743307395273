import { updateObject } from "utils/utility";
import * as actionTypes from "./integration.actionTypes";

const INITIAL_STATE = {
  listIntegrationStatus: {
    loading: false,
    error: null,
    config: {},
    rows: [],
    updateTime: undefined,
  },
  synchronize: {
    error: null,
    resultType: "",
    loading: false,
    updateTime: undefined,
  },
};

const getIntegrationStatusStart = state =>
  updateObject(state, {
    listIntegrationStatus: {
      ...state.listIntegrationStatus,
      loading: true,
    },
  });

const getIntegrationStatusSuccess = (state, action) =>
  updateObject(state, {
    listIntegrationStatus: {
      ...state.listIntegrationStatus,
      loading: false,
      rows: action.result,
      config: action.config,
      updateTime: Date.now(),
    },
  });

const getIntegrationStatusFail = (state, action) =>
  updateObject(state, {
    listIntegrationStatus: {
      ...state.listIntegrationStatus,
      loading: false,
      rows: [],
      error: action.error,
      message: action.message,
      updateTime: Date.now(),
    },
  });

const setIntegrationStatusOptions = (state, action) =>
  updateObject(state, {
    listIntegrationStatus: {
      ...state.listIntegrationStatus,
      options: action.options,
    },
  });

/* synchronize */

const synchronizeStart = state =>
  updateObject(state, {
    synchronize: {
      ...state.synchronize,
      loading: true,
    },
  });

const synchronizeSuccess = (state, action) =>
  updateObject(state, {
    synchronize: {
      ...state.synchronize,
      loading: false,
      data: action.result,
      updateTime: Date.now(),
    },
  });

const synchronizeFail = (state, action) =>
  updateObject(state, {
    synchronize: {
      ...state.synchronize,
      loading: false,
      data: null,
      error: action.error,
      message: action.message,
      updateTime: Date.now(),
    },
  });

const reset = () => INITIAL_STATE;

const integrationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_STATUS_START:
      return getIntegrationStatusStart(state);

    case actionTypes.GET_STATUS_SUCCESS:
      return getIntegrationStatusSuccess(state, action);

    case actionTypes.GET_STATUS_FAIL:
      return getIntegrationStatusFail(state, action);

    case actionTypes.GET_STATUS_OPTIONS:
      return setIntegrationStatusOptions(state, action);

    case actionTypes.SYNCHRONIZE_START:
      return synchronizeStart(state);

    case actionTypes.SYNCHRONIZE_SUCCESS:
      return synchronizeSuccess(state, action);

    case actionTypes.SYNCHRONIZE_FAIL:
      return synchronizeFail(state, action);

    case actionTypes.INTEGRATION_RESET:
      return reset();

    default:
      return state;
  }
};

export default integrationReducer;
