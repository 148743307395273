import * as actionTypes from "./regions.actionTypes";

export const listRegionsOptionsStart = () => ({
  type: actionTypes.LIST_REGIONS_OPTIONS_START,
});

export const listRegionsOptionsSuccess = regions => ({
  type: actionTypes.LIST_REGIONS_OPTIONS_SUCCESS,
  regions,
});

export const listRegionsOptionsError = (error, message) => ({
  type: actionTypes.LIST_REGIONS_OPTIONS_ERROR,
  error,
  message,
});
