import { updateObject } from "utils/utility";
import * as actionTypes from "./regions.actionTypes";

const initialState = {
  listRegionsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    regionsOptions: [],
  },
};

const listRegionsOptionsStart = state =>
  updateObject(state, {
    listRegionsOptions: {
      ...state.listRegionsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listRegionsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listRegionsOptions: {
      ...state.listRegionsOptions,
      loading: false,
      resultType: "success",
      message: null,
      regionsOptions: action.regions.map(item => ({
        ...item,
        label: item.name,
      })),
      regionsOptionsDS: action.regions.map(item => {
        return {
          value: {
            ...item,
            microrregions: item?.microrregions.map(micro => ({
              value: micro,
              label: micro.name,
            })),
          },
          label: item.name,
        };
      }),
    },
  });

const listRegionsOptionsError = (state, action) =>
  updateObject(state, {
    listRegionsOptions: {
      ...state.listRegionsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      regionsOptions: [],
    },
  });

const regionsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.LIST_REGIONS_OPTIONS_START:
      return listRegionsOptionsStart(state);
    case actionTypes.LIST_REGIONS_OPTIONS_SUCCESS:
      return listRegionsOptionsSuccess(state, action);
    case actionTypes.LIST_REGIONS_OPTIONS_ERROR:
      return listRegionsOptionsError(state, action);

    default:
      return state;
  }
};

export default regionsReducer;
