export const RESET = "RESET";

export const LIST_PROFILES_OPTIONS_START = "LIST_PROFILES_OPTIONS_START";
export const LIST_PROFILES_OPTIONS_SUCCESS = "LIST_PROFILES_OPTIONS_SUCCESS";
export const LIST_PROFILES_OPTIONS_ERROR = "LIST_PROFILES_OPTIONS_ERROR";

export const LIST_USERS_BY_PROFILE_START = "LIST_USERS_BY_PROFILE_START";
export const LIST_USERS_BY_PROFILE_SUCCESS = "LIST_USERS_BY_PROFILE_SUCCESS";
export const LIST_USERS_BY_PROFILE_ERROR = "LIST_USERS_BY_PROFILE_ERROR";
export const LIST_USERS_BY_PROFILE_OPTIONS = "LIST_USERS_BY_PROFILE_OPTIONS";

export const LIST_USERS_BY_PROFILE_MODAL_START = "LIST_USERS_BY_PROFILE_MODAL_START";
export const LIST_USERS_BY_PROFILE_MODAL_SUCCESS = "LIST_USERS_BY_PROFILE_MODAL_SUCCESS";
export const LIST_USERS_BY_PROFILE_MODAL_ERROR = "LIST_USERS_BY_PROFILE_MODAL_ERROR";

export const SET_USERS_PROFILE_START = "SET_USERS_PROFILE_START";
export const SET_USERS_PROFILE_SUCCESS = "SET_USERS_PROFILE_SUCCESS";
export const SET_USERS_PROFILE_ERROR = "SET_USERS_PROFILE_ERROR";

export const SAVE_PROFILE_START = "SAVE_PROFILE_START";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_ERROR = "SAVE_PROFILE_ERROR";

export const EDIT_PROFILE_START = "EDIT_PROFILE_START";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";

export const LIST_PROFILE_DETAILS_START = "LIST_PROFILE_DETAILS_START";
export const LIST_PROFILE_DETAILS_SUCCESS = "LIST_PROFILE_DETAILS_SUCCESS";
export const LIST_PROFILE_DETAILS_ERROR = "LIST_PROFILE_DETAILS_ERROR";

export const RESET_PROFILE_DETAILS = "RESET_PROFILE_DETAILS";
