import API from "utils/api";
import { basicErrorHandler } from "utils/utility";
import * as actions from "./regions.actions";

export const listRegionsOptions = () => dispatch => {
  dispatch(actions.listRegionsOptionsStart());

  const url = "/v1/mesorregiao";

  API.get(url)
    .then(response => {
      const adaptedResponse = response.data.map(mesorregiao => {
        return {
          id: mesorregiao.id,
          name: mesorregiao.descricao,
          associated: true,
          state: { id: mesorregiao.estado.id, name: mesorregiao.estado.nome },
          microrregions: mesorregiao.microrregioes.map(microrregiao => ({
            id: microrregiao.id,
            name: microrregiao.descricao,
          })),
        };
      });
      dispatch(actions.listRegionsOptionsSuccess(adaptedResponse));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listRegionsOptionsError));
};
