import * as actionTypes from "./login.actionTypes";

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = token => ({
  type: actionTypes.LOGIN_SUCCESS,
  token,
});

export const loginError = (error, message) => ({
  type: actionTypes.LOGIN_ERROR,
  error,
  message,
});

export const loginModalShow = () => ({
  type: actionTypes.LOGIN_MODAL_SHOW,
});

export const updateAuthRefresh = () => ({
  type: actionTypes.UPDATE_AUTH_REFRESH,
});
