import ListingOptions from "utils/listingOptions";

const ListingDefaultState = (orderFieldDefault, filters, frontEndControl = false) => ({
  loading: false,
  error: null,
  options: new ListingOptions(orderFieldDefault, frontEndControl, filters),
  rows: [],
  updateTime: undefined,
});

export default ListingDefaultState;
