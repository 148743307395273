const theme = {
  color: {
    primary: "#336eae",
    secondary: "#d7e5f4",
    grey500: "#333333",
    gray400: "#666666",
    grey300: "#949494",
    blue300: "#249ddb",
    red400: "#af1d1e",
    red300: "#e25051",
    red200: "#f4b9b9",
  },
  size: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
  },
  sizeNoPixes: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
  },
};

export default theme;
