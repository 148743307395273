import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { setRouteTracking } from "utils/utility";
import Reducers from "./reducers";

// autoMergeLevel1 (default)
const persistConfig = {
  key: "root",
  storage,
};

export const history = createBrowserHistory();

const middlewares = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}
if (process.env.NODE_ENV !== "test") {
  setRouteTracking(history);
}

const persistedReducer = persistReducer(persistConfig, Reducers());
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("./reducers", () => {
    const nextReducer = require("./reducers").default;
    store.replaceReducer(nextReducer);
  });
}
