export const LIST_USERS_START = "LIST_USERS_START";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_ERROR = "LIST_USERS_ERROR";
export const LIST_USERS_OPTIONS = "LIST_USERS_OPTIONS";

export const LIST_USER_DETAILS_START = "LIST_USER_DETAILS_START";
export const LIST_USER_DETAILS_SUCCESS = "LIST_USER_DETAILS_SUCCESS";
export const LIST_USER_DETAILS_ERROR = "LIST_USER_DETAILS_ERROR";

export const LIST_USER_DETAILS_SIMPLIFIED_START = "LIST_USER_DETAILS_SIMPLIFIED_START";
export const LIST_USER_DETAILS_SIMPLIFIED_SUCCESS = "LIST_USER_DETAILS_SIMPLIFIED_SUCCESS";
export const LIST_USER_DETAILS_SIMPLIFIED_ERROR = "LIST_USER_DETAILS_SIMPLIFIED_ERROR";

export const SAVE_USER_START = "SAVE_USER_START";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_ERROR = "SAVE_USER_ERROR";

export const EDIT_USER_START = "EDIT_USER_START";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_USER_SUBSEGMENTS_START = "GET_USER_SUBSEGMENTS_START";
export const GET_USER_SUBSEGMENTS_SUCCESS = "GET_USER_SUBSEGMENTS_SUCCESS";
export const GET_USER_SUBSEGMENTS_FAIL = "GET_USER_SUBSEGMENTS_FAIL";

export const EXPORT_USERS_FILE_START = "EXPORT_USERS_FILE_FILE_START";
export const EXPORT_USERS_FILE_SUCCESS = "EXPORT_USERS_FILE_SUCCESS";
export const EXPORT_USERS_FILE_FAIL = "EXPORT_USERS_FILE_FAIL";
