/**
 * @param  {} object
 * @param  {} keys
 * @param  {} defaultVal
 */
export const get = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split(".");
  object = object?.[keys[0]];

  if (object && keys?.length > 1) {
    return get(object, keys.slice(1), undefined);
  }

  return object === undefined ? defaultVal : object;
};

/**
 * @param  {} object
 * @param  {} keys
 * @param  {} val
 */
export const set = (object, keys, val) => {
  keys = Array.isArray(keys) ? keys : keys.split(".");

  if (keys.length > 1) {
    object[keys[0]] = object[keys[0]] || {};
    return set(object[keys[0]], keys.slice(1), val);
  }

  object[keys[0]] = val;
};

/**
 * @param  {} obj
 * @param  {} path
 */
export const deepFind = (obj, path) => {
  const paths = path
    .replace("[", ".")
    .replace("]", "")
    .split(".");
  let current = obj;
  let i;

  for (i = 0; i < paths.length; ++i) {
    if (current === undefined) return undefined;
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
};

/**
 * @param  {} obj1
 * @param  {} obj2
 */
export const objDeepCompare = (obj1, obj2) => {
  // Loop through properties in object 1
  for (const p in obj1) {
    // Check property exists on both objects
    if (obj1?.hasOwnProperty(p) !== obj2?.hasOwnProperty(p)) {
      return false;
    }

    switch (typeof obj1[p]) {
      // Deep compare objects
      case "object":
        if (!objDeepCompare(obj1[p], obj2[p])) {
          return false;
        }
        break;
      // Compare function code
      case "function":
        if (
          typeof obj2[p] === "undefined" ||
          (p != "compare" && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      // Compare values
      default:
        if (obj1[p] != obj2[p]) {
          return false;
        }
    }
  }

  // Check object 2 for any extra properties
  // for (const p in obj2) {
  //   if (obj1 && typeof obj1[p] === "undefined") {
  //     return false;
  //   }
  // }
  return true;
};

/**
 * @param  {} obj
 * @param  {} func
 */
export const mapObj = (obj, func) => {
  Object.keys(obj).forEach(col => {
    obj[col] = func(obj[col], col);
  });
};

/**
 * @param  {Object} oldObject
 * @param  {Object} updatedProperties
 */
export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const defaultObservationObject = () => ({
  reason: "",
  observation: "",
});

/**
 * @param  {} obj
 */
export const isEmptyObj = obj => {
  return Object.keys(obj).length === 0;
};

/**
 * @param {} objA
 * @param {} objB
 */
export const isEqualObjs = (objA, objB) => {
  return JSON.stringify(objA) === JSON.stringify(objB);
};
