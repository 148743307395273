import { updateObject } from "utils/utility";
import * as actionTypes from "./panorama.actionTypes";

const INITIAL_STATE = {
  listPanoramas: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    panoramasOptions: {},
  },
  newPanoramas: {
    year: null,
    list: [],
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    config: {},
  },
  activatePanorama: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  listPanoramsYears: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    years: [],
  },
};

const reset = () => INITIAL_STATE;

/* list of panoramas */

const listPanoramasOptionsStart = state =>
  updateObject(state, {
    listPanoramas: {
      ...state.listPanoramas,
      loading: true,
      error: null,
      message: null,
    },
  });

const listPanoramasOptionsSuccess = (state, action) =>
  updateObject(state, {
    listPanoramas: {
      ...state.listPanoramas,
      loading: false,
      resultType: "success",
      message: null,
      panoramasOptions: {
        ...state.listPanoramas.panoramasOptions,
        [action.index]: { [action.ano]: [...action.panoramas] },
      },
    },
  });

const listPanoramasOptionsFail = (state, action) =>
  updateObject(state, {
    listPanoramas: {
      ...state.listPanoramas,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      panoramasOptions: {},
    },
  });

const setSelectedYear = (state, action) => {
  return updateObject(state, {
    newPanoramas: {
      ...state.newPanoramas,
      year: action.year,
    },
  });
};

const listAllPanoramasStart = state =>
  updateObject(state, {
    newPanoramas: {
      ...state.newPanoramas,
      loading: true,
      error: null,
      message: null,
    },
  });

const listAllPanoramasSuccess = (state, action) =>
  updateObject(state, {
    newPanoramas: {
      ...state.newPanoramas,
      loading: false,
      resultType: "success",
      message: null,
      list: action.list,
      config: action.config,
    },
  });

const listAllPanoramasFail = (state, action) =>
  updateObject(state, {
    newPanoramas: {
      ...state.newPanoramas,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      list: [],
    },
  });

const activatePanoramaStart = state =>
  updateObject(state, {
    activatePanorama: {
      ...state.activatePanorama,
      loading: true,
      error: null,
      message: null,
    },
  });

const activatePanoramaSuccess = state =>
  updateObject(state, {
    activatePanorama: {
      ...state.activatePanorama,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const activatePanoramaFail = (state, action) =>
  updateObject(state, {
    activatePanorama: {
      ...state.activatePanorama,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const listYearsWithPanoramsStart = state =>
  updateObject(state, {
    listPanoramsYears: {
      ...state.listPanoramsYears,
      loading: true,
    },
  });

const listYearsWithPanoramsSuccess = (state, action) =>
  updateObject(state, {
    listPanoramsYears: {
      ...state.listPanoramsYears,
      loading: false,
      resultType: "success",
      message: null,
      years: action.years,
    },
  });

const listYearsWithPanoramsFail = (state, action) =>
  updateObject(state, {
    listPanoramsYears: {
      ...state.listPanoramsYears,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      years: [],
    },
  });

const panoramaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();
    case actionTypes.SELECTED_YEAR:
      return setSelectedYear(state, action);

    case actionTypes.LIST_PANORAMAS_OPTIONS_START:
      return listPanoramasOptionsStart(state);
    case actionTypes.LIST_PANORAMAS_OPTIONS_SUCCESS:
      return listPanoramasOptionsSuccess(state, action);
    case actionTypes.LIST_PANORAMAS_OPTIONS_FAIL:
      return listPanoramasOptionsFail(state, action);

    case actionTypes.LIST_ALL_PANORAMAS_START:
      return listAllPanoramasStart(state);
    case actionTypes.LIST_ALL_PANORAMAS_SUCCESS:
      return listAllPanoramasSuccess(state, action);
    case actionTypes.LIST_ALL_PANORAMAS_FAIL:
      return listAllPanoramasFail(state, action);

    case actionTypes.ACTIVATE_PANORAMA_START:
      return activatePanoramaStart(state);
    case actionTypes.ACTIVATE_PANORAMA_SUCCESS:
      return activatePanoramaSuccess(state);
    case actionTypes.ACTIVATE_PANORAMA_FAIL:
      return activatePanoramaFail(state, action);

    case actionTypes.LIST_YEARS_WITH_PANORAMS_START:
      return listYearsWithPanoramsStart(state);
    case actionTypes.LIST_YEARS_WITH_PANORAMS_SUCCESS:
      return listYearsWithPanoramsSuccess(state, action);
    case actionTypes.LIST_YEARS_WITH_PANORAMS_FAIL:
      return listYearsWithPanoramsFail(state, action);

    default:
      return state;
  }
};

export default panoramaReducer;
