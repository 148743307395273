export const RESET = "RESET";

export const GET_REPORT_DATA_START = "GET_REPORT_DATA_START";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_ERROR = "GET_REPORT_DATA_ERROR";

export const GET_COMPARATIVE_START = "GET_COMPARATIVE_START";
export const GET_COMPARATIVE_SUCCESS = "GET_COMPARATIVE_SUCCESS";
export const GET_COMPARATIVE_ERROR = "GET_COMPARATIVE_ERROR";
export const SET_SELECTED_SCENARIO = "SET_SELECTED_SCENARIO";
export const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const SET_DUPLICATED_SCENARIO = "SET_DUPLICATED_SCENARIO";
export const RESET_SELECTED_SCENARIO = "RESET_SELECTED_SCENARIO";
export const RESET_ALL_SCENARIOS = "RESET_ALL_SCENARIOS";

export const GET_YEARS_START = "GET_YEARS_START";
export const GET_YEARS_SUCCESS = "GET_YEARS_SUCCESS";
export const GET_YEARS_ERROR = "GET_YEARS_ERROR";

export const SET_SCALE = "SET_SCALE";

export const IS_DUPLICATING_SCENARIO = "IS_DUPLICATING_SCENARIO";
