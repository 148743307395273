export const RESET = "RESET";
export const RESET_SALES_LIST = "RESET_SALES_LIST";
export const RESET_SALE = "RESET_SALE";
export const RESET_HISTORY = "RESET_HISTORY";

export const SAVE_SALE_START = "SAVE_SALE_START";
export const SAVE_SALE_SUCCESS = "SAVE_SALE_SUCCESS";
export const SAVE_SALE_ERROR = "SAVE_SALE_ERROR";

export const SAVE_SALES_START = "SAVE_SALES_START";
export const SAVE_SALES_SUCCESS = "SAVE_SALES_SUCCESS";
export const SAVE_SALES_ERROR = "SAVE_SALES_ERROR";

export const FINISH_SALE_CREATING = "FINISH_SALE_CREATING";

export const LIST_SALES_START = "LIST_SALES_START";
export const LIST_SALES_SUCCESS = "LIST_SALES_SUCCESS";
export const LIST_SALES_ERROR = "LIST_SALES_ERROR";
export const LIST_SALES = "LIST_SALES";

export const LIST_SALES_CHECK_DUPLICATED_START = "LIST_SALES_CHECK_DUPLICATED_START";
export const LIST_SALES_CHECK_DUPLICATED_SUCCESS = "LIST_SALES_CHECK_DUPLICATED_SUCCESS";
export const LIST_SALES_CHECK_DUPLICATED_ERROR = "LIST_SALES_CHECK_DUPLICATED_ERROR";

export const EXPORT_SALES_START = "EXPORT_SALES_START";
export const EXPORT_SALES_SUCCESS = "EXPORT_SALES_SUCCESS";
export const EXPORT_SALES_ERROR = "EXPORT_SALES_ERROR";
export const EXPORT_RESET_FILTER = "EXPORT_RESET_FILTER";

export const GET_SALE_START = "GET_SALE_START";
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS";
export const GET_SALE_ERROR = "GET_SALE_ERROR";

export const GET_SALE_HISTORY_START = "GET_SALE_HISTORY_START";
export const GET_SALE_HISTORY_SUCCESS = "GET_SALE_HISTORY_SUCCESS";
export const GET_SALE_HISTORY_ERROR = "GET_SALE_HISTORY_ERROR";

export const EDIT_SALE_START = "EDIT_SALE_START";
export const EDIT_SALE_SUCCESS = "EDIT_SALE_SUCCESS";
export const EDIT_SALE_ERROR = "EDIT_SALE_ERROR";

export const DELETE_SALE_START = "DELETE_SALE_START";
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS";
export const DELETE_SALE_ERROR = "DELETE_SALE_ERROR";

export const SALE_SAVE_FILTER = "SALE_SAVE_FILTER";
export const SALE_INCREMENT_FILTER = "SALE_INCREMENT_FILTER";
export const SALE_RESET_FILTER = "SALE_RESET_FILTER";
export const IS_RESETING_FILTER = "IS_RESETING_FILTER";

export const SALE_SET_TAB = "SALE_SET_TAB";

export const EXPORT_TRANSACTIONS_HISTORY_START = "EXPORT_TRANSACTIONS_HISTORY_START";
export const EXPORT_TRANSACTIONS_HISTORY_SUCCESS = "EXPORT_TRANSACTIONS_HISTORY_SUCCESS";
export const EXPORT_TRANSACTIONS_HISTORY_ERROR = "EXPORT_TRANSACTIONS_HISTORY_ERROR";

export const ALTER_SALE_STATUS_START = "ALTER_SALE_STATUS_START";
export const ALTER_SALE_STATUS_SUCCESS = "ALTER_SALE_STATUS_SUCCESS";
export const ALTER_SALE_STATUS_ERROR = "ALTER_SALE_STATUS_ERROR";

export const SET_SELECTED_SEGMENTN1 = "SET_SELECTED_SEGMENTN1";

export const HAS_SALE_FIELD_CHANGED = "HAS_SALE_FIELD_CHANGED";

export const GET_RECOMMENDED_TAX_PRICE_START = "GET_RECOMMENDED_TAX_PRICE_START";
export const GET_RECOMMENDED_TAX_PRICE_SUCCESS = "GET_RECOMMENDED_TAX_PRICE_SUCCESS";
export const GET_RECOMMENDED_TAX_PRICE_ERROR = "GET_RECOMMENDED_TAX_PRICE_ERROR";
export const RESET_RECOMMENDED_TAX_PRICE = "RESET_RECOMMENDED_TAX_PRICE";

export const GET_SALE_MARGIN_START = "GET_SALE_MARGIN_START";
export const GET_SALE_MARGIN_SUCCESS = "GET_SALE_MARGIN_SUCCESS";
export const GET_SALE_MARGIN_ERROR = "GET_SALE_MARGIN_ERROR";

export const DELETE_RECOMMENDED_TAX_PRICE = "DELETE_RECOMMENDED_TAX_PRICE";

export const SHOULD_TRANSACTION_UPDATE = "SHOULD_TRANSACTION_UPDATE";

export const UPDATE_RECOMMENDED_PRICES = "UPDATE_RECOMMENDED_PRICES";

export const SET_PREPEX_VALUE = "SET_PREPEX_VALUE";

export const CHECK_IF_SALE_IS_ON_MARGIN_START = "CHECK_IF_SALE_IS_ON_MARGIN_START";
export const CHECK_IF_SALE_IS_ON_MARGIN_SUCCESS = "CHECK_IF_SALE_IS_ON_MARGIN_SUCCESS";
export const CHECK_IF_SALE_IS_ON_MARGIN_ERROR = "CHECK_IF_SALE_IS_ON_MARGIN_ERROR";

export const UPDATE_TRANSACTIONS_SITUATION_START = "UPDATE_TRANSACTIONS_SITUATION_START";
export const UPDATE_TRANSACTIONS_SITUATION_SUCCESS = "UPDATE_TRANSACTIONS_SITUATION_SUCCESS";
export const UPDATE_TRANSACTIONS_SITUATION_ERROR = "UPDATE_TRANSACTIONS_SITUATION_ERROR";

export const GET_FLOW_CODE_START = "GET_FLOW_CODE_START";
export const GET_FLOW_CODE_SUCCESS = "GET_FLOW_CODE_SUCCESS";
export const GET_FLOW_CODE_ERROR = "GET_FLOW_CODE_ERROR";
export const RESET_FLOW_CODE = "RESET_FLOW_CODE";

export const LIST_SALES_STATUS_START = "LIST_SALES_STATUS_START";
export const LIST_SALES_STATUS_SUCCESS = "LIST_SALES_STATUS_SUCCESS";
export const LIST_SALES_STATUS_ERROR = "LIST_SALES_STATUS_ERROR";

export const SEND_SALES_FILE_START = "SEND_SALES_FILE_START";
export const SEND_SALES_FILE_SUCCESS = "SEND_SALES_FILE_SUCCESS";
export const SEND_SALES_FILE_ERROR = "SEND_SALES_FILE_ERROR";

export const DOWNLOAD_SALES_FILE_START = "DOWNLOAD_SALES_FILE_START";
export const DOWNLOAD_SALES_FILE_SUCCESS = "DOWNLOAD_SALES_FILE_SUCCESS";
export const DOWNLOAD_SALES_FILE_ERROR = "DOWNLOAD_SALES_FILE_ERROR";

export const GET_LIST_MARGINS_START = "GET_LIST_MARGINS_START";
export const GET_LIST_MARGINS_SUCCESS = "GET_LIST_MARGINS_SUCCESS";
export const GET_LIST_MARGINS_ERROR = "GET_LIST_MARGINS_ERROR";

export const SET_TYPE_OF_SALE = "SET_TYPE_OF_SALE";
export const SET_SALE_NEW_EXPERIENCE_PERIOD = "SET_SALE_NEW_EXPERIENCE_PERIOD";

export const IS_NEW_RECOMMENDATION = "IS_NEW_RECOMMENDATION";
export const RESET_IS_NEW_RECOMMENDATION = "RESET_IS_NEW_RECOMMENDATION";
export const SET_SALE_MODAL_TYPE = "SET_SALE_MODAL_TYPE";

export const GET_NEGOTIATION_SALES_DEADLINES_LIST_START =
  "GET_NEGOTIATION_SALES_DEADLINES_LIST_START";
export const GET_NEGOTIATION_SALES_DEADLINES_LIST_SUCCESS =
  "GET_NEGOTIATION_SALES_DEADLINES_LIST_SUCCESS";
export const GET_NEGOTIATION_SALES_DEADLINES_LIST_ERROR =
  "GET_NEGOTIATION_SALES_DEADLINES_LIST_ERROR";

export const GET_SALES_STATUS_START = "GET_SALES_STATUS_START";
export const GET_SALES_STATUS_SUCCESS = "GET_SALES_STATUS_SUCCESS";
export const GET_SALES_STATUS_ERROR = "GET_SALES_STATUS_ERROR";

export const SEPARATE_SALE_START = "SEPARATE_SALE_START";
export const SEPARATE_SALE_SUCCESS = "SEPARATE_SALE_SUCCESS";
export const SEPARATE_SALE_ERROR = "SEPARATE_SALE_ERROR";

export const GET_SALES_IDS_START = "GET_SALES_IDS_START";
export const GET_SALES_IDS_SUCCESS = "GET_SALES_IDS_SUCCESS";
export const GET_SALES_IDS_ERROR = "GET_SALES_IDS_ERROR";
