import { updateObject } from "utils/utility";
import * as actionTypes from "./scenes.actionTypes";

const initialState = {
  listScenesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    scenesOptions: [],
    scenesOptionsDS: [],
  },
};

const reset = () => initialState;

/* list roles options for comboboxes and autocompletes */

const listScenesOptionsStart = state =>
  updateObject(state, {
    listScenesOptions: {
      ...state.listScenesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listScenesOptionsSuccess = (state, action) =>
  updateObject(state, {
    listScenesOptions: {
      ...state.listScenesOptions,
      loading: false,
      resultType: "success",
      message: null,
      scenesOptions: action.scenes.map(item => ({
        id: item.id,
        label: item.name,
      })),
      scenesOptionsDS: action.scenesDS,
    },
  });

const listScenesOptionsError = (state, action) =>
  updateObject(state, {
    listScenesOptions: {
      ...state.listScenesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      scenesOptions: [],
      scenesOptionsDS: [],
    },
  });

const setSelectedScenes = (state, action) =>
  updateObject(state, {
    selectedScenes: action.scenes,
  });

const resetSelectedScenes = state =>
  updateObject(state, {
    selectedScenes: null,
  });
const scenesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_SCENES_OPTIONS_START:
      return listScenesOptionsStart(state);
    case actionTypes.LIST_SCENES_OPTIONS_SUCCESS:
      return listScenesOptionsSuccess(state, action);
    case actionTypes.LIST_SCENES_OPTIONS_ERROR:
      return listScenesOptionsError(state, action);
    case actionTypes.SET_SELECTED_SCENES:
      return setSelectedScenes(state, action);
    case actionTypes.RESET_SELECTED_SCENES:
      return resetSelectedScenes(state);

    default:
      return state;
  }
};

export default scenesReducer;
