import { updateObject } from "utils/utility";
import * as actionTypes from "./segmentTree.actionTypes";

const initialState = {
  listSegmentTreeOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    segmentsTreeOptions: [],
  },
  listSegmentOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    segmentsOptions: [],
  },
  createSegmentTree: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  editSegmentTree: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  values: null,
};

const reset = () => initialState;

/* list segments N1 options for comboboxes and autocompletes */

const listSegmentTreeOptionsStart = state =>
  updateObject(state, {
    listSegmentTreeOptions: {
      ...state.listSegmentTreeOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listSegmentTreeOptionsSuccess = (state, action) =>
  updateObject(state, {
    listSegmentTreeOptions: {
      ...state.listSegmentTreeOptions,
      loading: false,
      resultType: "success",
      message: null,
      segmentsTreeOptions: action.list,
    },
  });

const listSegmentTreeOptionsError = (state, action) =>
  updateObject(state, {
    listSegmentTreeOptions: {
      ...state.listSegmentTreeOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      segmentsTreeOptions: [],
    },
  });

/* list segments options for comboboxes and autocompletes */

const listSegmentOptionsStart = state =>
  updateObject(state, {
    listSegmentOptions: {
      ...state.listSegmentOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listSegmentOptionsSuccess = (state, action) =>
  updateObject(state, {
    listSegmentOptions: {
      ...state.listSegmentOptions,
      loading: false,
      resultType: "success",
      message: null,
      segmentsOptions: action.list,
    },
  });

const listSegmentOptionsError = (state, action) =>
  updateObject(state, {
    listSegmentOptions: {
      ...state.listSegmentOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      segmentsOptions: [],
    },
  });

const createSegmentTreeStart = state =>
  updateObject(state, {
    createSegmentTree: {
      ...state.createSegmentTree,
      loading: true,
      error: null,
      message: null,
    },
  });

const createSegmentTreeSuccess = state =>
  updateObject(state, {
    createSegmentTree: {
      ...state.createSegmentTree,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const createSegmentTreeError = (state, action) =>
  updateObject(state, {
    createSegmentTree: {
      ...state.createSegmentTree,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const editSegmentTreeStart = state =>
  updateObject(state, {
    editSegmentTree: {
      ...state.editSegmentTree,
      loading: true,
      error: null,
      message: null,
    },
  });

const editSegmentTreeSuccess = state =>
  updateObject(state, {
    editSegmentTree: {
      ...state.editSegmentTree,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const editSegmentTreeError = (state, action) =>
  updateObject(state, {
    editSegmentTree: {
      ...state.editSegmentTree,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const setValues = (state, action) => {
  return updateObject(state, {
    values: action.values,
  });
};

const segmentTreeReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_SEGMENTS_TREE_OPTIONS_START:
      return listSegmentTreeOptionsStart(state);
    case actionTypes.LIST_SEGMENTS_TREE_OPTIONS_SUCCESS:
      return listSegmentTreeOptionsSuccess(state, action);
    case actionTypes.LIST_SEGMENTS_TREE_OPTIONS_ERROR:
      return listSegmentTreeOptionsError(state, action);

    case actionTypes.LIST_SEGMENTS_OPTIONS_START:
      return listSegmentOptionsStart(state);
    case actionTypes.LIST_SEGMENTS_OPTIONS_SUCCESS:
      return listSegmentOptionsSuccess(state, action);
    case actionTypes.LIST_SEGMENTS_OPTIONS_ERROR:
      return listSegmentOptionsError(state, action);

    case actionTypes.CREATE_SEGMENT_TREE_START:
      return createSegmentTreeStart(state);
    case actionTypes.CREATE_SEGMENT_TREE_SUCCESS:
      return createSegmentTreeSuccess(state);
    case actionTypes.CREATE_SEGMENT_TREE_ERROR:
      return createSegmentTreeError(state, action);

    case actionTypes.EDIT_SEGMENT_TREE_START:
      return editSegmentTreeStart(state);
    case actionTypes.EDIT_SEGMENT_TREE_SUCCESS:
      return editSegmentTreeSuccess(state);
    case actionTypes.EDIT_SEGMENT_TREE_ERROR:
      return editSegmentTreeError(state, action);

    case actionTypes.SET_VALUES:
      return setValues(state, action);

    default:
      return state;
  }
};

export default segmentTreeReducer;
