export const RESET = "RESET";

export const UPLOAD_START = "UPLOAD_START";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";

export const UPLOAD_STATUS_START = "UPLOAD_STATUS_START";
export const UPLOAD_STATUS_SUCCESS = "UPLOAD_STATUS_SUCCESS";
export const UPLOAD_STATUS_ERROR = "UPLOAD_STATUS_ERROR";

export const DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_START = "DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_START";
export const DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_SUCCESS = "DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_SUCCESS";
export const DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_ERROR = "DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_ERROR";

export const DOWNLOAD_DEFAULT_PRICING_FILE_START = "DOWNLOAD_DEFAULT_PRICING_FILE_START";
export const DOWNLOAD_DEFAULT_PRICING_FILE_SUCCESS = "DOWNLOAD_DEFAULT_PRICING_FILE_SUCCESS";
export const DOWNLOAD_DEFAULT_PRICING_FILE_ERROR = "DOWNLOAD_DEFAULT_PRICING_FILE_ERROR";

export const SELECTED_SCENARIO = "SELECTED_SCENARIO";

export const DOWNLOAD_SHEET_START = "DOWNLOAD_SHEET_START";
export const DOWNLOAD_SHEET_SUCCESS = "DOWNLOAD_SHEET_SUCCESS";
export const DOWNLOAD_SHEET_ERROR = "DOWNLOAD_SHEET_ERROR";
