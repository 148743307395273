import { updateObject } from "utils/utility";
import * as actionTypes from "./powerbi.actionTypes";

const initialState = {
  powerBiData: {
    loading: false,
    error: null,
    resultType: "sucess",
    message: null,
    report: {},
  },
};

const reset = () => initialState;

const getPowerBiReportsDataStart = state =>
  updateObject(state, {
    powerBiData: {
      ...state.powerBiData,
      loading: true,
      error: null,
      message: null,
    },
  });

const getPowerBiReportsDataSuccess = (state, action) =>
  updateObject(state, {
    powerBiData: {
      ...state.powerBiData,
      loading: false,
      resultType: "success",
      message: null,
      report: action.powerBiData,
    },
  });

const getPowerBiReportsDataError = (state, action) =>
  updateObject(state, {
    powerBiData: {
      ...state.powerBiData,
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const powerBiReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.GET_POWERBI_REPORTS_DATA_START:
      return getPowerBiReportsDataStart(state);
    case actionTypes.GET_POWERBI_REPORTS_DATA_SUCCESS:
      return getPowerBiReportsDataSuccess(state, action);
    case actionTypes.GET_POWERBI_REPORTS_DATA_ERROR:
      return getPowerBiReportsDataError(state, action);

    default:
      return state;
  }
};

export default powerBiReducer;
