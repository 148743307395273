export const RESET = "RESET";

export const LIST_STATES_OPTIONS_START = "LIST_STATES_OPTIONS_START";
export const LIST_STATES_OPTIONS_SUCCESS = "LIST_STATES_OPTIONS_SUCCESS";
export const LIST_STATES_OPTIONS_ERROR = "LIST_STATES_OPTIONS_ERROR";

export const LIST_STATES_OPTIONS_APPCLIENTE_START = "LIST_STATES_OPTIONS_APPCLIENTE_START";
export const LIST_STATES_OPTIONS_APPCLIENTE_SUCCESS = "LIST_STATES_OPTIONS_APPCLIENTE_SUCCESS";
export const LIST_STATES_OPTIONS_APPCLIENTE_ERROR = "LIST_STATES_OPTIONS_APPCLIENTE_ERROR";

export const LIST_STATES_INITIALS_OPTIONS_START = "LIST_STATES_INITIALS_OPTIONS_START";
export const LIST_STATES_INITIALS_OPTIONS_SUCCESS = "LIST_STATES_INITIALS_OPTIONS_SUCCESS";
export const LIST_STATES_INITIALS_OPTIONS_ERROR = "LIST_STATES_INITIALS_OPTIONS_ERROR";

export const LIST_STATES_ASSOCIATED_START = "LIST_STATES_ASSOCIATED_START";
export const LIST_STATES_ASSOCIATED_SUCCESS = "LIST_STATES_ASSOCIATED_SUCCESS";
export const LIST_STATES_ASSOCIATED_ERROR = "LIST_STATES_ASSOCIATED_ERROR";
