import { updateObject } from "utils/utility";
import * as actionTypes from "./saleTypes.actionTypes";

const INITIAL_STATE = {
  listSaleTypesOptions: {
    data: [],
    error: null,
    message: null,
    loading: false,
  },
};

const getSaleTypesStart = state =>
  updateObject(state, {
    listSaleTypesOptions: {
      ...state.listSaleTypesOptions,
      error: null,
      message: null,
      loading: true,
      data: [],
    },
  });

const getSaleTypesSuccess = (state, action) => {
  return updateObject(state, {
    listSaleTypesOptions: {
      ...state.listSaleTypesOptions,
      error: null,
      message: null,
      loading: false,
      data: action.data,
    },
  });
};

const getSaleTypesError = (state, action) =>
  updateObject(state, {
    listSaleTypesOptions: {
      ...state.listSaleTypesOptions,
      error: action.error,
      message: action.message,
      loading: false,
    },
  });

const saleTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SALE_TYPES_START:
      return getSaleTypesStart(state);
    case actionTypes.GET_SALE_TYPES_SUCCESS:
      return getSaleTypesSuccess(state, action);
    case actionTypes.GET_SALE_TYPES_ERROR:
      return getSaleTypesError(state, action);

    default:
      return state;
  }
};

export default saleTypesReducer;
