import * as actionTypes from "./notifications.actionTypes";

export const openNotificationMenu = isOpen => ({
  type: actionTypes.OPEN_NOTIFICATION_MENU,
  isOpen,
});

export const getSystemNotificationsStart = () => ({
  type: actionTypes.GET_SYSTEM_NOTIFICATION_START,
});

export const getSystemNotificationsSuccess = systemId => ({
  type: actionTypes.GET_SYSTEM_NOTIFICATION_SUCCESS,
  systemId,
});

export const getSystemNotificationsError = () => ({
  type: actionTypes.GET_SYSTEM_NOTIFICATION_ERROR,
});

export const getNotificationsStart = () => ({
  type: actionTypes.GET_NOTIFICATION_LIST_START,
});

export const getNotificationsSuccess = ({ notifications, config }) => ({
  type: actionTypes.GET_NOTIFICATION_LIST_SUCCESS,
  notifications,
  config,
});

export const getNotificationsError = () => ({
  type: actionTypes.GET_NOTIFICATION_LIST_ERROR,
});

export const getLastNotificationsStart = () => ({
  type: actionTypes.GET_LAST_NOTIFICATION_LIST_START,
});

export const getLastNotificationsSuccess = payload => ({
  type: actionTypes.GET_LAST_NOTIFICATION_LIST_SUCCESS,
  notifications: payload,
});

export const getLastNotificationsError = () => ({
  type: actionTypes.GET_LAST_NOTIFICATION_LIST_ERROR,
});

export const getUnreadNotificationsStart = () => ({
  type: actionTypes.GET_UNREAD_NOTIFICATION_START,
});

export const getUnreadNotificationsSuccess = payload => ({
  type: actionTypes.GET_UNREAD_NOTIFICATION_SUCCESS,
  notifications: payload,
});

export const getUnreadNotificationsError = () => ({
  type: actionTypes.GET_UNREAD_NOTIFICATION_ERROR,
});

export const updateReadNotificationStart = () => ({
  type: actionTypes.UPDATE_READ_NOTIFICATION_START,
});

export const updateReadNotificationSuccess = () => ({
  type: actionTypes.UPDATE_READ_NOTIFICATION_SUCCESS,
});

export const updateReadNotificationError = () => ({
  type: actionTypes.UPDATE_READ_NOTIFICATION_ERROR,
});

export const downloadNotificationStart = () => ({
  type: actionTypes.DOWNLOAD_NOTIFICATION_START,
});

export const downloadNotificationSuccess = () => ({
  type: actionTypes.DOWNLOAD_NOTIFICATION_SUCCESS,
});

export const downloadNotificationError = () => ({
  type: actionTypes.DOWNLOAD_NOTIFICATION_ERROR,
});
