/**
 * should work on a js sort function to sort dates on format mm/yyyy
 *
 * @param  {string} a first year in format mm/yyyy
 * @param  {string} b second year in format mm/yyyy
 */
export const monthSorting = (a, b) => {
  const detailsA = a.split("/");
  const detailsB = b.split("/");
  if (detailsA[1] > detailsB[1]) {
    return 2;
  }
  if (detailsA[1] < detailsB[1]) {
    return -2;
  }
  if (detailsA[0] > detailsB[0]) {
    return 1;
  }
  if (detailsA[0] < detailsB[0]) {
    return -1;
  }
  return 0;
};

export const appClientAssociatedSort = (a, b) => {
  if (a.associated && !b.associated) {
    return -1;
  } else if (!a.associated && b.associated) {
    return 1;
  }
  return 0;
};
