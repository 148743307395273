export const LIST_CLIENTS_OPTIONS_START = "LIST_CLIENTS_OPTIONS_START";
export const LIST_CLIENTS_OPTIONS_SUCCESS = "LIST_CLIENTS_OPTIONS_SUCCESS";
export const LIST_CLIENTS_OPTIONS_ERROR = "LIST_CLIENTS_OPTIONS_ERROR";

export const LIST_CLIENTS_FOR_PROPOSALS_START = "LIST_CLIENTS_FOR_PROPOSALS_START";
export const LIST_CLIENTS_FOR_PROPOSALS_SUCCESS = "LIST_CLIENTS_FOR_PROPOSALS_SUCCESS";
export const LIST_CLIENTS_FOR_PROPOSALS_ERROR = "LIST_CLIENTS_FOR_PROPOSALS_ERROR";

export const LIST_MONTHS_YEAR_START = "LIST_MONTHS_YEAR_START";
export const LIST_MONTHS_YEAR_SUCCESS = "LIST_MONTHS_YEAR_SUCCESS";
export const LIST_MONTHS_YEAR_FAIL = "LIST_MONTHS_YEAR_FAIL";

export const LIST_ROUTES_START = "LIST_ROUTES_START";
export const LIST_ROUTES_SUCCESS = "LIST_ROUTES_SUCCESS";
export const LIST_ROUTES_FAIL = "LIST_ROUTES_FAIL";

export const SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_START =
  "SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_START";
export const SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_SUCCESS =
  "SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_SUCCESS";
export const SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_ERROR =
  "SAVE_SEGMENT_TREE_CLIENT_RELATIONSHIP_ERROR";
