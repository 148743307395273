import * as actionTypes from "./observations.actionTypes";
import { updateObject } from "lib/object";

const initialState = {
  observationsList: {
    loading: false,
    observationOptions: [],
    error: null,
    message: null,
  },
  observationsTransactionsList: {
    loading: false,
    observationTransactionsOptions: [],
    error: null,
    message: null,
  },
  observationsAsRead: {
    loading: false,
    error: null,
    message: null,
  },
  observationsTransactionsAsRead: {
    loading: false,
    error: null,
    message: null,
  },
};

export const getObservationsStart = state =>
  updateObject(state, {
    observationsList: {
      loading: true,
      observationOptions: [],
      error: null,
      message: null,
    },
  });

export const getObservationsSuccess = (state, action) =>
  updateObject(state, {
    observationsList: {
      loading: false,
      observationOptions: action.payload,
      error: null,
      message: null,
    },
  });

export const getObservationsError = (state, action) =>
  updateObject(state, {
    observationsList: {
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const getObservationsTransactionsStart = state =>
  updateObject(state, {
    observationsTransactionsList: {
      loading: true,
      observationTransactionsOptions: [],
      error: null,
      message: null,
    },
  });

export const getObservationsTransactionsSuccess = (state, action) =>
  updateObject(state, {
    observationsTransactionsList: {
      loading: false,
      observationTransactionsOptions: action.payload,
      error: null,
      message: null,
    },
  });

export const getObservationsTransactionsError = (state, action) =>
  updateObject(state, {
    observationsTransactionsList: {
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const setObservationsAsReadStart = state =>
  updateObject(state, {
    observationsAsRead: {
      loading: true,
      error: null,
      message: null,
    },
  });

export const setObservationsAsReadSuccess = state =>
  updateObject(state, {
    observationsAsRead: {
      loading: false,
      error: null,
      message: null,
    },
  });

export const setObservationsAsReadError = (state, action) =>
  updateObject(state, {
    observationsAsRead: {
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

export const setObservationsTransactionsAsReadStart = state =>
  updateObject(state, {
    observationsTransactionsAsRead: {
      loading: true,
      error: null,
      message: null,
    },
  });

export const setObservationsTransactionsAsReadSuccess = state =>
  updateObject(state, {
    observationsTransactionsAsRead: {
      loading: false,
      error: null,
      message: null,
    },
  });

export const setObservationsTransactionsAsReadError = (state, action) =>
  updateObject(state, {
    observationsTransactionsAsRead: {
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const observationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OBSERVATIONS_TRANSACTIONS_START:
      return getObservationsTransactionsStart(state);
    case actionTypes.GET_OBSERVATIONS_TRANSACTIONS_SUCCESS:
      return getObservationsTransactionsSuccess(state, action);
    case actionTypes.GET_OBSERVATIONS_TRANSACTIONS_ERROR:
      return getObservationsTransactionsError(state, action);

    case actionTypes.SET_OBSERVATIONS_TRANSACTIONS_AS_READ_START:
      return setObservationsTransactionsAsReadStart(state);
    case actionTypes.SET_OBSERVATIONS_TRANSACTIONS_AS_READ_SUCCESS:
      return setObservationsTransactionsAsReadSuccess(state);
    case actionTypes.SET_OBSERVATIONS_TRANSACTIONS_AS_READ_ERROR:
      return setObservationsTransactionsAsReadError(state, action);

    case actionTypes.GET_OBSERVATIONS_START:
      return getObservationsStart(state);
    case actionTypes.GET_OBSERVATIONS_SUCCESS:
      return getObservationsSuccess(state, action);
    case actionTypes.GET_OBSERVATIONS_ERROR:
      return getObservationsError(state, action);

    case actionTypes.SET_OBSERVATIONS_AS_READ_START:
      return setObservationsAsReadStart(state);
    case actionTypes.SET_OBSERVATIONS_AS_READ_SUCCESS:
      return setObservationsAsReadSuccess(state);
    case actionTypes.SET_OBSERVATIONS_AS_READ_ERROR:
      return setObservationsAsReadError(state, action);

    default:
      return state;
  }
};

export default observationsReducer;
