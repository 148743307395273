import { updateObject } from "utils/utility";
import * as actionTypes from "./factories.actionTypes";

const initialState = {
  listFactoriesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    factoriesOptions: [],
  },
};

const reset = () => initialState;

const listFactoriesOptionsStart = state =>
  updateObject(state, {
    listFactoriesOptions: {
      ...state.listFactoriesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listFactoriesOptionsSuccess = (state, action) =>
  updateObject(state, {
    listFactoriesOptions: {
      ...state.listFactoriesOptions,
      loading: false,
      resultType: "success",
      message: null,
      factoriesOptions: action.factories.map(item => ({
        id: item.id,
        label: item.name,
      })),
      factoriesOptionsDS: action.factories.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });

const listFactoriesOptionsError = (state, action) =>
  updateObject(state, {
    listFactoriesOptions: {
      ...state.listFactoriesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      factoriesOptions: [],
    },
  });

const factoriesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_FACTORIES_OPTIONS_START:
      return listFactoriesOptionsStart(state);
    case actionTypes.LIST_FACTORIES_OPTIONS_SUCCESS:
      return listFactoriesOptionsSuccess(state, action);
    case actionTypes.LIST_FACTORIES_OPTIONS_ERROR:
      return listFactoriesOptionsError(state, action);

    default:
      return state;
  }
};

export default factoriesReducer;
