import * as actionTypes from "./messages.actionTypes";

export const reset = () => actionTypes.RESET;

export const addMessage = message => ({
  type: actionTypes.ADD_MESSAGE,
  message,
});

export const removeMessage = id => ({
  type: actionTypes.REMOVE_MESSAGE,
  id,
});

export const setToast = ({ active, status, message, title }) => ({
  type: actionTypes.SET_TOAST,
  active,
  status,
  message,
  title,
});
