export const DASHBOARD = "/";
export const LOGIN = "/login";

/**
 * urls de navegação de vendas
 */

export const PAGE_SALE_LIST = `${DASHBOARD}vendas`;
export const PAGE_SALE_FORM = `${DASHBOARD}vendas/formulario`;
export const PAGE_SALE_RESUME = id => `${PAGE_SALE_FORM}/${id}/resumo`;
export const PAGE_SALE_RESUME_LOTE = `${DASHBOARD}vendas/resumo-lote`;
export const PAGE_SALE_FORM_EDIT = id => `${PAGE_SALE_FORM}/${id}`;
export const PAGE_SALE_FORM_CHANGE_STATUS = id => `${PAGE_SALE_FORM}/${id}?cs=1&open=1`;
export const PAGE_REARRANGING_SALE = id => `${PAGE_SALE_FORM_EDIT(id)}/remanejamento`;
export const PAGE_REARRANGE_SELECT_TRANSACTIONS = id =>
  `${PAGE_SALE_FORM_EDIT(id)}/remanejar-venda`;

export const PAGE_FLOW_LIST = `${DASHBOARD}fluxos`;

/**
 * urls de navegação de relatórios
 */

export const PAGE_REPORTS = `${DASHBOARD}relatorios`;
export const PAGE_REPORTS_COMPARATIVE = `${DASHBOARD}relatorios/comparativo`;
export const PAGE_REPORTS_POWERBI = `${DASHBOARD}relatorios/powerbi`;

/**
 * urls de navegação de pricing
 */

export const PAGE_PRICING_LIST = `${DASHBOARD}pricing`;
export const PAGE_PRICING_UPLOAD = `${DASHBOARD}pricing/uploads`;
export const PAGE_PRICING_NO_RECOMMENDATION = `${DASHBOARD}pricing/sem-recomendacao`;
export const PAGE_PRICES_DEADLINES_SALES_LIST = `${DASHBOARD}pricing/prazos-precos`;
export const PAGE_PRICES_SERVICES_LIST = `${DASHBOARD}pricing/servicos`;

/**
 * urls de navegação de admin
 */

export const PAGE_USER_AND_PROFILE = `${DASHBOARD}usuarios-perfis`;
export const PAGE_USER_FORM = `${DASHBOARD}usuarios-perfis/formulario-usuario`;
export const PAGE_USER_DETAIL = id => `${PAGE_USER_FORM}/${id}/detalhes`;
export const PAGE_USER_FORM_ID = id => `${PAGE_USER_FORM}/${id}`;

export const PAGE_PROFILE_LIST = `${PAGE_USER_AND_PROFILE}?id=2`;
export const PAGE_PROFILE_FORM = `${PAGE_USER_AND_PROFILE}/formulario-perfil`;
export const PAGE_PROFILE_FORM_ID = id => `${PAGE_USER_AND_PROFILE}/formulario-perfil/${id}`;

export const PAGE_UPLOAD_FILES = `${DASHBOARD}uploads`;
export const PAGE_UPLOAD_SCENARIO_PANORAMS = `${DASHBOARD}sincronizacao-tacito`;

export const PAGE_SEGMENT_MANAGEMENT = `${DASHBOARD}gestao-segmentos`;

/**
 * urls de navegação de proposta solução logística
 */

export const PAGE_PROPOSAL_SOLUTION_LIST = `${DASHBOARD}proposta-solucao-logistica`;
export const PAGE_PROPOSAL_SOLUTION_FORM = `${DASHBOARD}proposta-solucao-logistica/formulario`;
export const PAGE_PROPOSAL_SOLUTION_EDIT = code => `${PAGE_PROPOSAL_SOLUTION_FORM}/${code}`;
export const PAGE_PROPOSAL_SOLUTION_RESUME = code =>
  `${PAGE_PROPOSAL_SOLUTION_FORM}/${code}/resumo`;
export const PAGE_PROPOSAL_SOLUTION_ASSOCIATE_SALES = code =>
  `${PAGE_PROPOSAL_SOLUTION_FORM}/${code}/vendas`;
export const PAGE_PROPOSAL_PREVIEW = code => `${PAGE_PROPOSAL_SOLUTION_FORM}/${code}/preview`;

/**
 * urls de navegação de notificações
 */

export const PAGE_NOTIFICATIONS = `${DASHBOARD}notificacoes`;

export const PAGE_NOT_ALLOWED = `${DASHBOARD}acesso-negado`;
