export const RESET = "RESET";

export const LIST_PRICING_STATUS_START = "LIST_PRICING_STATUS_START";
export const LIST_PRICING_STATUS_SUCCESS = "LIST_PRICING_STATUS_SUCCESS";
export const LIST_PRICING_STATUS_ERROR = "LIST_PRICING_STATUS_ERROR";

export const SEND_PRICING_FILE_START = "SEND_PRICING_FILE_START";
export const SEND_PRICING_FILE_SUCCESS = "SEND_PRICING_FILE_SUCCESS";
export const SEND_PRICING_FILE_ERROR = "SEND_PRICING_FILE_ERROR";

export const DOWNLOAD_PRICING_FILE_START = "DOWNLOAD_PRICING_FILE_START";
export const DOWNLOAD_PRICING_FILE_SUCCESS = "DOWNLOAD_PRICING_FILE_SUCCESS";
export const DOWNLOAD_PRICING_FILE_ERROR = "DOWNLOAD_PRICING_FILE_ERROR";

export const GET_PRICING_START = "GET_PRICING_START";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export const GET_PRICING_ERROR = "GET_PRICING_ERROR";

export const LIST_PRICING_WITH_FILTERS_START = "LIST_PRICING_WITH_FILTERS_START";
export const LIST_PRICING_WITH_FILTERS_SUCCESS = "LIST_PRICING_WITH_FILTERS_SUCCESS";
export const LIST_PRICING_WITH_FILTERS_ERROR = "LIST_PRICING_WITH_FILTERS_ERROR";

export const GET_RECOMMENDED_PRICING_START = "GET_RECOMMENDED_PRICING_START";
export const GET_RECOMMENDED_PRICING_SUCCESS = "GET_RECOMMENDED_PRICING_SUCCESS";
export const GET_RECOMMENDED_PRICING_ERROR = "GET_RECOMMENDED_PRICING_ERROR";

export const UPDATE_SALES_WITHOUT_RECOMMENDATION = "UPDATE_SALES_WITHOUT_RECOMMENDATION";
export const MODAL_NO_RECOMMENDATION_STATUS = "MODAL_NO_RECOMMENDATION_STATUS";
export const SAVE_MODAL = "SAVE_MODAL";

export const GET_SALES_WITHOUT_RECOMMENDATION_START = "GET_SALES_WITHOUT_RECOMMENDATION_START";
export const GET_SALES_WITHOUT_RECOMMENDATION_SUCCESS = "GET_SALES_WITHOUT_RECOMMENDATION_SUCCESS";
export const GET_SALES_WITHOUT_RECOMMENDATION_ERROR = "GET_SALES_WITHOUT_RECOMMENDATION_ERROR";

export const SAVE_NEW_RECOMMENDATION_START = "SAVE_NEW_RECOMMENDATION_START";
export const SAVE_NEW_RECOMMENDATION_SUCCESS = "SAVE_NEW_RECOMMENDATION_SUCCESS";
export const SAVE_NEW_RECOMMENDATION_ERROR = "SAVE_NEW_RECOMMENDATION_ERROR";

export const REQUEST_EXTENSION_START = "REQUEST_EXTENSION_START";
export const REQUEST_EXTENSION_SUCCESS = "REQUEST_EXTENSION_SUCCESS";
export const REQUEST_EXTENSION_ERROR = "REQUEST_EXTENSION_ERROR";

export const GET_EXTEND_REQUESTING_DATA_START = "GET_EXTEND_REQUESTING_DATA_START";
export const GET_EXTEND_REQUESTING_DATA_SUCCESS = "GET_EXTEND_REQUESTING_DATA_SUCCESS";
export const GET_EXTEND_REQUESTING_DATA_ERROR = "GET_EXTEND_REQUESTING_DATA_ERROR";

export const SAVE_EXTEND_APPROVAL_START = "SAVE_EXTEND_APPROVAL_START";
export const SAVE_EXTEND_APPROVAL_SUCCESS = "SAVE_EXTEND_APPROVAL_SUCCESS";
export const SAVE_EXTEND_APPROVAL_ERROR = "SAVE_EXTEND_APPROVAL_ERROR";

export const UPDATE_EXTEND_LIST = "UPDATE_EXTEND_LIST";

export const UPLOAD_DOCUMENTS_START = "UPLOAD_DOCUMENTS_START";
export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_DOCUMENTS_SUCCESS";
export const UPLOAD_DOCUMENTS_ERROR = "UPLOAD_DOCUMENTS_ERROR";

export const GET_PRICING_DOCUMENTS_LIST_START = "GET_PRICING_DOCUMENTS_LIST_START";
export const GET_PRICING_DOCUMENTS_LIST_SUCCESS = "GET_PRICING_DOCUMENTS_LIST_SUCCESS";
export const GET_PRICING_DOCUMENTS_LIST_ERROR = "GET_PRICING_DOCUMENTS_LIST_ERROR";

export const EDIT_DOCUMENTS_INFO_START = "EDIT_DOCUMENTS_INFO_START";
export const EDIT_DOCUMENTS_INFO_SUCCESS = "EDIT_DOCUMENTS_INFO_SUCCESS";
export const EDIT_DOCUMENTS_INFO_ERROR = "EDIT_DOCUMENTS_INFO_ERROR";

export const DELETE_DOCUMENTS_INFO_START = "DELETE_DOCUMENTS_INFO_START";
export const DELETE_DOCUMENTS_INFO_SUCCESS = "DELETE_DOCUMENTS_INFO_SUCCESS";
export const DELETE_DOCUMENTS_INFO_ERROR = "DELETE_DOCUMENTS_INFO_ERROR";

export const DOCUMENTS_EDIT = "DOCUMENTS_EDIT";

export const DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_START =
  "DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_START";
export const DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_SUCCESS =
  "DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_SUCCESS";
export const DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_ERROR =
  "DOWNLOAD_NO_RECOMMENDATION_SALES_FILE_ERROR";

export const CLIPBOARD_NO_RECOMMENDATION_DATE = "CLIPBOARD_NO_RECOMMENDATION_DATE";

export const LIST_PRICING_HISTORIC_START = "LIST_PRICING_HISTORIC_START";
export const LIST_PRICING_HISTORIC_SUCCESS = "LIST_PRICING_HISTORIC_SUCCESS";
export const LIST_PRICING_HISTORIC_ERROR = "LIST_PRICING_HISTORIC_ERROR";

export const DOWNLOAD_PRICING_HISTORIC_FILE_START = "DOWNLOAD_PRICING_HISTORIC_FILE_START";
export const DOWNLOAD_PRICING_HISTORIC_FILE_SUCCESS = "DOWNLOAD_PRICING_HISTORIC_FILE_SUCCESS";
export const DOWNLOAD_PRICING_HISTORIC_FILE_ERROR = "DOWNLOAD_PRICING_HISTORIC_FILE_ERROR";

export const OBSERVER_UPLOAD_STATUS = "OBSERVER_UPLOAD_STATUS";

export const GET_PRICING_PENDING_SERVICES_LIST_START = "GET_PRICING_PENDING_SERVICES_LIST_START";
export const GET_PRICING_PENDING_SERVICES_LIST_SUCCESS =
  "GET_PRICING_PENDING_SERVICES_LIST_SUCCESS";
export const GET_PRICING_PENDING_SERVICES_LIST_ERROR = "GET_PRICING_PENDING_SERVICES_LIST_ERROR";

export const GET_PRICING_REALIZED_SERVICES_LIST_START = "GET_PRICING_REALIZED_SERVICES_LIST_START";
export const GET_PRICING_REALIZED_SERVICES_LIST_SUCCESS =
  "GET_PRICING_REALIZED_SERVICES_LIST_SUCCESS";
export const GET_PRICING_REALIZED_SERVICES_LIST_ERROR = "GET_PRICING_REALIZED_SERVICES_LIST_ERROR";

export const SELECTED_SALES_PRICING_SERVICES = "SELECTED_SALES_PRICING_SERVICES";
export const SELECTED_ALL_SALES_PRICING_SERVICES = "SELECTED_ALL_SALES_PRICING_SERVICES";
export const RESET_SELECTED_SALES_PRICING_SERVICES = "RESET_SELECTED_SALES_PRICING_SERVICES";

export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_ERROR = "GET_PRICING_SERVICES_ERROR";

export const GET_SERVICES_TRANSACTIONS_START = "GET_SERVICES_TRANSACTIONS_START";
export const GET_SERVICES_TRANSACTIONS_SUCCESS = "GET_SERVICES_TRANSACTIONS_SUCCESS";
export const GET_SERVICES_TRANSACTIONS_ERROR = "GET_PRICING_SERVICES_TRANSACTIONS_ERROR";
export const RESET_SERVICES_TRANSACTIONS = "RESET_SERVICES_TRANSACTIONS";

export const SAVE_SERVICES_START = "SAVE_SERVICES_START";
export const SAVE_SERVICES_SUCCESS = "SAVE_SERVICES_SUCCESS";
export const SAVE_SERVICES_ERROR = "SAVE_SERVICES_ERROR";
