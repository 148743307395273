export const SET_SELECTED_SALES = "SET_SELECTED_SALES";
export const RESET_SELECTED_SALES = "RESET_SELECTED_SALES";
export const RESET_ASSOCIATED_SALES = "RESET_ASSOCIATED_SALES";

export const GET_PROPOSAL_SOLUTIONS_DETAILS_START = "GET_PROPOSAL_SOLUTIONS_DETAILS_START";
export const GET_PROPOSAL_SOLUTIONS_DETAILS_SUCCESS = "GET_PROPOSAL_SOLUTIONS_DETAILS_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_DETAILS_ERROR = "GET_PROPOSAL_SOLUTIONS_DETAILS_ERROR";

export const GET_PROPOSAL_SOLUTIONS_CLIENTS_START = "GET_PROPOSAL_SOLUTIONS_CLIENTS_START";
export const GET_PROPOSAL_SOLUTIONS_CLIENTS_SUCCESS = "GET_PROPOSAL_SOLUTIONS_CLIENTS_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_CLIENTS_ERROR = "GET_PROPOSAL_SOLUTIONS_CLIENTS_ERROR";
export const RESET_PROPOSAL_SOLUTIONS_CLIENTS = "RESET_PROPOSAL_SOLUTIONS_CLIENTS";

export const GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_START =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_START";
export const GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_SUCCESS =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_ERROR =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_ERROR";

export const GET_PROPOSAL_SOLUTIONS_CONTACTS_START = "GET_PROPOSAL_SOLUTIONS_CONTACTS_START";
export const GET_PROPOSAL_SOLUTIONS_CONTACTS_SUCCESS = "GET_PROPOSAL_SOLUTIONS_CONTACTS_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_CONTACTS_ERROR = "GET_PROPOSAL_SOLUTIONS_CONTACTS_ERROR";

export const GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_START =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_START";
export const GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_SUCCESS =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_ERROR =
  "GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_ERROR";

export const GET_SALES_PROPOSAL_SOLUTIONS_START = "GET_SALES_PROPOSAL_SOLUTIONS_START";
export const GET_SALES_PROPOSAL_SOLUTIONS_SUCCESS = "GET_SALES_PROPOSAL_SOLUTIONS_SUCCESS";
export const GET_SALES_PROPOSAL_SOLUTIONS_ERROR = "GET_SALES_PROPOSAL_SOLUTIONS_ERROR";

export const GET_PROPOSAL_SOLUTIONS_LIST_START = "GET_PROPOSAL_SOLUTIONS_LIST_START";
export const GET_PROPOSAL_SOLUTIONS_LIST_SUCCESS = "GET_PROPOSAL_SOLUTIONS_LIST_SUCCESS";
export const GET_PROPOSAL_SOLUTIONS_LIST_ERROR = "GET_PROPOSAL_SOLUTIONS_LIST_ERROR";

export const GET_FORMALIZATIONS_LIST_START = "GET_FORMALIZATIONS_LIST_START";
export const GET_FORMALIZATIONS_LIST_SUCCESS = "GET_FORMALIZATIONS_LIST_SUCCESS";
export const GET_FORMALIZATIONS_LIST_ERROR = "GET_FORMALIZATIONS_LIST_ERROR";

export const DISASSOCIATE_SALE_START = "DISASSOCIATE_SALE_START";
export const DISASSOCIATE_SALE_SUCCESS = "DISASSOCIATE_SALE_SUCCESS";
export const DISASSOCIATE_SALE_ERROR = "DISASSOCIATE_SALE_ERROR";

export const SAVE_PROPOSAL_SOLUTIONS_START = "SAVE_PROPOSAL_SOLUTIONS_START";
export const SAVE_PROPOSAL_SOLUTIONS_SUCCESS = "SAVE_PROPOSAL_SOLUTIONS_SUCCESS";
export const SAVE_PROPOSAL_SOLUTIONS_ERROR = "SAVE_PROPOSAL_SOLUTIONS_ERROR";
export const SAVE_PROPOSAL_SOLUTIONS_WARNING = "SAVE_PROPOSAL_SOLUTIONS_WARNING";

export const GET_COMPENSATIONS_LIST_START = "GET_COMPENSATIONS_LIST_START";
export const GET_COMPENSATIONS_LIST_SUCCESS = "GET_COMPENSATIONS_LIST_SUCCESS";
export const GET_COMPENSATIONS_LIST_ERROR = "GET_COMPENSATIONS_LIST_ERROR";

export const SELECTED_ASSOCIATED_SALE = "SELECTED_ASSOCIATED_SALE";
export const SELECTED_ALL_ASSOCIATED_SALES = "SELECTED_ALL_ASSOCIATED_SALES";
export const RESET_SELECTED_ASSOCIATED_SALES = "RESET_SELECTED_ASSOCIATED_SALES";

export const SAVE_PROPOSAL_OBSERVATION_START = "SAVE_PROPOSAL_OBSERVATION_START";
export const SAVE_PROPOSAL_OBSERVATION_ERROR = "SAVE_PROPOSAL_OBSERVATION_ERROR";
export const SAVE_PROPOSAL_OBSERVATION_SUCCESS = "SAVE_PROPOSAL_OBSERVATION_SUCCESS";

export const GET_PROPOSAL_OBSERVATION_START = "GET_PROPOSAL_OBSERVATION_START";
export const GET_PROPOSAL_OBSERVATION_ERROR = "GET_PROPOSAL_OBSERVATION_ERROR";
export const GET_PROPOSAL_OBSERVATION_SUCCESS = "GET_PROPOSAL_OBSERVATION_SUCCESS";
export const RESET_PROPOSAL_OBSERVATION = "RESET_PROPOSAL_OBSERVATION";

export const RESET_PROPOSAL_PREVIEW = "RESET_PROPOSAL_PREVIEW";
export const GET_PROPOSAL_PREVIEW_START = "GET_PROPOSAL_PREVIEW_START";
export const GET_PROPOSAL_PREVIEW_SUCCESS = "GET_PROPOSAL_PREVIEW_SUCCESS";
export const GET_PROPOSAL_PREVIEW_ERROR = "GET_PROPOSAL_PREVIEW_ERROR";
export const SEND_PROPOSAL_EMAIL_START = "SEND_PROPOSAL_EMAIL_START";
export const SEND_PROPOSAL_EMAIL_SUCCESS = "SEND_PROPOSAL_EMAIL_SUCCESS";
export const SEND_PROPOSAL_EMAIL_ERROR = "SEND_PROPOSAL_EMAIL_ERROR";
