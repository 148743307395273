import { stringify } from "qs";
import { addMessage } from "store/messages/messages.useCases";

/**
 * @param  {} error Recieved error
 * @param  {} dispatch dispatch function used to send the message and the error action
 * @param  {} action error action to proceed with the original call
 * @param  {} fowardingParams optional foward params of the error (to customize errors feedback)
 */
export const basicErrorHandler = (error, dispatch, action, fowardingParams) => {
  const type = "danger";
  const conectivityErrorMessage = "Ocorreu um erro de conectividade com o servidor";
  const unexpectedErrorMessage = "Ocorreu um erro inesperado";

  if (error.message !== "Cancel")
    if (
      error &&
      error.response &&
      error.response.data &&
      !error.response.data.success &&
      error.response.data.error &&
      Array.isArray(error.response.data.error)
    ) {
      const backendErrorMessage = error.response.data.error.join(" ");
      dispatch(addMessage(backendErrorMessage, type));
      dispatch(action(error, backendErrorMessage, fowardingParams));
    } else if (error && Array.isArray(error?.response?.data?.errors?.errors)) {
      const message = error.response.data.errors.errors.join(" ");
      dispatch(addMessage(message, type));
      dispatch(action(error, message, fowardingParams));
    } else if (error && Array.isArray(error?.response?.data?.errors?.Messages)) {
      const message = error.response?.data?.errors.Messages.join(". ");
      dispatch(addMessage(message, type));
      dispatch(action(error, message, fowardingParams));
    } else if (error && typeof error === "string") {
      dispatch(addMessage(error, type));
      dispatch(action(error, "unexpected error", fowardingParams));
    } else if (typeof error === "object" && error && error.message) {
      dispatch(addMessage(error.message, type));
      dispatch(action(error, error.message, fowardingParams));
    } else if (error && error.request) {
      dispatch(addMessage(conectivityErrorMessage, type));
      dispatch(action(error, "Request error", fowardingParams));
    } else {
      dispatch(addMessage(unexpectedErrorMessage, type));
      dispatch(action("unexpected error", "unexpected error", fowardingParams));
    }
};
