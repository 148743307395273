export const getFileName = response => {
  const disposition = response.headers["content-disposition"];
  let fileName;

  let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, "");
  }

  return fileName;
};
