import * as actionsTypes from "./saleTypes.actionTypes";

export const getSaleTypesStart = () => ({
  type: actionsTypes.GET_SALE_TYPES_START,
});

export const getSaleTypesSuccess = data => ({
  type: actionsTypes.GET_SALE_TYPES_SUCCESS,
  data,
});

export const getSaleTypesError = (error, message) => ({
  type: actionsTypes.GET_SALE_TYPES_ERROR,
  error,
  message,
});
