import ReactGA from "react-ga";
import { isNotProdEnv } from "utils/utility";

const trackingId = "UA-158282127-1";
const isDebugging = false;

export const startTrackingUser = () => {
  if (!isNotProdEnv()) {
    ReactGA.initialize(trackingId, { debug: isDebugging });
  }
};

export const setUserId = userSystemRegistry => {
  if (!isNotProdEnv()) {
    ReactGA.set({ userId: userSystemRegistry });
  }
};

export const setRouteTracking = history => {
  if (!isNotProdEnv()) {
    history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }
};

export const sendEvent = (category, action) => {
  if (!isNotProdEnv()) {
    if (category && action) {
      ReactGA.event({
        category,
        action,
      });
    }
  }
};
