import { updateObject } from "utils/utility";
import * as actionsTypes from "./subSegments.actionTypes";

const initialState = {
  listSubSegmentsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    subSegmentsOptions: [],
    subSegmentsOptionsDS: [],
  },
};

const reset = () => initialState;

/* LIST SUBSEGMENTS OPTIONS */

const listSubSegmentsOptionsStart = state =>
  updateObject(state, {
    listSubSegmentsOptions: {
      ...state.listSubSegmentsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listSubSegmentsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listSubSegmentsOptions: {
      ...state.listSubSegmentsOptions,
      loading: false,
      resultType: "success",
      message: null,
      subSegmentsOptions: action.payload,
      subSegmentsOptionsDS: action.payload?.map(item => ({
        value: item.id,
        label: item.label,
      })),
    },
  });

const listSubSegmentsOptionsError = (state, action) =>
  updateObject(state, {
    listSubSegmentsOptions: {
      ...state.listSubSegmentsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const subSegmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.RESET:
      return reset();

    case actionsTypes.LIST_SUBSEGMENTS_OPTIONS_START:
      return listSubSegmentsOptionsStart(state);
    case actionsTypes.LIST_SUBSEGMENTS_OPTIONS_SUCCESS:
      return listSubSegmentsOptionsSuccess(state, action);
    case actionsTypes.LIST_SUBSEGMENTS_OPTIONS_ERROR:
      return listSubSegmentsOptionsError(state, action);

    default:
      return state;
  }
};

export default subSegmentsReducer;
