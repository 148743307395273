import { updateObject } from "utils/utility";
import { ERROR_MESSAGES } from "constants/messages";
import * as actionTypes from "./login.actionTypes";

const initialState = {
  loginForm: {
    loading: false,
    error: null,
    message: null,
    resultType: "alert",
    token: false,
    showAuthModal: false,
    updateAuthRefresh: false,
  },
};

const loginStart = state => {
  const newObj = updateObject(state.loginForm, {
    loading: true,
    error: null,
    message: null,
  });

  return updateObject(state, {
    loginForm: newObj,
  });
};

const loginSuccess = (state, action) => {
  const newObj = updateObject(state.loginForm, {
    loading: false,
    showAuthModal: false,
    updateAuthRefresh: true,
  });

  return updateObject(state, {
    loginForm: newObj,
    token: action.token,
  });
};

const loginError = (state, action) => {
  const newObj = updateObject(state.loginForm, {
    resultType: "danger",
    error: action.error,
    message: ERROR_MESSAGES.AUTH,
    loading: false,
    updateAuthRefresh: false,
  });

  return updateObject(state, {
    loginForm: newObj,
  });
};

const loginModalShow = state =>
  updateObject(state, {
    loginForm: updateObject(state.loginForm, {
      showAuthModal: true,
      updateAuthRefresh: false,
    }),
  });

const updateAuthRefresh = state => {
  return updateObject(state, {
    ...state,
    loginForm: {
      ...state.loginForm,
      updateAuthRefresh: false,
    },
  });
};

const loginReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_ERROR:
      return loginError(state, action);
    case actionTypes.LOGIN_MODAL_SHOW:
      return loginModalShow(state);

    case actionTypes.UPDATE_AUTH_REFRESH:
      return updateAuthRefresh(state);

    default:
      return state;
  }
};

export default loginReducer;
