import { simpleId, updateObject } from "utils/utility";

import * as actionTypes from "./messages.actionTypes";

const initialState = {
  messages: [],
  toast: {
    active: false,
    status: null,
    message: null,
    title: null,
  },
};

const reset = () => initialState;

const addMessage = (state, action) => {
  const messages = state.messages.concat([
    {
      id: simpleId(),
      text: action.message.text,
      type: action.message.type,
      count: 1,
    },
  ]);

  messages.forEach(msg => {
    messages.forEach((msgValid, msgValidIndex) => {
      if (msg.id !== msgValid.id && msg.text === msgValid.text) {
        msg.count += 1;
        messages.splice(msgValidIndex, 1);
      }
    });
  });

  return { messages };
};

const removeMessage = (state, action) => ({
  messages: state.messages.filter(item => item.id !== action.id),
});

const setToast = (state, action) => {
  return updateObject(state, {
    toast: {
      ...state.toast,
      active: action.active,
      status: action.status,
      message: action.message,
      title: action.title,
    },
  });
};

const messagesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);

    case actionTypes.SET_TOAST:
      return setToast(state, action);
    default:
      return state;
  }
};

export default messagesReducer;
