import API from "utils/api";
import { history } from "store/store";
import { addMessage } from "store/messages/messages.useCases";

import { subSegmentsOptionsBackToFront } from "store/subSegments/subSegments.format";
import { formatUserBackToFront, formatUserFrontToBack } from "./users.format";
import { basicErrorHandler, autoListingParamsGet, downloadBlob } from "utils/utility";

import * as actions from "./users.actions";
import * as urls from "utils/urls";
import { getFileName } from "lib/files";

export const listUsers = (filterFields, page, itemsPerPageCount) => dispatch => {
  dispatch(actions.listUsersStart());

  const enhancedOptions = {
    ...filterFields,
    page,
    itemsPerPageCount,
  };

  const url = `v1/Usuario?${autoListingParamsGet(enhancedOptions)}`;

  API.get(url)
    .then(response => {
      const { data } = response.data;

      const formattedUsers = formatUserBackToFront(data);
      dispatch(
        actions.listUsersSuccess(formattedUsers, {
          filterFields: filterFields.filterFields,
          itemsPerPageCount: response.data?.pageSize,
          page: response.data?.page,
          totalPages: response.data?.totalPages,
          totalCount: response.data?.totalRegistries,
        }),
      );
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listUsersError));
};

export const listUsersOptions = options => dispatch => {
  dispatch(actions.listUsersOptions(options));
};

export const getUserDetail = id => dispatch => {
  dispatch(actions.listUserDetailsStart());

  const url = `v1/Usuario/matricula/${id}`;

  API.get(url)
    .then(response => {
      dispatch(actions.listUserDetailsSuccess(response.data));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listUserDetailsError));
};

export const getUserDetailSimplified = () => dispatch => {
  dispatch(actions.listUserDetailsSimplifiedStart());

  const url = "/v1/Usuario/Perfil";

  API.get(url)
    .then(response => {
      dispatch(actions.listUserDetailsSimplifiedSuccess(response.data));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.listUserDetailsSimplifiedError));
};

export const saveUser = values => dispatch => {
  dispatch(actions.saveUserStart());

  const url = "v1/usuario";

  API.post(url, formatUserFrontToBack(values))
    .then(() => {
      dispatch(actions.saveUserSuccess());
      history.push(urls.PAGE_USER_AND_PROFILE);
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.saveUserError));
};

export const editUser = values => dispatch => {
  dispatch(actions.editUserStart());

  const url = "v1/usuario";

  API.put(url, formatUserFrontToBack(values))
    .then(() => {
      dispatch(actions.editUserSuccess());
      history.push(urls.PAGE_USER_AND_PROFILE);
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.editUserError));
};

export const getUserSubSegments = () => dispatch => {
  dispatch(actions.getUserSubSegmentsStart());

  const url = "/v1/usuario/subsegmentos";

  API.get(url)
    .then(response => {
      dispatch(actions.getUserSubSegmentsSuccess(subSegmentsOptionsBackToFront(response.data)));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.getUserSubSegmentsFail));
};

export const deleteUser = (id, page, itemsPerPageCount) => dispatch => {
  dispatch(actions.deleteUserStart());

  const url = `v1/usuario/${id}`;

  API.delete(url)
    .then(() => {
      dispatch(actions.deleteUserSuccess());
      dispatch(listUsers({ filterFields: {} }, page, itemsPerPageCount));
      history.replace(urls.PAGE_USER_AND_PROFILE);
      dispatch(addMessage("Usuário excluído com sucesso!", "success"));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.deleteUserError));
};

export const downloadUsersFile = filterFields => dispatch => {
  dispatch(actions.exportUsersFileStart());
  const enhancedOptions = {
    filterFields: {
      PerfilIds: filterFields?.perfilIds,
      CargoIds: filterFields?.cargoIds,
    },
  };

  const url = `v1/Usuario/Exportar?${autoListingParamsGet(enhancedOptions, false)}`;

  API.get(url, {
    responseType: "blob",
  })
    .then(response => {
      downloadBlob(response.data, getFileName(response));
      dispatch(addMessage("Arquivo baixado com sucesso", "success"));
      dispatch(actions.exportUsersFileSuccess());
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.exportUsersFileFail));
};
