import { updateObject } from "utils/utility";
import * as actionTypes from "./origins.actionTypes";

const initialState = {
  listOriginsHighlightOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    originsOptions: [],
  },
  originsHarborsBySegmentN1: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    originsOptions: [],
  },
};

const reset = () => initialState;

const listOriginsHighlightOptionsStart = state =>
  updateObject(state, {
    listOriginsHighlightOptions: {
      ...state.listOriginsHighlightOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listOriginsHighlightOptionsSuccess = (state, action) => {
  const newOriginsOptions = [...state.listOriginsHighlightOptions.originsOptions];

  newOriginsOptions[action.searchIndex] = action.origins.map(item => ({
    ...item,
    id: item.id,
    label: item.name,
  }));

  return updateObject(state, {
    listOriginsHighlightOptions: {
      ...state.listOriginsHighlightOptions,
      loading: false,
      resultType: "success",
      message: null,
      originsOptions: newOriginsOptions,
      originsOptionsDS: action.origins.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });
};

const listOriginsHighlightOptionsError = (state, action) =>
  updateObject(state, {
    listOriginsHighlightOptions: {
      ...state.listOriginsHighlightOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      originsOptions: [],
    },
  });

/* ORIGINS HARBOR'S REQUEST BY SEGMENT N1 */

const originsHarborsOptionsBySegmentN1Start = state =>
  updateObject(state, {
    originsHarborsBySegmentN1: {
      ...state.originsHarborsBySegmentN1,
      loading: true,
      error: null,
      message: null,
    },
  });

const originsHarborsOptionsBySegmentN1Success = (state, action) => {
  return updateObject(state, {
    originsHarborsBySegmentN1: {
      ...state.originsHarborsBySegmentN1,
      loading: false,
      resultType: "success",
      message: null,
      originsOptions: action.origins.map(item => ({
        id: item.id,
        label: item.name,
      })),
    },
  });
};

const originsHarborsOptionsBySegmentN1Error = (state, action) =>
  updateObject(state, {
    originsHarborsBySegmentN1: {
      ...state.originsHarborsBySegmentN1,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      originsOptions: [],
    },
  });

const originsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_ORIGINS_HIGHLIGHT_OPTIONS_START:
      return listOriginsHighlightOptionsStart(state);
    case actionTypes.LIST_ORIGINS_HIGHLIGHT_OPTIONS_SUCCESS:
      return listOriginsHighlightOptionsSuccess(state, action);
    case actionTypes.LIST_ORIGINS_HIGHLIGHT_OPTIONS_ERROR:
      return listOriginsHighlightOptionsError(state, action);

    case actionTypes.ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_START:
      return originsHarborsOptionsBySegmentN1Start(state);
    case actionTypes.ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_SUCCESS:
      return originsHarborsOptionsBySegmentN1Success(state, action);
    case actionTypes.ORIGINS_HARBORS_OPTIONS_BY_SEGMENTN1_ERROR:
      return originsHarborsOptionsBySegmentN1Error(state, action);

    default:
      return state;
  }
};

export default originsReducer;
