/**
 * @param  {number} n Number to normalize
 */
export const normalizeNumber = n => {
  if (Number.isInteger(n)) {
    return n;
  }
  if (n.toFixed) {
    return n.toFixed(2);
  }
  return "";
};

export const roundNumber = n => {
  if (Number.isInteger(n)) {
    return n;
  }
  if (n.toFixed) {
    return n.toFixed();
  }
  return "";
};

const formatCurrency = (value, format = "N") => {
  const intPart = value.split(",")[0];
  const floatNumber = Number.parseFloat(value.replace(",", "."));
  const intPartWithoutSymbols = intPart.replace("-", "");

  const currencyToBi = value => ["Bi", normalizeNumber(value / 1000000000)];
  const currencyToM = value => ["M", normalizeNumber(value / 1000000)];
  const currencyToK = value => ["K", normalizeNumber(value / 1000)];
  const normalizeCurrency = value => ["", normalizeNumber(value)];

  const currencyFormat = () => {
    if (intPartWithoutSymbols.length >= 10) {
      return currencyToBi(floatNumber);
    } else if (intPartWithoutSymbols.length >= 7 && intPartWithoutSymbols.length <= 9) {
      return currencyToM(floatNumber);
    } else if (intPartWithoutSymbols.length >= 4 && intPartWithoutSymbols.length <= 6) {
      return currencyToK(floatNumber);
    }
    return normalizeCurrency(floatNumber);
  };

  const currencyFormatter = {
    Bi: currencyToBi,
    M: currencyToM,
    K: currencyToK,
    R: normalizeCurrency,
    N: currencyFormat,
  };
  return currencyFormatter[format](floatNumber);
};

const formatVolume = (value, isUN, format = "N", label = "") => {
  const decimalFormatter = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
  });

  const volumeToBi = value =>
    `${decimalFormatter.format(normalizeNumber(value / 1000000000))} ${
      isUN ? "BiUN" : label ? `Bi${label}` : "BiTU"
    }`;
  const volumeToM = value =>
    `${decimalFormatter.format(normalizeNumber(value / 1000000))} ${
      isUN ? "MUN" : label ? `M${label}` : "MTU"
    }`;
  const volumeToK = value =>
    `${decimalFormatter.format(normalizeNumber(value / 1000))} ${
      isUN ? "KUN" : label ? `K${label}` : "KTU"
    }`;
  const normalizeVolume = value =>
    `${decimalFormatter.format(normalizeNumber(value))} ${isUN ? "UN" : label || "TU"}`;

  const volumeFormat = () => {
    const intPart = String(value).split(".")[0];
    const intPartWithoutSymbols = intPart.replace("-", "");

    if (intPartWithoutSymbols.length >= 10) {
      return volumeToBi(value);
    } else if (intPartWithoutSymbols.length >= 7 && intPartWithoutSymbols.length <= 9) {
      return volumeToM(value);
    } else if (intPartWithoutSymbols.length >= 4 && intPartWithoutSymbols.length <= 6) {
      return volumeToK(value);
    }
    return normalizeVolume(value);
  };

  const volumeFormatter = {
    Bi: volumeToBi,
    M: volumeToM,
    K: volumeToK,
    R: normalizeVolume,
    N: volumeFormat,
  };
  return volumeFormatter[format](value);
};

/**
 * @param  {number} val the value to format
 * @param  {boolean} isCurrency=false If it's money or weight format
 * @param  {boolean} isUN=false If it's money or weight format
 * @param {string} format should format in a specific format
 */
export const convertToTU = (val, isCurrency = false, isUN = false, format = undefined) => {
  if (val === "" || val === undefined) return "-";

  if (isCurrency) {
    let newValue = val.toString().replace(".", ",");
    let intPart;
    let decimalPart;

    if (newValue.includes(",")) {
      const tmpArray = newValue.split(",");
      intPart = tmpArray[0];
      decimalPart = tmpArray[1];
    } else {
      intPart = newValue;
      decimalPart = "00";
    }

    newValue = `${intPart},${decimalPart}`;
    const [prefix, formattedNumber] = formatCurrency(newValue, format);

    let result = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(formattedNumber);

    if (prefix && result[0] === "-") {
      return `-${prefix}${result.replace("-", "").replace("$", "$")}`;
    }
    return `${prefix || ""}${result.replace("$", "$")}`;
  }

  let resultValue;

  resultValue = formatVolume(val, isUN, format);

  return val.length >= 8 ? resultValue.split(",")[0] : resultValue;
};

export const convertToUnityMeasure = (val, isCurrency = false, label = "", format = undefined) => {
  if (val === "" || val === undefined) return "-";

  if (isCurrency) {
    let newValue = val.toString().replace(".", ",");
    let intPart;
    let decimalPart;

    if (newValue.includes(",")) {
      const tmpArray = newValue.split(",");
      intPart = tmpArray[0];
      decimalPart = tmpArray[1];
    } else {
      intPart = newValue;
      decimalPart = "00";
    }

    newValue = `${intPart},${decimalPart}`;

    const floatNumber = Number.parseFloat(newValue.replace(",", "."));
    let formattedNumber;
    let prefix;

    prefix = "K";
    formattedNumber = normalizeNumber(floatNumber / 1000);

    let result = new Intl.NumberFormat("pr-BR", {
      style: "currency",
      currency: "BRL",
    }).format(formattedNumber);

    result = result.replace(/\./g, "^");
    result = result.replace(/\,/g, "*");
    result = result.replace(/\^/g, ",");
    result = result.replace(/\*/g, ".");

    if (prefix && result[0] === "-") {
      return `-${prefix}${result.replace("-", "").replace("$", "$")}`;
    }
    return `${prefix || ""}${result.replace("$", "$")}`;
  }

  let resultValue = formatVolume(val, false, format, label);

  resultValue = resultValue.replace(/\./g, "^");
  resultValue = resultValue.replace(/\,/g, "*");
  resultValue = resultValue.replace(/\^/g, ",");
  resultValue = resultValue.replace(/\*/g, ".");

  return val.length >= 8 ? resultValue.split(",")[0] : resultValue;
};

/**
 * @param  {} n
 */
export const twoDecimals = n => parseFloat(Math.round(n * 100) / 100).toFixed(2);

/**
 * Normal string gets it's first letter uppercased
 * @param  {string} s basic string
 */
export const firstUppercase = s => {
  if (s && typeof s === "string") {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  return "";
};

/**
 * returns an hyphen if its an invalid value to show
 * @param  {string} string any string content (valid or not)
 */
export const valstr = string => (!string ? "-" : string);

export const valarray = values => {
  if (values.length) {
    return values.join(",");
  }
  return "-";
};

/**
 * safe returns an string preventing unexpected errors when recieving unexpected objects
 * @param  {any} x any object
 */
export const safeToString = x => {
  switch (typeof x) {
    case "object":
      return "object";
    case "function":
      return "function";
    default:
      return `${x}`;
  }
};

/**
 * Convert a value to your currency mode in R$
 * @author Gabriel Consalter
 *
 * @param {number} n - Value to show in currency mode
 * @param {boolean} [showCurrency=true] - If shows currency 'R$' in string or not
 * @returns {string} - The value in currency mode as string
 */
export const currency = (n, showCurrency = true) => {
  const numbers = Number(n)
    .toFixed(2)
    .toString()
    .split(".");
  return `${showCurrency ? "R$" : ""}${numbers[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")},${
    numbers[1]
  }`;
};

/**
 * Get only the number from a string
 * @param {string} value
 * @returns {number}
 */
export const extractNumberFromString = value => {
  return Number(value.replace(/\D/g, ""));
};

export const transformMonthYearDateToNewDate = date => {
  if (date) {
    return new Date(date.split("/")?.[1], Number(date.split("/")?.[0]) - 1);
  } else {
    return undefined;
  }
};

export function uniqBy(a, key) {
  let tempArray = {};
  return a.filter(function(item) {
    var k = key(item);
    return tempArray.hasOwnProperty(k) ? false : (tempArray[k] = true);
  });
}

/**
 * Receives a object and return a list for compare if is equal
 * @param {object} value
 * @returns {string}
 */
export const formatProposalDetailsForCompare = proposal => {
  return JSON.stringify({
    gerenteResponsavel: {
      id: proposal?.gerenteResponsavel?.id,
      matricula: proposal?.gerenteResponsavel?.matricula,
      nome: proposal?.gerenteResponsavel?.nome,
      email: proposal?.gerenteResponsavel?.email,
      telefone: proposal?.gerenteResponsavel?.telefone,
    },
    descricao: proposal?.descricao,
    contratantes: proposal?.contratantes,
    devedoresSolidarios: proposal?.devedoresSolidarios,
    contatos: proposal?.contatos,
    top: proposal?.top,
  });
};
