export const SYNCHRONIZE_START = "SYNCHRONIZE_START";
export const SYNCHRONIZE_SUCCESS = "SYNCHRONIZE_SUCCESS";
export const SYNCHRONIZE_FAIL = "SYNCHRONIZE_FAIL";

export const GET_STATUS_START = "GET_STATUS_START";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAIL = "GET_STATUS_FAIL";
export const GET_STATUS_OPTIONS = "GET_STATUS_OPTIONS";

export const INTEGRATION_RESET = "INTEGRATION_RESET";
