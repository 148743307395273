import * as actionTypes from "./taxCalculation.actionTypes";
import { updateObject } from "utils/utility";

const initialState = {};

const getTaxCalculationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state[action.tabId]?.taxes,
        [action.transactionKey]: {
          loading: true,
          error: null,
          message: null,
          liquidTax: undefined,
          clientTax: undefined,
        },
      },
    },
  });
};

const getTaxCalculationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state?.[action.tabId]?.taxes,
        [action.transactionKey]: {
          loading: false,
          liquidTax: action.liquidTax,
          clientTax: action.clientTax,
          isClientTaxInsert: action.isClientTaxInsert,
        },
      },
    },
  });
};

const getTaxCalculationError = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state?.[action.tabId]?.taxes,
        [action.transactionKey]: {
          loading: false,
          error: action.error,
          message: action.message,
        },
      },
    },
  });
};

const updateTaxCalculationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state[action.tabId]?.taxes,
        [action.transactionKey]: {
          ...state[action.tabId]?.taxes[action.transactionKey],
          loading: true,
          error: null,
          message: null,
        },
      },
    },
  });
};

const updateTaxCalculationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state[action.tabId]?.taxes,
        [action.transactionKey]: {
          ...state[action.tabId]?.taxes?.[action.transactionKey],
          loading: false,
          liquidTax: action.liquidTax,
          clientTax: action.clientTax,
          tax: action.tax,
        },
      },
    },
  });
};

const updateTaxCalculationError = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state[action.tabId].taxes,
        [action.transactionKey]: {
          ...state[action.tabId].taxes[action.transactionKey],
          loading: false,
          error: action.error,
          message: action.message,
        },
      },
    },
  });
};

const resetTaxCalculation = (state, action) => {
  return updateObject({
    ...state,
    [action.tabId]: {
      taxes: {},
    },
  });
};

const resetTransactionTaxCalculation = (state, action) => {
  return updateObject(state, {
    ...state,
    [action.tabId]: {
      taxes: {
        ...state.taxes,
        [action.transactionKey]: {},
      },
    },
  });
};

const taxCalculationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TAX_CALCULATION_START:
      return getTaxCalculationStart(state, action);
    case actionTypes.GET_TAX_CALCULATION_SUCCESS:
      return getTaxCalculationSuccess(state, action);
    case actionTypes.GET_TAX_CALCULATION_ERROR:
      return getTaxCalculationError(state, action);

    case actionTypes.UPDATE_TAX_CALCULATION_START:
      return updateTaxCalculationStart(state, action);
    case actionTypes.UPDATE_TAX_CALCULATION_SUCCESS:
      return updateTaxCalculationSuccess(state, action);
    case actionTypes.UPDATE_TAX_CALCULATION_ERROR:
      return updateTaxCalculationError(state, action);

    case actionTypes.RESET_TAX_CALCULATION:
      return resetTaxCalculation(state, action);

    case actionTypes.RESET_TRANSACTION_TAX_CALCULATION:
      return resetTransactionTaxCalculation(state, action);

    default:
      return state;
  }
};

export default taxCalculationReducer;
