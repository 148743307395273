export const subSegmentsOptionsBackToFront = subSegments =>
  subSegments.map(subSegment => subSegmentByIdBackToFront(subSegment));

export const subSegmentByIdBackToFront = subSegment => {
  return {
    id: subSegment.id,
    label: subSegment.nome,
    segmentsN1: subSegment.segmentosN1.map(n1 => {
      return {
        id: n1.id,
        label: n1.descricao,
        segmentId: n1.segmentoId,
        segmentType: n1.segmentoTipo,
        idSegmentTree: n1.arvoreSegmentoId,
        saleTypes: n1.vendaTipos,
      };
    }),
  };
};
