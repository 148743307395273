import { updateObject } from "utils/utility";
import * as actionTypes from "./salesRearrange.actionTypes";

const initialState = {
  listSalesRearrange: {
    salesRearrange: [],
    properties: [],
  },
  createRearrangedSale: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: {},
    averageLiquidTax: 0,
  },
};

const setSalesRearrange = (state, action) => {
  const values = action.salesRearrange;
  let properties = [];

  if (values) {
    Object.keys(values).forEach(year => {
      Object.keys(values[year]).forEach(month => {
        values[year][month].forEach((sale, index) => {
          if (sale.checked === true) properties.push(`monthYearSale.${year}.${month}.${index}`);
        });
      });
    });
  }

  return updateObject(state, {
    listSalesRearrange: {
      ...state.listSalesRearrange,
      salesRearrange: values,
      properties,
    },
  });
};

const resetSalesRearrange = state =>
  updateObject(state, {
    listSalesRearrange: {
      ...state.listSalesRearrange,
      salesRearrange: undefined,
      properties: undefined,
    },
  });

const createRearrangedSaleStart = state =>
  updateObject(state, {
    createRearrangedSale: {
      ...state.createRearrangedSale,
      loading: true,
    },
  });

const createRearrangedSaleSuccess = (state, action) =>
  updateObject(state, {
    createRearrangedSale: {
      ...state.createRearrangedSale,
      loading: false,
      resultType: "success",
      data: action.data,
      averageLiquidTax: action.averageLiquidTax,
    },
  });

const createRearrangedSaleError = (state, action) =>
  updateObject(state, {
    createRearrangedSale: {
      ...state.createRearrangedSale,
      loading: false,
      error: action.error,
      resultType: "danger",
      message: action.message,
    },
  });

const finishSaleRearrangedCreating = state =>
  updateObject(state, {
    createRearrangedSale: {
      ...state.createRearrangedSale,
      resultType: "danger",
    },
  });

const salesRearrangeReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.SET_SALES_REARRANGE:
      return setSalesRearrange(state, action);
    case actionTypes.RESET_SALES_REARRANGE:
      return resetSalesRearrange(state);

    case actionTypes.CREATE_REARRANGED_SALE_START:
      return createRearrangedSaleStart(state);
    case actionTypes.CREATE_REARRANGED_SALE_SUCCESS:
      return createRearrangedSaleSuccess(state, action);
    case actionTypes.CREATE_REARRANGED_SALE_ERROR:
      return createRearrangedSaleError(state, action);

    case actionTypes.FINISH_SALE_REARRANGED_CREATING:
      return finishSaleRearrangedCreating(state);

    default:
      return state;
  }
};

export default salesRearrangeReducer;
