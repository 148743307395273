import * as actionTypes from "./users.actionTypes";

/* list users options */

export const listUsersStart = () => ({
  type: actionTypes.LIST_USERS_START,
});

export const listUsersSuccess = (list, config) => ({
  type: actionTypes.LIST_USERS_SUCCESS,
  list,
  config,
});

export const listUsersError = (error, message) => ({
  type: actionTypes.LIST_USERS_ERROR,
  error,
  message,
});

export const listUsersOptions = options => ({
  type: actionTypes.LIST_USERS_OPTIONS,
  options,
});

/* get user details */

export const listUserDetailsStart = () => ({
  type: actionTypes.LIST_USER_DETAILS_START,
});

export const listUserDetailsSuccess = details => ({
  type: actionTypes.LIST_USER_DETAILS_SUCCESS,
  details,
});

export const listUserDetailsError = (error, message) => ({
  type: actionTypes.LIST_USER_DETAILS_ERROR,
  error,
  message,
});

/* get user details simplified */

export const listUserDetailsSimplifiedStart = () => ({
  type: actionTypes.LIST_USER_DETAILS_SIMPLIFIED_START,
});

export const listUserDetailsSimplifiedSuccess = details => ({
  type: actionTypes.LIST_USER_DETAILS_SIMPLIFIED_SUCCESS,
  details,
});

export const listUserDetailsSimplifiedError = (error, message) => ({
  type: actionTypes.LIST_USER_DETAILS_SIMPLIFIED_ERROR,
  error,
  message,
});

/* save user */

export const saveUserStart = () => ({
  type: actionTypes.SAVE_USER_START,
});

export const saveUserSuccess = () => ({
  type: actionTypes.SAVE_USER_SUCCESS,
});

export const saveUserError = (error, message) => ({
  type: actionTypes.SAVE_USER_ERROR,
  error,
  message,
});

/* edit user */

export const editUserStart = values => ({
  type: actionTypes.EDIT_USER_START,
  values,
});

export const editUserSuccess = () => ({
  type: actionTypes.EDIT_USER_SUCCESS,
});

export const editUserError = (error, message) => ({
  type: actionTypes.EDIT_USER_ERROR,
  error,
  message,
});

/* delete user */

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserError = (error, message) => ({
  type: actionTypes.DELETE_USER_ERROR,
  error,
  message,
});

export const getUserSubSegmentsStart = () => ({
  type: actionTypes.GET_USER_SUBSEGMENTS_START,
});

export const getUserSubSegmentsSuccess = subsegments => ({
  type: actionTypes.GET_USER_SUBSEGMENTS_SUCCESS,
  subsegments,
});

export const getUserSubSegmentsFail = (error, message) => ({
  type: actionTypes.GET_USER_SUBSEGMENTS_FAIL,
  error,
  message,
});

export const exportUsersFileStart = () => ({
  type: actionTypes.EXPORT_USERS_FILE_START,
});

export const exportUsersFileSuccess = () => ({
  type: actionTypes.EXPORT_USERS_FILE_SUCCESS,
});

export const exportUsersFileFail = (error, message) => ({
  type: actionTypes.EXPORT_USERS_FILE_FAIL,
  error,
  message,
});
