import { updateObject } from "utils/utility";
import * as actionTypes from "./proposalSolutions.actionTypes";

const INITIAL_STATE = {
  getProposalSolutionsDetails: {
    details: undefined,
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getProposalSolutionsClients: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getProposalSolutionsAccountManagers: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getProposalSolutionsContacts: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getSalesProposalSolutions: {
    details: {},
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getProposalSolutionsList: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getProposalSolutionsAccountabilities: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  getFormalizationsList: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  disassociateSale: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  saveProposalSolutions: {
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  selectedSales: {
    list: [],
  },
  getCompensationsList: {
    list: [],
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  selectedAssociatedSales: {
    list: [],
  },
  proposalObservation: {
    observation: undefined,
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
  proposalPreview: {
    preview: undefined,
    email: {
      destination: "",
      copy: false,
      message: "",
    },
    loading: false,
    error: null,
    message: null,
    resultType: "danger",
  },
};

const resetProposalSolutionsList = state =>
  updateObject(state, {
    ...state,
    error: null,
    message: null,
    loading: false,
    list: [],
  });

const proposalSolutionsStart = state =>
  updateObject(state, {
    ...state,
    loading: true,
  });

const getProposalSolutionsListSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    loading: false,
    resultType: "success",
    message: null,
    list: action.list,
    config: action.config,
  });

const getProposalSolutionsListError = (state, action) =>
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    list: [],
  });

const saveProposalSolutionsSuccess = state =>
  updateObject(state, {
    ...state,
    loading: false,
    error: null,
    resultType: "success",
  });

const saveProposalSolutionsError = (state, action) =>
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
  });

const saveProposalSolutionsWarning = state =>
  updateObject(state, {
    ...state,
    loading: false,
    error: null,
    resultType: "danger",
  });

const getProposalSolutionsDetailsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    resultType: "success",
    details: action.details,
    options: {
      ...state.options,
      itemsPerPageCount: action?.options?.itemsPerPageCount,
      page: action?.options?.page,
      totalPages: action?.options?.totalPages,
      totalCount: action?.options?.totalCount,
      filterFields: action?.filterFields,
    },
  });
};

const getProposalSolutionsDetailsError = (state, action) =>
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    details: undefined,
  });

const disassociateSaleSuccess = state => {
  updateObject(state, {
    ...state,
    loading: false,
    resultType: "danger",
    details: undefined,
  });
};

const disassociateSaleError = (state, action) =>
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    list: state?.list ? [...state.list] : [],
  });

const setSelectedSales = (state, action) => {
  const hasSale = state?.list?.filter(sale => sale.id === action.sale.id).length > 0;
  let newList = [];

  if (hasSale) {
    newList = [
      ...state.list.map(sale => {
        if (sale.id === action.sale.id) {
          return {
            id: action.sale.id,
            checked: action.sale.checked,
            totalVolume: action.sale.totalVolume,
          };
        } else {
          return sale;
        }
      }),
    ];
  } else {
    if (state?.list?.length > 0) {
      newList = [...state.list, action.sale];
    } else {
      newList = [action.sale];
    }
  }
  return updateObject(state, {
    ...state,
    list: [...newList],
  });
};

const setSelectedAssociatedSales = (state, action) => {
  const hasSale = state?.list?.filter(sale => sale.id === action.id).length > 0;
  let newList = [];

  if (hasSale) {
    newList = [
      ...state.list.map(sale => {
        if (sale.id === action.id) {
          return { id: action.id, checked: action.checked };
        } else {
          return sale;
        }
      }),
    ];
  } else {
    if (state?.list?.length > 0) {
      newList = [...state.list, { id: action.id, checked: action.checked }];
    } else {
      newList = [{ id: action.id, checked: action.checked }];
    }
  }
  return updateObject(state, {
    ...state,
    list: [...newList],
  });
};

const setAllSelectedAssociatedSales = (state, action) => {
  let newList = [];
  if (action.ids?.length > 0) {
    newList = action.ids.map(id => {
      return { id, checked: action.checked };
    });
  }
  return updateObject(state, {
    ...state,
    list: [...newList],
  });
};

const resetSelectedASsociatedSales = state => {
  return updateObject(state, {
    ...state,
    list: [],
  });
};

const saveProposalObservationStart = state => {
  updateObject(state, {
    ...state,
    loading: true,
    error: null,
    message: null,
    observation: undefined,
  });
};

const saveProposalObservationSuccess = (state, action) => {
  updateObject(state, {
    ...state,
    loading: false,
    error: null,
    resultType: "success",
    message: action.message,
    observation: action.observation,
  });
};

const saveProposalObservationError = (state, action) => {
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    observation: undefined,
  });
};

const getProposalObservationStart = (state, action) => {
  updateObject(state, {
    ...state,
    loading: true,
    error: null,
    message: null,
    observation: undefined,
  });
};

const getProposalObservationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    resultType: "success",
    message: action.message,
    observation: action.observation,
  });
};

const getProposalObservationError = (state, action) => {
  updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    observation: undefined,
  });
};

const resetProposalObservation = state => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    message: null,
    observation: undefined,
  });
};

const resetProposalPreview = state => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    message: null,
    preview: undefined,
    email: {
      destination: "",
      copy: false,
      message: "",
    },
  });
};

const getProposalPreviewStart = state => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
    message: null,
    preview: undefined,
    email: {
      destination: "",
      copy: false,
      message: "",
    },
  });
};

const getProposalPreviewSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    message: null,
    resultType: "success",
    preview: action.preview,
    email: {
      destination: "",
      copy: false,
      message: "",
    },
  });
};

const getProposalPreviewError = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
    preview: undefined,
    email: {
      destination: "",
      copy: false,
      message: "",
    },
  });
};

const sendProposalEmailStart = state => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
    message: null,
    resultType: "danger",
  });
};

const sendProposalEmailSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: null,
    message: action.message,
    resultType: "success",
  });
};

const sendProposalEmailError = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
    message: action.message,
    resultType: "danger",
  });
};

// REDUCERS

function getProposalSolutionsListReducer(state = INITIAL_STATE.getProposalSolutionsList, action) {
  switch (action.type) {
    case actionTypes.GET_PROPOSAL_SOLUTIONS_LIST_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_LIST_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_LIST_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getProposalSolutionsAccountManagersReducer(
  state = INITIAL_STATE.getProposalSolutionsAccountManagers,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNT_MANAGERS_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getProposalSolutionsClientsReducer(
  state = INITIAL_STATE.getProposalSolutionsClients,
  action,
) {
  switch (action.type) {
    case actionTypes.RESET_PROPOSAL_SOLUTIONS_CLIENTS:
      return resetProposalSolutionsList(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CLIENTS_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CLIENTS_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CLIENTS_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getProposalSolutionsContactsReducer(
  state = INITIAL_STATE.getProposalSolutionsContacts,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CONTACTS_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CONTACTS_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_CONTACTS_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getProposalSolutionsAccountabilitiesReducer(
  state = INITIAL_STATE.getProposalSolutionsAccountabilities,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_ACCOUNTABILITIES_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getFormalizationsListReducer(state = INITIAL_STATE.getFormalizationsList, action) {
  switch (action.type) {
    case actionTypes.GET_FORMALIZATIONS_LIST_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_FORMALIZATIONS_LIST_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_FORMALIZATIONS_LIST_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function getProposalSolutionsDetailsReducer(
  state = INITIAL_STATE.getProposalSolutionsDetails,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_PROPOSAL_SOLUTIONS_DETAILS_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_DETAILS_SUCCESS:
      return getProposalSolutionsDetailsSuccess(state, action);
    case actionTypes.GET_PROPOSAL_SOLUTIONS_DETAILS_ERROR:
      return getProposalSolutionsDetailsError(state, action);

    default:
      return state;
  }
}

function getSalesProposalSolutionsReducer(state = INITIAL_STATE.getSalesProposalSolutions, action) {
  switch (action.type) {
    case actionTypes.GET_SALES_PROPOSAL_SOLUTIONS_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_SALES_PROPOSAL_SOLUTIONS_SUCCESS:
      return getProposalSolutionsDetailsSuccess(state, action);
    case actionTypes.GET_SALES_PROPOSAL_SOLUTIONS_ERROR:
      return getProposalSolutionsDetailsError(state, action);

    default:
      return state;
  }
}

function disassociateSaleReducer(state = INITIAL_STATE.disassociateSale, action) {
  switch (action.type) {
    case actionTypes.DISASSOCIATE_SALE_START:
      return proposalSolutionsStart(state);
    case actionTypes.DISASSOCIATE_SALE_SUCCESS:
      return disassociateSaleSuccess(state);
    case actionTypes.DISASSOCIATE_SALE_ERROR:
      return disassociateSaleError(state, action);

    case actionTypes.RESET_ASSOCIATED_SALES:
      return resetProposalSolutionsList(state);

    default:
      return state;
  }
}

function saveProposalSolutionsReducer(state = INITIAL_STATE.saveProposalSolutions, action) {
  switch (action.type) {
    case actionTypes.SAVE_PROPOSAL_SOLUTIONS_START:
      return proposalSolutionsStart(state);
    case actionTypes.SAVE_PROPOSAL_SOLUTIONS_SUCCESS:
      return saveProposalSolutionsSuccess(state);
    case actionTypes.SAVE_PROPOSAL_SOLUTIONS_ERROR:
      return saveProposalSolutionsError(state, action);
    case actionTypes.SAVE_PROPOSAL_SOLUTIONS_WARNING:
      return saveProposalSolutionsWarning(state);

    default:
      return state;
  }
}

function selectedSalesReducer(state = INITIAL_STATE.selectedSales, action) {
  switch (action.type) {
    case actionTypes.RESET_SELECTED_SALES:
      return resetProposalSolutionsList(state);
    case actionTypes.SET_SELECTED_SALES:
      return setSelectedSales(state, action);

    default:
      return state;
  }
}

function getCompensationsListReducer(state = INITIAL_STATE.getCompensationsList, action) {
  switch (action.type) {
    case actionTypes.GET_COMPENSATIONS_LIST_START:
      return proposalSolutionsStart(state);
    case actionTypes.GET_COMPENSATIONS_LIST_SUCCESS:
      return getProposalSolutionsListSuccess(state, action);
    case actionTypes.GET_COMPENSATIONS_LIST_ERROR:
      return getProposalSolutionsListError(state, action);

    default:
      return state;
  }
}

function selectedAssociatedSalesReducer(state = INITIAL_STATE.selectedAssociatedSales, action) {
  switch (action.type) {
    case actionTypes.SELECTED_ASSOCIATED_SALE:
      return setSelectedAssociatedSales(state, action);
    case actionTypes.SELECTED_ALL_ASSOCIATED_SALES:
      return setAllSelectedAssociatedSales(state, action);
    case actionTypes.RESET_SELECTED_ASSOCIATED_SALES:
      return resetSelectedASsociatedSales(state);
    default:
      return state;
  }
}

function proposalObservationReducer(state = INITIAL_STATE.proposalObservation, action) {
  switch (action.type) {
    case actionTypes.SAVE_PROPOSAL_OBSERVATION_START:
      return saveProposalObservationStart(state);
    case actionTypes.SAVE_PROPOSAL_OBSERVATION_SUCCESS:
      return saveProposalObservationSuccess(state, action);
    case actionTypes.SAVE_PROPOSAL_OBSERVATION_ERROR:
      return saveProposalObservationError(state, action);

    case actionTypes.GET_PROPOSAL_OBSERVATION_START:
      return getProposalObservationStart(state);
    case actionTypes.GET_PROPOSAL_OBSERVATION_SUCCESS:
      return getProposalObservationSuccess(state, action);
    case actionTypes.GET_PROPOSAL_OBSERVATION_ERROR:
      return getProposalObservationError(state, action);
    case actionTypes.RESET_PROPOSAL_OBSERVATION:
      return resetProposalObservation(state);

    default:
      return state;
  }
}

function proposalPreviewReducer(state = INITIAL_STATE.proposalPreview, action) {
  switch (action.type) {
    case actionTypes.RESET_PROPOSAL_PREVIEW:
      return resetProposalPreview(state);

    case actionTypes.GET_PROPOSAL_PREVIEW_START:
      return getProposalPreviewStart(state);
    case actionTypes.GET_PROPOSAL_PREVIEW_SUCCESS:
      return getProposalPreviewSuccess(state, action);
    case actionTypes.GET_PROPOSAL_PREVIEW_ERROR:
      return getProposalPreviewError(state, action);

    case actionTypes.SEND_PROPOSAL_EMAIL_START:
      return sendProposalEmailStart(state);
    case actionTypes.SEND_PROPOSAL_EMAIL_SUCCESS:
      return sendProposalEmailSuccess(state, action);
    case actionTypes.SEND_PROPOSAL_EMAIL_ERROR:
      return sendProposalEmailError(state, action);

    default:
      return state;
  }
}

const proposalSolutionsReducer = (state = INITIAL_STATE, action) => {
  return {
    getFormalizationsList: getFormalizationsListReducer(state.getFormalizationsList, action),
    getProposalSolutionsAccountManagers: getProposalSolutionsAccountManagersReducer(
      state.getProposalSolutionsAccountManagers,
      action,
    ),
    getProposalSolutionsClients: getProposalSolutionsClientsReducer(
      state.getProposalSolutionsClients,
      action,
    ),
    getProposalSolutionsDetails: getProposalSolutionsDetailsReducer(
      state.getProposalSolutionsDetails,
      action,
    ),
    getProposalSolutionsContacts: getProposalSolutionsContactsReducer(
      state.getProposalSolutionsContacts,
      action,
    ),
    getProposalSolutionsAccountabilities: getProposalSolutionsAccountabilitiesReducer(
      state.getProposalSolutionsAccountabilities,
      action,
    ),
    getSalesProposalSolutions: getSalesProposalSolutionsReducer(
      state.getSalesProposalSolutions,
      action,
    ),
    getProposalSolutionsList: getProposalSolutionsListReducer(
      state.getProposalSolutionsList,
      action,
    ),
    getCompensationsList: getCompensationsListReducer(state.getCompensationsList, action),

    disassociateSale: disassociateSaleReducer(state.disassociateSale, action),
    saveProposalSolutions: saveProposalSolutionsReducer(state.saveProposalSolutions, action),
    selectedSales: selectedSalesReducer(state.selectedSales, action),
    selectedAssociatedSales: selectedAssociatedSalesReducer(state.selectedAssociatedSales, action),
    proposalObservation: proposalObservationReducer(state.proposalObservation, action),
    proposalPreview: proposalPreviewReducer(state.proposalPreview, action),
  };
};

export default proposalSolutionsReducer;
