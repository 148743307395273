import { updateObject } from "utils/utility";
import ListingDefaultState from "utils/listingDefaultState";
import * as actionTypes from "./profiles.actionTypes";

const initialState = {
  listProfilesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    profilesOptions: [],
  },
  datatableByProfiles: {},
  datatableByProfilesModal: {},
  setUsersProfile: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  profileForm: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: undefined,
  },
  profileDetails: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    details: undefined,
    updateTime: undefined,
  },
};

const reset = () => initialState;

/* list roles options for comboboxes and autocompletes */

const listProfilesOptionsStart = state =>
  updateObject(state, {
    listProfilesOptions: {
      ...state.listProfilesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listProfilesOptionsSuccess = (state, action) => {
  const datatableByProfiles = {};
  const datatableByProfilesModal = {};

  action.profiles.forEach(p => {
    datatableByProfiles[p.descricao] = ListingDefaultState("nome");
    datatableByProfilesModal[p.descricao] = ListingDefaultState("nome");
  });

  return updateObject(state, {
    listProfilesOptions: {
      ...state.listProfilesOptions,
      loading: false,
      resultType: "success",
      message: null,
      profilesOptions: action.profiles.map(item => ({
        id: item.id,
        label: item.descricao,
        usersCount: item.contagemUsuarios,
      })),
      profilesOptionsDS: action.profiles.map(item => ({
        value: item.id,
        label: item.descricao,
      })),
    },
    datatableByProfiles,
    datatableByProfilesModal,
  });
};

const listProfilesOptionsError = (state, action) =>
  updateObject(state, {
    listProfilesOptions: {
      ...state.listProfilesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      profilesOptions: [],
    },
  });

/* list users by profile options */

const listUsersByProfileStart = (state, action) => {
  const newState = {
    ...state,
    datatableByProfiles: {
      ...state.datatableByProfiles,
      [action.profileLabel]: {
        ...state.datatableByProfiles[action.profileLabel],
        loading: true,
      },
    },
  };

  return updateObject(state, newState);
};

const listUsersByProfileSuccess = (state, action) => {
  const newState = {
    ...state,
    datatableByProfiles: {
      ...state.datatableByProfiles,
      [action.profileLabel]: {
        ...state.datatableByProfiles[action.profileLabel],
        loading: false,
        rows: action.list,
        options: {
          ...state.datatableByProfiles[action.profileLabel]?.options,
          ...action.config,
        },
      },
    },
  };

  return updateObject(state, newState);
};

const listUsersByProfileError = (state, action) => {
  const newUserByProfilesConfig = {
    ...state.datatableByProfiles[action.profileLabel],
    loading: false,
    rows: [],
    error: action.error,
    updateTime: Date.now(),
  };

  const newDatatableByProfiles = state.datatableByProfiles;
  newDatatableByProfiles[action.profileLabel] = newUserByProfilesConfig;

  return updateObject(state, {
    datatableByProfiles: newDatatableByProfiles,
  });
};

const listUsersByProfileModalStart = (state, action) => {
  const newState = {
    ...state,
    datatableByProfilesModal: {
      ...state.datatableByProfilesModal,
      [action.profileLabel]: {
        ...state.datatableByProfilesModal[action.profileLabel],
        loading: true,
      },
    },
  };

  return updateObject(state, newState);
};

const listUsersByProfileModalSuccess = (state, action) => {
  const newState = {
    ...state,
    datatableByProfilesModal: {
      ...state.datatableByProfilesModal,
      [action.profileLabel]: {
        ...state.datatableByProfilesModal[action.profileLabel],
        loading: false,
        rows: action.list,
        options: {
          ...state.datatableByProfilesModal[action.profileLabel]?.options,
          ...action.config,
        },
      },
    },
  };

  return updateObject(state, newState);
};

const listUsersByProfileModalError = (state, action) => {
  const newUserByProfilesConfig = {
    ...state.datatableByProfilesModal[action.profileLabel],
    loading: false,
    rows: [],
    error: action.error,
    updateTime: Date.now(),
  };

  const newDatatableByProfilesModal = state.datatableByProfilesModal;
  newDatatableByProfilesModal[action.profileLabel] = newUserByProfilesConfig;

  return updateObject(state, {
    datatableByProfilesModal: newDatatableByProfilesModal,
  });
};

/* set users new profile */

const setUsersProfileStart = state => {
  const newObj = updateObject(state.setUsersProfile, {
    loading: true,
    error: null,
    message: null,
  });

  return updateObject(state, {
    setUsersProfile: newObj,
  });
};

const setUsersProfileSuccess = state => {
  const newObj = updateObject(state.setUsersProfile, {
    loading: false,
    resultType: "success",
  });

  return updateObject(state, {
    setUsersProfile: newObj,
  });
};

const setUsersProfileError = (state, action) => {
  const newObj = updateObject(state.setUsersProfile, {
    resultType: "danger",
    error: action.error,
    loading: false,
  });

  return updateObject(state, {
    setUsersProfile: newObj,
  });
};

/* save user */

const saveProfileStart = state =>
  updateObject(state, {
    profileForm: {
      ...state.profileForm,
      loading: true,
      error: null,
      message: null,
    },
  });

const saveProfileSuccess = state =>
  updateObject(state, {
    profileForm: {
      ...state.profileForm,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const saveProfileError = (state, action) =>
  updateObject(state, {
    profileForm: {
      ...state.profileForm,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

/* get profile details */

const listProfileDetailsStart = state =>
  updateObject(state, {
    profileDetails: {
      ...state.profileDetails,
      loading: true,
      error: null,
      message: null,
    },
  });

const listProfileDetailsSuccess = (state, action) =>
  updateObject(state, {
    profileDetails: {
      ...state.profileDetails,
      loading: false,
      resultType: "success",
      message: null,
      details: action.details,
      updateTime: Date.now(),
    },
  });

const listProfileDetailsError = (state, action) =>
  updateObject(state, {
    profileDetails: {
      ...state.profileDetails,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const resetProfileDetails = state =>
  updateObject(state, {
    profileDetails: {
      ...state.profileDetails,
      loading: false,
      error: null,
      message: null,
      details: {},
      updateTime: null,
    },
  });

const profilesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_PROFILES_OPTIONS_START:
      return listProfilesOptionsStart(state);
    case actionTypes.LIST_PROFILES_OPTIONS_SUCCESS:
      return listProfilesOptionsSuccess(state, action);
    case actionTypes.LIST_PROFILES_OPTIONS_ERROR:
      return listProfilesOptionsError(state, action);

    case actionTypes.LIST_USERS_BY_PROFILE_START:
      return listUsersByProfileStart(state, action);
    case actionTypes.LIST_USERS_BY_PROFILE_SUCCESS:
      return listUsersByProfileSuccess(state, action);
    case actionTypes.LIST_USERS_BY_PROFILE_ERROR:
      return listUsersByProfileError(state, action);

    case actionTypes.LIST_USERS_BY_PROFILE_MODAL_START:
      return listUsersByProfileModalStart(state, action);
    case actionTypes.LIST_USERS_BY_PROFILE_MODAL_SUCCESS:
      return listUsersByProfileModalSuccess(state, action);
    case actionTypes.LIST_USERS_BY_PROFILE_MODAL_ERROR:
      return listUsersByProfileModalError(state, action);

    case actionTypes.SET_USERS_PROFILE_START:
      return setUsersProfileStart(state);
    case actionTypes.SET_USERS_PROFILE_SUCCESS:
      return setUsersProfileSuccess(state);
    case actionTypes.SET_USERS_PROFILE_ERROR:
      return setUsersProfileError(state, action);

    case actionTypes.SAVE_PROFILE_START:
      return saveProfileStart(state);
    case actionTypes.SAVE_PROFILE_SUCCESS:
      return saveProfileSuccess(state);
    case actionTypes.SAVE_PROFILE_ERROR:
      return saveProfileError(state, action);

    case actionTypes.EDIT_PROFILE_START:
      return saveProfileStart(state);
    case actionTypes.EDIT_PROFILE_SUCCESS:
      return saveProfileSuccess(state);
    case actionTypes.EDIT_PROFILE_ERROR:
      return saveProfileError(state, action);

    case actionTypes.LIST_PROFILE_DETAILS_START:
      return listProfileDetailsStart(state);
    case actionTypes.LIST_PROFILE_DETAILS_SUCCESS:
      return listProfileDetailsSuccess(state, action);
    case actionTypes.LIST_PROFILE_DETAILS_ERROR:
      return listProfileDetailsError(state, action);

    case actionTypes.RESET_PROFILE_DETAILS:
      return resetProfileDetails(state);

    default:
      return state;
  }
};

export default profilesReducer;
