import * as actionTypes from "./products.actionTypes";

/* list products options */

export const listProductsOptionsStart = () => ({
  type: actionTypes.LIST_PRODUCTS_OPTIONS_START,
});

export const listProductsOptionsSuccess = products => ({
  type: actionTypes.LIST_PRODUCTS_OPTIONS_SUCCESS,
  products,
});

export const listProductsOptionsError = (error, message) => ({
  type: actionTypes.LIST_PRODUCTS_OPTIONS_ERROR,
  error,
  message,
});
