import { updateObject } from "utils/utility";
import * as actionTypes from "./cities.actionTypes";

const initialState = {
  listCitiesOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    citiesOptions: [],
  },
  listCitiesOptionsNoClient: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    citiesOptions: [],
  },
  listCitiesOptionsV1: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    citiesOptions: [],
  },
  listCitiesOptionsV1ByStates: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    citiesOptions: [],
  },
};

const reset = () => initialState;

const listCitiesOptionsStart = state =>
  updateObject(state, {
    listCitiesOptions: {
      ...state.listCitiesOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listCitiesOptionsSuccess = (state, action) =>
  updateObject(state, {
    listCitiesOptions: {
      ...state.listCitiesOptions,
      loading: false,
      resultType: "success",
      message: null,
      citiesOptions: action.cities.map(item => ({
        id: item.id,
        label: item.nome,
      })),
    },
  });

const listCitiesOptionsError = (state, action) =>
  updateObject(state, {
    listCitiesOptions: {
      ...state.listCitiesOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      citiesOptions: [],
    },
  });

const listCitiesOptionsNoClientStart = state =>
  updateObject(state, {
    listCitiesOptionsNoClient: {
      ...state.listCitiesOptionsNoClient,
      loading: true,
      error: null,
      message: null,
    },
  });

const listCitiesOptionsNoClientSuccess = (state, action) =>
  updateObject(state, {
    listCitiesOptionsNoClient: {
      ...state.listCitiesOptionsNoClient,
      loading: false,
      resultType: "success",
      message: null,
      citiesOptions: action.cities.map(item => ({
        id: item.id,
        label: item.nome,
        stateId: item.stateId,
      })),
    },
  });

const listCitiesOptionsNoClientError = (state, action) =>
  updateObject(state, {
    listCitiesOptionsNoClient: {
      ...state.listCitiesOptionsNoClient,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      citiesOptions: [],
    },
  });

const listCitiesOptionsV1Start = state =>
  updateObject(state, {
    listCitiesOptionsV1: {
      ...state.listCitiesOptionsV1,
      loading: true,
      error: null,
      message: null,
    },
  });

const listCitiesOptionsV1Success = (state, action) =>
  updateObject(state, {
    listCitiesOptionsV1: {
      ...state.listCitiesOptionsV1,
      loading: false,
      resultType: "success",
      message: null,
      citiesOptions: action.cities.map(item => ({
        value: { ...item },
        label: item.nome,
      })),
      citiesOptionsNoDS: action.cities.map(item => ({
        id: item.id,
        label: item.nome,
        estadoId: item.estadoId,
      })),
    },
  });

const listCitiesOptionsV1Error = (state, action) =>
  updateObject(state, {
    listCitiesOptionsV1: {
      ...state.listCitiesOptionsV1,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      citiesOptions: [],
    },
  });

const citiesReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_CITIES_OPTIONS_START:
      return listCitiesOptionsStart(state);
    case actionTypes.LIST_CITIES_OPTIONS_SUCCESS:
      return listCitiesOptionsSuccess(state, action);
    case actionTypes.LIST_CITIES_OPTIONS_ERROR:
      return listCitiesOptionsError(state, action);

    case actionTypes.LIST_CITIES_OPTIONS_NO_CLIENT_START:
      return listCitiesOptionsNoClientStart(state);
    case actionTypes.LIST_CITIES_OPTIONS_NO_CLIENT_SUCCESS:
      return listCitiesOptionsNoClientSuccess(state, action);
    case actionTypes.LIST_CITIES_OPTIONS_NO_CLIENT_ERROR:
      return listCitiesOptionsNoClientError(state, action);

    case actionTypes.LIST_CITIES_OPTIONS_V1_START:
      return listCitiesOptionsV1Start(state);
    case actionTypes.LIST_CITIES_OPTIONS_V1_SUCCESS:
      return listCitiesOptionsV1Success(state, action);
    case actionTypes.LIST_CITIES_OPTIONS_V1_ERROR:
      return listCitiesOptionsV1Error(state, action);

    default:
      return state;
  }
};

export default citiesReducer;
