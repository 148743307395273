export const RESET = "RESET";

export const LIST_DESTINATIONS_OPTIONS_START = "LIST_DESTINATIONS_OPTIONS_START";
export const LIST_DESTINATIONS_OPTIONS_SUCCESS = "LIST_DESTINATIONS_OPTIONS_SUCCESS";
export const LIST_DESTINATIONS_OPTIONS_APPCLIENT_SUCCESS = "LIST_DESTINATIONS_OPTIONS_APPCLIENT_SUCCESS";
export const LIST_DESTINATIONS_OPTIONS_ERROR = "LIST_DESTINATIONS_OPTIONS_ERROR";

export const LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_START =
  "LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_START";
export const LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_SUCCESS =
  "LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_SUCCESS";
export const LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_ERROR =
  "LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_ERROR";
