import { updateObject } from "utils/utility";
import * as actionTypes from "./pessoaJuridica.actionTypes";

const initialState = {
  listPessoaJuridicaOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    pessoaJuridicaOptions: [],
  },
};

const reset = () => initialState;

const listPessoaJuridicaOptionsStart = state =>
  updateObject(state, {
    listPessoaJuridicaOptions: {
      ...state.listPessoaJuridicaOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listPessoaJuridicaOptionsSuccess = (state, action) =>
  updateObject(state, {
    listPessoaJuridicaOptions: {
      ...state.listPessoaJuridicaOptions,
      loading: false,
      resultType: "success",
      message: null,
      pessoaJuridicaOptions: action.list,
    },
  });

const listPessoaJuridicaOptionsError = (state, action) =>
  updateObject(state, {
    listPessoaJuridicaOptions: {
      ...state.listPessoaJuridicaOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      pessoaJuridicaOptions: [],
    },
  });

const pessoaJuridicaReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_PESSOA_JURIDICA_OPTIONS_START:
      return listPessoaJuridicaOptionsStart(state);
    case actionTypes.LIST_PESSOA_JURIDICA_OPTIONS_SUCCESS:
      return listPessoaJuridicaOptionsSuccess(state, action);
    case actionTypes.LIST_PESSOA_JURIDICA_OPTIONS_ERROR:
      return listPessoaJuridicaOptionsError(state, action);

    default:
      return state;
  }
};

export default pessoaJuridicaReducer;
