import * as actionTypes from "./halls.actionTypes";

export const listHallsOptionsStart = () => ({
  type: actionTypes.LIST_HALLS_OPTIONS_START,
});

export const listHallsOptionsSuccess = halls => ({
  type: actionTypes.LIST_HALLS_OPTIONS_SUCCESS,
  halls,
});

export const listHallsOptionsError = (error, message) => ({
  type: actionTypes.LIST_HALLS_OPTIONS_ERROR,
  error,
  message,
});
