export const GET_VOLUME_DIFERENCIATION_START = "GET_VOLUME_DIFERENCIATION_START";
export const GET_VOLUME_DIFERENCIATION_SUCCESS = "GET_VOLUME_DIFERENCIATION_SUCCESS";
export const GET_VOLUME_DIFERENCIATION_ERROR = "GET_VOLUME_DIFERENCIATION_ERROR";

export const GET_PRICE_DIFERENCIATION_START = "GET_PRICE_DIFERENCIATION_START";
export const GET_PRICE_DIFERENCIATION_SUCCESS = "GET_PRICE_DIFERENCIATION_SUCCESS";
export const GET_PRICE_DIFERENCIATION_ERROR = "GET_PRICE_DIFERENCIATION_ERROR";

export const SET_PROPERTY_LIST = "SET_PROPERTY_LIST";
export const RESET_PROPERTY_LIST = "RESET_PROPERTY_LIST";
