import { formatDistanceStrict } from "date-fns";
import { pt } from "date-fns/locale";

export const formatNotificationsBackToFront = notifications => {
  return notifications?.map(item => {
    return {
      id: item.id,
      title: item.titulo,
      description: item.mensagemFinal,
      isRead: item.visualizada,
      time: formatDistanceStrict(new Date(item.dataCriacao), new Date(), {
        locale: pt,
        addSuffix: false,
      }),
      path: item.caminhoRelativo,
      link: item.link,
      caminhoDownload: item.caminhoDownload,
    };
  });
};
