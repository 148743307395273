import { updateObject } from "utils/utility";
import * as actionTypes from "./halls.actionTypes";

const initialState = {
  listHallsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    hallsOptions: [],
  },
};

const listHallsOptionsStart = state =>
  updateObject(state, {
    listHallsOptions: {
      ...state.listHallsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listHallsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listHallsOptions: {
      ...state.listHallsOptions,
      loading: false,
      resultType: "success",
      message: null,
      hallsOptions: action.halls.map(item => ({
        id: item.id,
        label: item.name,
      })),
      hallsOptionsDS: action.halls.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });

const listHallsOptionsError = (state, action) =>
  updateObject(state, {
    listHallsOptions: {
      ...state.listHallsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      hallsOptions: [],
    },
  });

const hallsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.LIST_HALLS_OPTIONS_START:
      return listHallsOptionsStart(state);
    case actionTypes.LIST_HALLS_OPTIONS_SUCCESS:
      return listHallsOptionsSuccess(state, action);
    case actionTypes.LIST_HALLS_OPTIONS_ERROR:
      return listHallsOptionsError(state, action);

    default:
      return state;
  }
};

export default hallsReducer;
