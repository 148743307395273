import { SALES_TYPE } from "constants/sales";

/**
 * Returns if sale is railroad sale
 * @param  {number} idSaleType id from saletype
 * @returns {boolean} if sale is railroad
 */
export const verifyIsRailroadSale = idSaleType => {
  return SALES_TYPE.RAILROAD.id === Number(idSaleType);
};

/**
 * Returns if sale is harbor sale
 * @param  {number} idSaleType id from saletype
 * @returns {boolean} if sale is railroad
 */
export const verifyIsHarborSale = idSaleType => {
  return SALES_TYPE.HARBOR.id === Number(idSaleType);
};
