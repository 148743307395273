import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html, body, #root {
    height: 100%;
  }

  #menu-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  div#menu-wrapper > div.menu {
    height: 100% !important;
    min-height: 100vh;
  }



  div#vl-menu > nav > button.hide-menu {
    padding: 8px;
  }

  div#vl-menu > nav > button.hide-menu:focus {
    box-shadow: inset 0px 0px 0px 2px #D7E5F4;
    outline: none;
  }

  .username,
  .profile ul,
  .title-item p {
    margin-bottom: 0;
  }

  .collapse:not(.show) {
    display: block;
  }

    div.header-container-m-0 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }

    div.header-container-m-2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 2em;
    }

    div.header-container-m-4 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 4em;
    }

    [data-testid="modal"] {
      z-index: 999999 !important;
      width: 100% !important;
      height: 100% !important;
    }
`;

export default GlobalStyle;
