import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import PTGeneral from "assets/locales/pt/General.json";
import PTForm from "assets/locales/pt/Form.json";
import PTMessages from "assets/locales/pt/Messages.json";

const resources = {
  pt: {
    General: PTGeneral,
    Form: PTForm,
    Messages: PTMessages,
  },
};

const resourcesList = ["General", "Form", "Messages"];

i18n.use(reactI18nextModule).init({
  fallbackLng: "pt",
  // have a common namespace used around the full app
  ns: resourcesList,
  defaultNS: resourcesList,
  fallbackNS: resourcesList,
  returnObjects: true,
  resources,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  cache: {
    enabled: true,
  },
  load: "languageOnly",
  whitelist: ["pt"],
  react: {
    wait: true,
  },
});

export default i18n;
