import { updateObject } from "utils/utility";
import * as actionTypes from "./permissions.actionTypes";

const initialState = {
  listPermissionsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    data: [],
  },
};

const reset = () => initialState;

const listPermissionsOptionsStart = state =>
  updateObject(state, {
    listPermissionsOptions: {
      ...state.listPermissionsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listPermissionsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listPermissionsOptions: {
      ...state.listPermissionsOptions,
      loading: false,
      resultType: "success",
      message: null,
      data: action.data,
    },
  });

const listPermissionsOptionsError = (state, action) =>
  updateObject(state, {
    listPermissionsOptions: {
      ...state.listPermissionsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      data: [],
    },
  });

const permissionsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_PERMISSIONS_OPTIONS_START:
      return listPermissionsOptionsStart(state);
    case actionTypes.LIST_PERMISSIONS_OPTIONS_SUCCESS:
      return listPermissionsOptionsSuccess(state, action);
    case actionTypes.LIST_PERMISSIONS_OPTIONS_ERROR:
      return listPermissionsOptionsError(state, action);

    default:
      return state;
  }
};

export default permissionsReducer;
