import axios from "axios";
import ls from "local-storage";
import { uncoverText } from "utils/utility";
import { showAuthModal } from "store/login/login.useCases";
import { store } from "store/store";
import { config as urlConfig } from "./config";

const verifyToken = () => {
  const expiredDate = new Date(uncoverText(ls.get("per")).exp * 1000);

  if (expiredDate < new Date()) {
    store.dispatch(showAuthModal());
    throw new Error("Sessão expirada");
  }
};

const clientId = "6da52b98-9179-3234-98cc-6980a2dca367";

const API = axios.create({
  baseURL: urlConfig.apiurl,
});

const appClientAPI = axios.create({
  baseURL: urlConfig.appClientUrl,
});

const notificationAPI = axios.create({
  baseURL: urlConfig.apiNotificationUrl,
});

export const hasToken = () => {
  return ls.get("usd") && uncoverText(ls.get("usd")) && uncoverText(ls.get("usd")).password;
};

const currentExecutingRequests = {};

export const cancelAllRequests = () => {
  Object.keys(currentExecutingRequests).forEach(url => {
    const source = currentExecutingRequests[url];
    source.cancel("Cancel");
    delete currentExecutingRequests[url];
  });
};

API.defineRequestInterceptor = () => {
  API.interceptors.request.use(
    async request => {
      let originalRequest = request;

      const accessToken = `${uncoverText(ls.get("usd")).accessToken}`;
      const password = `${uncoverText(ls.get("usd")).password}`;

      originalRequest.headers.common.client_id = clientId;
      originalRequest.headers.common.access_token = accessToken;
      originalRequest.headers.common.authorization = `Bearer ${password}`;
      // Verifique se a solicitação deve ignorar o cancelationToken
      if (!request.ignoreCancelToken) {
        if (currentExecutingRequests[request.url]) {
          const source = currentExecutingRequests[request.url];
          delete currentExecutingRequests[request.url];
          source.cancel("Cancel");
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        originalRequest.cancelToken = source.token;
        currentExecutingRequests[request.url] = source;
      }

      if (!request.ignoreVerifyToken) {
        verifyToken();
      }

      return originalRequest;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

appClientAPI.interceptors.request.use(
  async request => {
    const accessToken = `${uncoverText(ls.get("usd")).accessToken}`;
    const password = `${uncoverText(ls.get("usd")).password}`;

    request.headers.common.client_id = clientId;
    request.headers.common.access_token = accessToken;
    request.headers.common.authorization = `Bearer ${password}`;

    verifyToken();

    return request;
  },
  error => Promise.reject(error),
);

API.defineResponseInterceptor = route => {
  API.interceptors.response.use(
    response => response,
    error => {
      return Promise.reject(error);
    },
  );
};

appClientAPI.defineResponseInterceptor = route => {
  appClientAPI.interceptors.response.use(
    response => {
      if (currentExecutingRequests[response.request.responseURL]) {
        // here you clean the request
        delete currentExecutingRequests[response.request.responseURL];
      }
      return response;
    },
    error => {
      const { config } = error;
      const originalRequest = config;

      if (axios.isCancel(error)) {
        return new Promise(() => {});
      }

      if (currentExecutingRequests[originalRequest.url]) {
        delete currentExecutingRequests[originalRequest.url];
      }

      return Promise.reject(error);
    },
  );
};

notificationAPI.defineRequestInterceptor = () => {
  notificationAPI.interceptors.request.use(
    async request => {
      const accessTokenNotification = `${
        uncoverText(ls.get("usd_notification")).accessTokenNotification
      }`;

      request.headers.common.access_token = accessTokenNotification;

      verifyToken();

      return request;
    },
    error => Promise.reject(error),
  );
};

notificationAPI.defineResponseInterceptor = route => {
  notificationAPI.interceptors.response.use(
    response => response,
    error => {
      return Promise.reject(error);
    },
  );
};

export { appClientAPI, notificationAPI };
export default API;
