export const GET_OBSERVATIONS_START = "GET_OBSERVATIONS_START";
export const GET_OBSERVATIONS_SUCCESS = "GET_OBSERVATIONS_SUCCESS";
export const GET_OBSERVATIONS_ERROR = "GET_OBSERVATIONS_ERROR";

export const GET_OBSERVATIONS_TRANSACTIONS_START = "GET_OBSERVATIONS_TRANSACTIONS_START";
export const GET_OBSERVATIONS_TRANSACTIONS_SUCCESS = "GET_OBSERVATIONS_TRANSACTIONS_SUCCESS";
export const GET_OBSERVATIONS_TRANSACTIONS_ERROR = "GET_OBSERVATIONS_TRANSACTIONS_ERROR";

export const SET_OBSERVATIONS_AS_READ_START = "SET_OBSERVATIONS_AS_READ_START";
export const SET_OBSERVATIONS_AS_READ_SUCCESS = "SET_OBSERVATIONS_AS_READ_SUCCESS";
export const SET_OBSERVATIONS_AS_READ_ERROR = "SET_OBSERVATIONS_AS_READ_ERROR";

export const SET_OBSERVATIONS_TRANSACTIONS_AS_READ_START =
  "SET_OBSERVATIONS_TRANSACTIONS_AS_READ_START";
export const SET_OBSERVATIONS_TRANSACTIONS_AS_READ_SUCCESS =
  "SET_OBSERVATIONS_TRANSACTIONS_AS_READ_SUCCESS";
export const SET_OBSERVATIONS_TRANSACTIONS_AS_READ_ERROR =
  "SET_OBSERVATIONS_TRANSACTIONS_AS_READ_ERROR";
