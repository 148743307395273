import { updateObject } from "utils/utility";
import * as actionTypes from "./destinations.actionTypes";

const initialState = {
  listDestinationsOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    destinationsOptions: [],
    destinationsOptionsDS: [],
  },
  listDestinationsHighlightOptions: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
    destinationsOptions: [],
  },
};

const reset = () => initialState;

const listDestinationsOptionsStart = state =>
  updateObject(state, {
    listDestinationsOptions: {
      ...state.listDestinationsOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listDestinationsOptionsSuccess = (state, action) =>
  updateObject(state, {
    listDestinationsOptions: {
      ...state.listDestinationsOptions,
      loading: false,
      resultType: "success",
      message: null,
      destinationsOptions: action.destinations.map(item => ({
        id: item.id,
        label: item.name,
      })),
      destinationsOptionsDS: action.destinations.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });

const listDestinationsOptionsError = (state, action) =>
  updateObject(state, {
    listDestinationsOptions: {
      ...state.listDestinationsOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      destinationsOptions: [],
    },
  });

const listDestinationsHighlightOptionsStart = state =>
  updateObject(state, {
    listDestinationsHighlightOptions: {
      ...state.listDestinationsHighlightOptions,
      loading: true,
      error: null,
      message: null,
    },
  });

const listDestinationsHighlightOptionsSuccess = (state, action) => {
  const newOptions = [...state.listDestinationsHighlightOptions.destinationsOptions];

  newOptions[action.searchIndex] = action.destinations.map(item => ({
    ...item,
    id: item.id,
    label: item.name,
  }));

  return updateObject(state, {
    listDestinationsHighlightOptions: {
      ...state.listDestinationsHighlightOptions,
      loading: false,
      resultType: "success",
      message: null,
      destinationsOptions: newOptions,
      destinationsOptionsDS: action.destinations.map(item => ({
        value: { ...item },
        label: item.name,
      })),
    },
  });
};

const listDestinationsOptionsAppClientSuccess = (state, action) => {
  const newOptions = action.destinations.map(item => ({
    value: { ...item },
    id: item.id,
    label: item.name,
  }));

  return updateObject(state, {
    listDestinationsOptions: {
      ...state.listDestinationsOptions,
      loading: false,
      resultType: "success",
      message: null,
      destinationsOptionsDS: newOptions,
    },
  });
};

const listDestinationsHighlightOptionsError = (state, action) =>
  updateObject(state, {
    listDestinationsHighlightOptions: {
      ...state.listDestinationsHighlightOptions,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      destinationsOptions: [],
    },
  });

const destinationsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.LIST_DESTINATIONS_OPTIONS_START:
      return listDestinationsOptionsStart(state);
    case actionTypes.LIST_DESTINATIONS_OPTIONS_SUCCESS:
      return listDestinationsOptionsSuccess(state, action);
    case actionTypes.LIST_DESTINATIONS_OPTIONS_APPCLIENT_SUCCESS:
      return listDestinationsOptionsAppClientSuccess(state, action);
    case actionTypes.LIST_DESTINATIONS_OPTIONS_ERROR:
      return listDestinationsOptionsError(state, action);

    case actionTypes.LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_START:
      return listDestinationsHighlightOptionsStart(state);
    case actionTypes.LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_SUCCESS:
      return listDestinationsHighlightOptionsSuccess(state, action);
    case actionTypes.LIST_DESTINATIONS_HIGHLIGHT_OPTIONS_ERROR:
      return listDestinationsHighlightOptionsError(state, action);

    default:
      return state;
  }
};

export default destinationsReducer;
