import { updateObject } from "lib/object";
import * as actionTypes from "./newExperienceSale.actionTypes";

const initialState = {
  diferenciationVolumeList: {
    list: [],
    error: null,
    loading: false,
    message: null,
    resultType: "danger",
  },
  diferenciationPriceList: {
    list: [],
    error: null,
    loading: false,
    message: null,
    resultType: "danger",
  },
  propertyList: [],
};

/* DEFAULT STATES */

const salesDefaultStartState = {
  loading: true,
  error: null,
  message: null,
};

const salesDefaultSuccessState = {
  loading: false,
  error: null,
  message: null,
  resultType: "success",
};

const salesDefaultErrorState = action => {
  return {
    resultType: "danger",
    loading: false,
    error: action.error,
    message: action.message,
  };
};

const salesDefaultStart = state =>
  updateObject(state, {
    ...state,
    ...salesDefaultStartState,
  });

/* diferenciation volume */

const getVolumeDiferenciationListSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    list: action.payload,
  });

const getVolumeDiferenciationListError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    list: [],
  });

/* diferenciation price */

const getPriceDiferenciationListSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultSuccessState,
    list: action.payload,
  });

const getPriceDiferenciationListError = (state, action) =>
  updateObject(state, {
    ...state,
    ...salesDefaultErrorState(action),
    list: [],
  });

function diferenciationVolumeListReducer(
  state = initialState.diferenciationVolumeList,
  action = "",
) {
  switch (action.type) {
    case actionTypes.GET_VOLUME_DIFERENCIATION_START:
      return salesDefaultStart(state);
    case actionTypes.GET_VOLUME_DIFERENCIATION_SUCCESS:
      return getVolumeDiferenciationListSuccess(state, action);
    case actionTypes.GET_VOLUME_DIFERENCIATION_ERROR:
      return getVolumeDiferenciationListError(state, action);

    default:
      return state;
  }
}

function diferenciationPriceListReducer(state = initialState.diferenciationPriceList, action = "") {
  switch (action.type) {
    case actionTypes.GET_PRICE_DIFERENCIATION_START:
      return salesDefaultStart(state);
    case actionTypes.GET_PRICE_DIFERENCIATION_SUCCESS:
      return getPriceDiferenciationListSuccess(state, action);
    case actionTypes.GET_PRICE_DIFERENCIATION_ERROR:
      return getPriceDiferenciationListError(state, action);

    default:
      return state;
  }
}

const setPropertyList = (state, action) => {
  return updateObject(state, {
    ...state,
    propertyList: {
      ...state.propertyList,
      [action.tabId]: action.payload,
    },
  });
};

const resetPropertyList = (state, action) =>
  updateObject(state, {
    propertyList: {
      ...state.propertyList,
      [action.tabId]: [],
    },
  });

const newExperienceSaleReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.SET_PROPERTY_LIST:
      return setPropertyList(state, action);

    case actionTypes.RESET_PROPERTY_LIST:
      return resetPropertyList(state, action);

    default:
      return {
        ...state,
        diferenciationVolumeList: diferenciationVolumeListReducer(
          state.diferenciationVolumeList,
          action,
        ),
        diferenciationPriceList: diferenciationPriceListReducer(
          state.diferenciationPriceList,
          action,
        ),
      };
  }
};

export default newExperienceSaleReducer;
