import { Tokens } from "@vli/locomotive-ds";

export const MAPPED = 1;
export const NEGOTIATION = 2;
export const COMPLETED = 3;
export const PENDING = 4;

export const MAPPED_LABEL = "mapeada";
export const NEGOTIATION_LABEL = "negociacao";
export const COMPLETED_LABEL = "concluida";
export const PENDING_LABEL = "conclusaoPendente";

export const SALES_STATUS = {
  MAPPED: { label: "Mapeada", id: MAPPED },
  NEGOTIATION: { label: "Em Negociação", id: NEGOTIATION },
  CONCLUDED: { label: "Concluída", id: COMPLETED },
  PENDING: { label: "Conclusão Pendente", id: PENDING },
};

export const TRANSACTION_SITUATION = {
  NO_STATUS: 1,
  PENDING: 2,
  APPROVED: 3,
  REFUSED: 4,
};

export const STATUS_TO_TAB = {
  [MAPPED]: 0,
  [NEGOTIATION]: 1,
  [PENDING]: 2,
  [COMPLETED]: 3,
};

export const TAB_TO_STATUS = {
  0: MAPPED,
  1: NEGOTIATION,
  2: PENDING,
  3: COMPLETED,
};

export const MONTHS_RESUMED = [
  "jan",
  "fev",
  "mar",
  "abr",
  "mai",
  "jun",
  "jul",
  "ago",
  "set",
  "out",
  "nov",
  "dez",
];

export const TRANSACTION_SITUATION_NAME = {
  "1": "SEM STATUS",
  "2": "EM ANÁLISE",
  "3": "APROVADA",
  "4": "RECUSADA",
};

export const TRANSACTION_SITUATION_COLOR = {
  "1": "blue",
  "2": "yellow",
  "3": "green",
  "4": "orange",
};

export const SALES_STATUS_OPTIONS = Object.keys(SALES_STATUS).map(property => ({
  ...SALES_STATUS[property],
}));

export const SALES_TYPE = {
  RAILROAD: { label: "Ferrovia", id: 1 },
  HARBOR: { label: "Porto", id: 2 },
};

export const COMERCIAL_OPTIONS = [
  {
    label: "Market share",
    value: "Market share",
  },
  {
    label: "Apetite do cliente",
    value: "Apetite do cliente",
  },
  {
    label: "Competitividade",
    value: "Competitividade",
  },
  {
    label: "Venda condicionada",
    value: "Venda condicionada",
  },
];

export const OTHERS_OPTION = "Outros";

export const OPERATIONAL_OPTIONS = [
  {
    label: "Ativos ociosos",
    value: "Ativos ociosos",
  },
  {
    label: "Ativos overbook",
    value: "Ativos overbook",
  },
  {
    label: "Outros",
    value: OTHERS_OPTION,
  },
];

export const PIS_COFINS_TAX = 0.9075;

export const NO_TAX_INSERTED_VALUE = null;

export const NEW_EXPERIENCE_SALES = 1;
export const STANDARD_SALES = 2;

export const STATUS_SALE = {
  Concluída: Tokens.colors.green300,
  "Conclusão Pendente": Tokens.colors.orange300,
  Mapeada: Tokens.colors.gray300,
  "Em Negociação": Tokens.colors.blue300,
};
