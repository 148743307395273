import { updateObject } from "utils/utility";
import DefaultRequestParams from "utils/defaultRequestParams";
import * as actionTypes from "./upload.actionTypes";

const initialState = {
  uploadSheet: DefaultRequestParams(),
  uploadStatus: {
    ...DefaultRequestParams(),
    statusOptions: [],
  },
  downloadDefaultAdmUploadFile: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  downloadDefaultPricingFile: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  downloadSheet: {
    loading: false,
    error: null,
    resultType: "danger",
    message: null,
  },
  isScenarioSelected: false,
};

const reset = () => initialState;

const uploadStart = state =>
  updateObject(state, {
    uploadSheet: {
      ...state.upload,
      loading: true,
      error: null,
      message: null,
    },
  });

const uploadSuccess = state =>
  updateObject(state, {
    uploadSheet: {
      ...state.upload,
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const uploadError = (state, action) =>
  updateObject(state, {
    uploadSheet: {
      ...state.upload,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const uploadStatusStart = state =>
  updateObject(state, {
    uploadStatus: {
      ...state.uploadStatus,
      loading: true,
      error: null,
      message: null,
    },
  });

const uploadStatusSuccess = (state, action) =>
  updateObject(state, {
    uploadStatus: {
      ...state.uploadStatus,
      loading: false,
      resultType: "success",
      message: null,
      statusOptions: action.status || [],
    },
  });

const uploadStatusError = (state, action) =>
  updateObject(state, {
    uploadStatus: {
      ...state.uploadStatus,
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
      statusOptions: [],
    },
  });

const downloadDefaultAdmUploadFileStart = state =>
  updateObject(state, {
    downloadDefaultAdmUploadFile: {
      loading: true,
      error: null,
      message: null,
    },
  });

const downloadDefaultAdmUploadFileSuccess = state =>
  updateObject(state, {
    downloadDefaultAdmUploadFile: {
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const downloadDefaultAdmUploadFileError = (state, action) =>
  updateObject(state, {
    downloadDefaultAdmUploadFile: {
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const downloadDefaultPricingFileStart = state =>
  updateObject(state, {
    downloadDefaultPricingFile: {
      loading: true,
      error: null,
      message: null,
    },
  });

const downloadDefaultPricingFileSuccess = state =>
  updateObject(state, {
    downloadDefaultPricingFile: {
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const downloadDefaultPricingFileError = (state, action) =>
  updateObject(state, {
    downloadDefaultPricingFile: {
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const selectedScenario = (state, action) =>
  updateObject(state, {
    isScenarioSelected: action.payload,
  });

const downloadSheetStart = state =>
  updateObject(state, {
    downloadSheet: {
      loading: true,
      error: null,
      message: null,
    },
  });

const downloadSheetSuccess = state =>
  updateObject(state, {
    downloadSheet: {
      loading: false,
      resultType: "success",
      message: null,
    },
  });

const downloadSheetError = (state, action) =>
  updateObject(state, {
    downloadSheet: {
      resultType: "danger",
      loading: false,
      error: action.error,
      message: action.message,
    },
  });

const uploadReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case actionTypes.RESET:
      return reset();

    case actionTypes.UPLOAD_START:
      return uploadStart(state);
    case actionTypes.UPLOAD_SUCCESS:
      return uploadSuccess(state);
    case actionTypes.UPLOAD_ERROR:
      return uploadError(state, action);

    case actionTypes.UPLOAD_STATUS_START:
      return uploadStatusStart(state);
    case actionTypes.UPLOAD_STATUS_SUCCESS:
      return uploadStatusSuccess(state, action);
    case actionTypes.UPLOAD_STATUS_ERROR:
      return uploadStatusError(state, action);

    case actionTypes.DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_START:
      return downloadDefaultAdmUploadFileStart(state);
    case actionTypes.DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_SUCCESS:
      return downloadDefaultAdmUploadFileSuccess(state);
    case actionTypes.DOWNLOAD_DEFAULT_ADM_UPLOAD_FILE_ERROR:
      return downloadDefaultAdmUploadFileError(state, action);

    case actionTypes.DOWNLOAD_DEFAULT_PRICING_FILE_START:
      return downloadDefaultPricingFileStart(state);
    case actionTypes.DOWNLOAD_DEFAULT_PRICING_FILE_SUCCESS:
      return downloadDefaultPricingFileSuccess(state);
    case actionTypes.DOWNLOAD_DEFAULT_PRICING_FILE_ERROR:
      return downloadDefaultPricingFileError(state, action);

    case actionTypes.DOWNLOAD_SHEET_START:
      return downloadSheetStart(state);
    case actionTypes.DOWNLOAD_SHEET_SUCCESS:
      return downloadSheetSuccess(state);
    case actionTypes.DOWNLOAD_SHEET_ERROR:
      return downloadSheetError(state, action);

    case actionTypes.SELECTED_SCENARIO:
      return selectedScenario(state, action);

    default:
      return state;
  }
};

export default uploadReducer;
